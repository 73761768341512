import { BundleCompareMeshDevicesComponent } from './pages/bundle-compare-mesh-devices/bundle-compare-mesh-devices.component';
import { BundleMeshDeviceDetailsComponent } from './pages/bundle-mesh-device-details/bundle-mesh-device-details.component';
import { BundleCompareChannelLineupComponent } from './pages/bundle-compare-channel-lineup/bundle-compare-channel-lineup.component';
import { BundleCompareDeviceComponent } from './pages/bundle-compare-device/bundle-compare-device.component';
import { BundleSelectDeviceComponent } from './pages/bundle-select-device/bundle-select-device.component';
import { BundleViewchannelLineupComponent } from './pages/bundle-viewchannel-lineup/bundle-viewchannel-lineup.component';
import { SelectBundleComponent } from './pages/select-bundle/select-bundle.component';
import { BundlePlanContainerComponent } from './pages/bundle-plan-container/bundle-plan-container.component';
import { BundleHelpMeChooseComponent } from './pages/bundle-help-me-choose/bundle-help-me-choose.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FinalStepsComponent } from './pages/final-steps/final-steps.component';
import { BundleViewDetailsModalComponent } from './components/bundle-view-details-modal/bundle-view-details-modal.component';
import { BundleLineupComponent } from 'src/app/shared/components/bundle-lineup/bundle-lineup.component';
import { FinalizePlanComponent } from 'src/app/shared/components/finalize-plan/finalize-plan.component';
const routes: Routes = [
  { 
    path:"bundle-plan", component:BundlePlanContainerComponent,
    
    children:[
      { path:"help-me-choose",component:BundleHelpMeChooseComponent },
      { path:"select-plan",component:SelectBundleComponent },
      { path:"compare-channel-lineup",component:BundleCompareChannelLineupComponent },
      { path:"select-device",component:BundleSelectDeviceComponent },
      { path:"compare-modem-devices",component:BundleCompareDeviceComponent },
      { path:"channel-lineup",component:BundleViewchannelLineupComponent },
      { path:"finalize-plan",component:FinalStepsComponent },
      { path:"compare-mesh-devices",component:BundleCompareMeshDevicesComponent },
      { path:"mesh-device-details",component:BundleMeshDeviceDetailsComponent },
      { path:"plan-summary",component:FinalizePlanComponent },
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BundlePlanRoutingModule { }
