import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet-compare-device',
  templateUrl: './internet-compare-device.component.html',
  styleUrls: ['./internet-compare-device.component.css']
})
export class InternetCompareDeviceComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit() {
  }

  goBack(){
    this.router.navigate(['internet-plan/select-device'])
  }

}
