import { environment } from 'src/environments/environment';
import { Service } from './../../models/service.model';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { UpdateSelectedService, UpdateSelectedServiceCategory } from '../../actions/gelato.actions';

@Component({
  selector: 'app-service-cards',
  templateUrl: './service-cards.component.html',
  styleUrls: ['./service-cards.component.css']
})
export class ServiceCardsComponent implements OnInit {

  @Input() service: Service;

  constructor(private router: Router, private store: Store) { }

  ngOnInit() {
  
  }
  navigate(id,category){
    this.store.dispatch(new UpdateSelectedService(id))
    this.store.dispatch(new UpdateSelectedServiceCategory(category))
    if(id === environment.BIZ_BROADBAND || id === environment.BIZ_BROADBAND_CONNECT_BUNDLE_PROMO){
      this.router.navigate(['internet-plan/select-plan'])
    }else if( id === environment.HOMEBIZ || id === environment.FIBER_CONNECT_PLANS || environment.BIZ_FIBER_CONNECT_BUNDLE_PROMO){
      this.router.navigate(['internet-plan/select-plan'])
    }else if( id === environment.BIZ_BROADBAND_CABLE || id === environment.FIBER_CONNECT_HDTV_PLANS){
      this.router.navigate(['bundle-plan/select-plan'])
    }
  }


  getScreenWidth(){
    let width = window.innerWidth
    if(this.service.image_sizes.length >= 1){
      // Phone
      if(width <= 450){
        return this.service.image_sizes[0]['350x111'] ? this.service.image_sizes[0]['350x111'] : this.service.thumbnail
      }
      // Landscape phone
      else if(width <= 768){
        return this.service.image_sizes[1]['677x133'] ? this.service.image_sizes[1]['677x133'] : this.service.thumbnail
      }
      // Tablet
      else if(width <= 1129){
        return this.service.image_sizes[2]['775x133'] ? this.service.image_sizes[2]['775x133'] : this.service.thumbnail
      }
      // Desktop
      else{
        return this.service.thumbnail
      }
    }
    // Desktop
    else{
      return this.service.thumbnail
    }
  }
}
