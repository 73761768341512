import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { Plan } from 'src/app/shared/models/plan.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Router } from '@angular/router';
import { UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Devices } from 'src/app/shared/models/devices.model';
import { SelectedDevice } from 'src/app/shared/models/selected-device.model';
import { DeviceComparison } from 'src/app/shared/models/device-comparison.model';
import { CableBoxDevice } from 'src/app/shared/models/cable-box-device.model';
import { DeviceFeature } from 'src/app/shared/models/device-feature.model';

@Component({
  selector: 'app-tv-compare-device',
  templateUrl: './tv-compare-device.component.html',
  styleUrls: ['./tv-compare-device.component.css']
})
export class TvCompareDeviceComponent implements OnInit {
  @Select(GelatoState.getHelpMeChoose) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getDevices) selectedService$: Observable<ResponseObject<Devices>>;
  @Select(GelatoState.getCompareDevices) selectedDevice$: Observable<ResponseObject<DeviceComparison>>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>
  selected:Devices[]
  blanckBox:[]
  planId: number[];
  selectedDevices:SelectedDevice[]
  deviceLineup:DeviceFeature[]
  device:CableBoxDevice[];
  device_type : string = "Cable Box"
  createYourOwnFlag:boolean = false
  constructor(private store: Store,private gelatoService:GelatoService,private router: Router) { }

  ngOnInit() {
  }

  goBack(){
    window.history.back()
  }

}
