import { DeviceCollection } from './../../shared/models/device-collection.model';
import { Building } from './../../shared/models/building.model';
import { VoucherCode } from './../../shared/models/voucher-code';
import { PackDetail } from 'src/app/shared/models/pack-detail.model';
import { DeviceComparison } from './../../shared/models/device-comparison.model';
import { DeviceDetail } from './../../shared/models/device-detail.model';
import { ChannelObject } from './../../shared/models/channel-object.model';
import { ChannelPack } from './../../shared/models/channel-pack.model';
import { ChannelComparison } from './../../shared/models/channel-comparison.model';
import { ServicePlan } from './../../shared/models/servicePlan.model';
import { Service } from './../../shared/models/service.model';
import { HeaderText } from './../../shared/models/header-text.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Province } from 'src/app/shared/models/province.model';
import { City } from 'src/app/shared/models/city.model';
import { Barangay } from 'src/app/shared/models/barangay.model';
import { Street } from 'src/app/shared/models/street.model';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
import { Genre } from 'src/app/shared/models/genre.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { Devices } from 'src/app/shared/models/devices.model';
import { SubmitApplication } from 'src/app/shared/models/submit-application.model';
import { BillingSummary } from 'src/app/shared/models/billing-summary';
import { Industry } from 'src/app/shared/models/industry.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Select } from '@ngxs/store';
import { PromoList } from 'src/app/shared/models/promo-list.model';
import { ValidateBody, ValidateRequest } from 'src/app/shared/models/validate-location.model';
@Injectable()
export class GelatoService {

  @Select(GelatoState.getApplicationType) applicationType$: Observable<string>;
  applicationType: string;
  constructor(private _http: HttpClient) {
    this.applicationType$.subscribe(x => {
      if (x) this.applicationType = x
    })
  }

  getHeaderText(): Observable<ResponseObject<HeaderText>> {
    try {
      let path = "/headingTextApi"
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<HeaderText>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)
    }
  }
  getProvinces(): Observable<ResponseObject<[Province]>> {
    try {
      let path = "/provinceList"
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<[Province]>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)
    }
  }
  getCities(province_code: string): Observable<ResponseObject<[City]>> {
    try {
      let path = "/cityList"
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      const body = {
        province_code
      }
      return this._http.post<ResponseObject<[City]>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)
    }
  }
  getBuildings(province_code: string, city_code: string): Observable<ResponseObject<[Building]>> {
    try {
      let path = "/buildingList"
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      const body = {
        province_code,
        city_code
      }
      return this._http.post<ResponseObject<[Building]>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)
    }
  }
  getBarangays(province_code: string, city_code: string): Observable<ResponseObject<[Barangay]>> {
    try {
      let path = "/barangayList"
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      const body = {
        province_code,
        city_code
      }
      return this._http.post<ResponseObject<[Barangay]>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)
    }
  }
  getStreets(province_code: string, city_code: string, brgy_code: string): Observable<ResponseObject<[Street]>> {
    try {
      let path = "/streetList"
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      const body = {
        province_code,
        city_code,
        brgy_code
      }
      return this._http.post<ResponseObject<[Street]>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)
    }
  }
  getServices(province: string, city: string, barangay: string, street: string, building_id?: number): Observable<ResponseObject<[Service]>> {
    try {
      let path = "/serviceList"
      const application_type = this.applicationType
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      const body = {
        province,
        city,
        barangay,
        street,
        building_id,
        application_type
      }
      return this._http.post<ResponseObject<[Service]>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)
    }
  }
  getServicesByLATLONG(latitude: number, longtitude: number, street?: string): Observable<ResponseObject<[Service]>> {
    try {
      let path = "/mapServiceability"
      const application_type = this.applicationType
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      const body = {
        longtitude,
        latitude,
        street,
        application_type
      }
      return this._http.post<ResponseObject<[Service]>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)
    }
  }
  getServicesByPlanID(id: string, province_code: string, service_category?: string, cable_lite?: boolean): Observable<ResponseObject<[ServicePlan]>> {
    try {
      let path = `/servicePlans?service_id=${id}&service_category=${service_category}&province_code=${province_code}&is_cable_lite=${cable_lite}&application_type=${this.applicationType}`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<[ServicePlan]>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)
    }
  }

  getPlanDetailsByID(id: string, province_code: string, service_category?: string): Observable<ResponseObject<PlanDetail>> {
    try {
      let path = `/servicePlans/${id}?service_category=${service_category}&province_code=${province_code}&application_type=${this.applicationType}`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<PlanDetail>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  comparePlanByIDs(plan_id: number[], service_category: string, province_code: string): Observable<ResponseObject<[PlanDetail]>> {
    try {
      let path = `/planComparison`
      const application_type = this.applicationType
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      const body = {
        plan_id,
        service_category,
        province_code,
        application_type
      }
      return this._http.post<ResponseObject<[PlanDetail]>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  compareChannelIDs(plan_id: number[], province_code: string): Observable<ResponseObject<ChannelComparison>> {
    try {
      let path = `/channelLineupComparison`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      const body = {
        plan_id,
        province_code
      }
      return this._http.post<ResponseObject<ChannelComparison>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)

    }
  }


  getGenres(): Observable<ResponseObject<[Genre]>> {
    try {
      let path = `/genreList`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<[Genre]>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }


  getGenreDetail(id: string): Observable<ResponseObject<Genre>> {
    try {
      let path = `/genreList/${id}`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<Genre>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  getChannelPacks(plan_id?: number, type?: string, genre_id?: string, search?: string, per_page?: string, page?: string, province_code?: string): Observable<ResponseObject<ChannelPack>> {
    try {
      let path = `/channelPacks?genre_id=${genre_id}&search=${search}&per_page=${per_page}&page=${page}&type=${type}&plan_id=${plan_id}&province_code=${province_code}`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      return this._http.get<ResponseObject<ChannelPack>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  getPackDetail(id: string, province_code?: string): Observable<ResponseObject<PackDetail>> {
    try {
      let path = `/channelPacks/${id}&province_code=${province_code}`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<PackDetail>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  getChannels(plan_id?: any, type?: string, genre_id?: string, search?: string, per_page?: string, page?: string, province_code?: string): Observable<ResponseObject<ChannelObject>> {
    try {
      let path = `/channelList?genre_id=${genre_id}&search=${search}&per_page=${per_page}&page=${page}&type=${type}&plan_id=${plan_id}&province_code=${province_code}`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<ChannelObject>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  getChannelDetail(id: string, province_code?: string): Observable<ResponseObject<Channel>> {
    try {
      let path = `/channelList/${id}&province_code=${province_code}`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<Channel>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  getDevices(planId?: number): Observable<ResponseObject<Devices>> {
    try {
      let path = ""
      if (planId) {
        path = `/deviceList?plan_id=${planId}`
      } else {
        path = `/deviceList`
      }

      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<Devices>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  getDeviceDetail(id: string, planId?: number): Observable<ResponseObject<DeviceDetail>> {
    try {
      let path = planId ? `/deviceList/${id}?plan_id=${planId}` : `/deviceList/${id}`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<DeviceDetail>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  getDeviceCollection(): Observable<ResponseObject<DeviceCollection>> {
    try {
      let path = `/deviceCollections`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<DeviceCollection>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  compareDevicesByID(device_type: string, device_id: number[], plan_id?: number): Observable<ResponseObject<DeviceComparison>> {
    try {
      let path = plan_id ? `/deviceComparison?plan_id=${plan_id}` : `/deviceComparison`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      const body = {
        device_type,
        device_id
      }
      return this._http.post<ResponseObject<DeviceComparison>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  validateVoucherCode(voucher_code: string, service_ids?: number[], plan_ids?: number[], province_code?: string): Observable<ResponseObject<VoucherCode>> {
    try {
      let path = `/voucherCode`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      const body = {
        voucher_code,
        service_ids,
        plan_ids,
        province_code
      }
      return this._http.post<ResponseObject<VoucherCode>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  submitApplication(data): Observable<ResponseObject<any[]>> {
    try {
      let path = `/submitApplication`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      return this._http.post<ResponseObject<any[]>>(environment.GELATO_API_BASE_URL + path, data, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  getValidID(): Observable<ResponseObject<any>> {
    try {
      let path = `/IDTypes`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });

      return this._http.get<ResponseObject<Channel>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  AdditionalBoxCharges(data): Observable<ResponseObject<any[]>> {
    try {
      let path = `/getCableTvAdditionalBoxCharges`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      return this._http.post<ResponseObject<any[]>>(environment.GELATO_API_BASE_URL + path, data, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  billingSummary(data): Observable<ResponseObject<BillingSummary>> {
    try {
      let path = `/billingSummary`
      data = { ...data, "application_type": this.applicationType }
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      return this._http.post<ResponseObject<BillingSummary>>(environment.GELATO_API_BASE_URL + path, data, { headers })
    } catch (error) {
      console.log(error)

    }
  }

  getIndustries(): Observable<ResponseObject<[Industry]>> {
    try {
      let path = "/industryList"
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      return this._http.get<ResponseObject<[Industry]>>(environment.GELATO_API_BASE_URL + path, { headers })
    } catch (error) {
      console.log(error)
    }
  }

  getBundlePromos(data): Observable<ResponseObject<[PromoList]>> {
    try {
      let path = "/bundlesPromoList"
      const body = {
        plan_ids: data
      }
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      return this._http.post<ResponseObject<[PromoList]>>(environment.GELATO_API_BASE_URL + path, body, { headers })
    } catch (error) {
      console.log(error)
    }
  }

  submitLocationValidtaionRequest(data: ValidateBody): Observable<ResponseObject<ValidateRequest>> {
    try {
      let path = `/submitValidationRequest`
      const headers = new HttpHeaders({ 'Interface-ID': environment.INTERFACE_ID, 'Interface-KEY': environment.INTERFACE_KEY });
      return this._http.post<ResponseObject<ValidateRequest>>(environment.GELATO_API_BASE_URL + path, data, { headers })
    } catch (error) {
      console.log(error)

    }
  }

}
