import { environment } from 'src/environments/environment';
import { RemoveSelectedDevice, RemoveSelectedPlan, UpdateBillingSummary, UpdateBillingSummaryPayload } from './../../actions/gelato.actions';
import { Address } from 'src/app/shared/models/address.model';
import { PlanDetail } from './../../models/planDetail.model';
import { Component, OnInit,Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs/internal/Observable';
import { ResponseObject } from '../../models/response-object.model';
import { Plan } from '../../models/plan.model';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { UpdateHelpMeChoose, ResetHelpMeChoose, UpdateComparePlans, AddSelectedPlan, UpdateEditMode, AddSelectedDevice } from '../../../shared/actions/gelato.actions';
import { MatDialog } from '@angular/material/dialog';
import { UpdatePlanDetails } from 'src/app/shared/actions/gelato.actions';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';
import * as _ from 'lodash'
import { Service } from '../../models/service.model';
@Component({
  selector: 'app-bundle-modal-plan-detail',
  templateUrl: './bundle-modal-plan-detail.component.html',
  styleUrls: ['./bundle-modal-plan-detail.component.css']
})
export class BundleModalPlanDetailComponent implements OnInit {

  @Select(GelatoState.getPlanDetail) planDetails$: Observable<ResponseObject<PlanDetail>>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getHelpMeChoose) selected$: Observable<any>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Input() plan: Plan;
  selectedPlans:[Plan]


  servicePlan:ServicePlan[]
  selected:Plan[]
  planDetail:PlanDetail
  isButtonVisible = true;
  buttonName = "See more";
  more: number=1;
  billingSummaryPayload:any
  address:Address
  province_code:string = ""
  constructor(private srv: GelatoState,private store: Store,private router: Router,private gelatoService:GelatoService,
    public dialogRef: MatDialogRef<BundleModalPlanDetailComponent>)
     { 

      this.planDetails$.subscribe(x=> {
        this.planDetail = x.data["plan_details"]
      })
      
      window.scrollTo({top: 0})

     }

     ngOnInit() {
      this.services$.subscribe(x => {
        if(x) this.province_code = x.data["province"]["code"]
      })
      const addressObj = localStorage.getItem("address")
      if(addressObj){
        this.address = JSON.parse(addressObj) as Address
        this.province_code = this.address.province.province_code
      }
      
      this.selectedPlans$.subscribe(x => {
        this.selectedPlans = x
      })
      this.address$.subscribe(x => {
        if(x){
          this.gelatoService.getServicesByPlanID(environment.BIZ_BROADBAND_CABLE.toString(),this.province_code).subscribe(x => {
            this.servicePlan = x.data["service_plans"]
          })    
        }
      })

      this.billingSummaryPayload$.subscribe(x => {
        if(x) this.billingSummaryPayload = x
      })
    }

    getThisFlag(planDetail:PlanDetail){
      if(this.selectedPlans){
        if(this.selectedPlans.some(x => x.id === planDetail.id)){
          return true
        }
        else if(this.selectedPlans.some(x => x.service_id == environment.HOMEBIZ || x.service_id == environment.BIZ_BROADBAND)){
          return true
        }
        else{
          return false
        }
      }
      else{
        return false
      }
    }


     SeeMore(){
      this.more = this.more + 11;
      if(this.more >= 7){
          this.isButtonVisible = false;
          this.buttonName = "See Less";        
      }
    }
    
  
      SeeLess(){
          this.more = 0;
          this.isButtonVisible = true;
      }
  


  selectPlan(plan:Plan){
    this.store.dispatch(new UpdateHelpMeChoose(this.planDetail))
    this.selectedService$.subscribe(x => {
       this.router.navigate(['/bundle-plan/channel-lineup'])
       this.dialogRef.close()
    })
  }
  
 
  
  getThis(plan:Plan) {
    this.store.dispatch(new UpdateHelpMeChoose(this.planDetail))
    if(this.selectedPlans){
      this.selectedPlans.filter(x => x.service_id === environment.BIZ_BROADBAND_CABLE).map(y => {
        this.store.dispatch(new RemoveSelectedPlan(y.id))
        y.comes_with.map(x => {
          this.store.dispatch(new RemoveSelectedDevice(x.id))      
        })
      }) 
    }
    this.store.dispatch(new AddSelectedPlan(plan))

    plan.comes_with.map(x => {
      this.store.dispatch(new AddSelectedDevice(x))
    })
    


    //CONSTRUCT SELECTED PLANS
    const selected = this.selectedPlans.map(x => {
      return {
        "plan_id":x.id,
        device_id:x.comes_with.map(x=>x.id)
      }
    })
    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)

    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      if(x.status_code === 200){
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{
      }
    })    

    this.dialogRef.close()
  }

  editPlanName(planName){
    var split = planName.split(" ")
    if(split.length > 1){
      return split[1]
    }
    else{
      return planName
    }
  }
  
  closeDialog(){
    this.dialogRef.close();
  }
  

}
