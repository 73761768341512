import { Device } from './../../models/device.model';
import { Plan } from 'src/app/shared/models/plan.model';
import { Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { Devices } from '../../models/devices.model';
import { ResponseObject } from '../../models/response-object.model';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';

@Component({
  selector: 'app-choose-modem-box',
  templateUrl: './choose-modem-box.component.html',
  styleUrls: ['./choose-modem-box.component.css']
})
export class ChooseModemBoxComponent implements OnInit {
  @Select(GelatoState.getSelectedPlans) selectedPlans$:Observable<[Plan]>
  @Select(GelatoState.getSelectedMesh) selectedMesh$:Observable<[Device]>
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  meshFlag:boolean = false
  selectedPlans:[Plan]
  
  selectedDevices:Device[] = []
  @Input() devices: ResponseObject<Devices>;
  title:string = "Do you want to change your modem?"
  constructor(private router: Router) { }

  ngOnInit() {
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    })
    this.selectedPlans$.subscribe(x => {
     this.selectedPlans = x
      if(x){
        if(x.length > 0) this.title = "Do you want to change your modem?"
      }
    })
    this.selectedMesh$.subscribe(x => {
      if(x){
        if(x.length > 0) this.meshFlag = true
      }
    })
    
  }

  toggleMesh(){
    this.meshFlag = !this.meshFlag
  }

  goTo(x){
    this.router.navigate([x])
  }

  displayFlag(device:Device) {
    
    return this.selectedDevices.some(x => x.id === device.id)

  }
}
