import { DeviceCollection } from './../../models/device-collection.model';
import { Component, Input, OnInit } from '@angular/core';
import { ResponseObject } from '../../models/response-object.model';

@Component({
  selector: 'app-choose-lite-box',
  templateUrl: './choose-lite-box.component.html',
  styleUrls: ['./choose-lite-box.component.css']
})
export class ChooseLiteBoxComponent implements OnInit {

  @Input() devices: ResponseObject<DeviceCollection>;
  constructor() { }

  ngOnInit() {
  }

}
