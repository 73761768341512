import { AddOrdinalPostion, AddSelectedCableDevice, AddSelectedCableDeviceCountId, AddSelectedCableLockin, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdateSelectedAvailableLockinPeriod, UpdateSelectedCableDevice, UpdateSelectedCableDeviceId } from './../../actions/gelato.actions';
import { Device } from 'src/app/shared/models/device.model';
import { CableBoxDevice } from './../../models/cable-box-device.model';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateDeviceDetail } from '../../actions/gelato.actions';
import { ModemViewDetailsModalComponent } from '../modem-view-details-modal/modem-view-details-modal.component';
import { AvailableLockinPeriod } from '../../models/available-lockin-period.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';
import { Plan } from '../../models/plan.model';
import { ResponseObject } from '../../models/response-object.model';
import { DeviceDetail } from '../../models/device-detail.model';
import * as _ from 'lodash';
@Component({
  selector: 'app-cable-device-box',
  templateUrl: './cable-device-box.component.html',
  styleUrls: ['./cable-device-box.component.css']
})
export class CableDeviceBoxComponent implements OnInit{
  
  @Select(GelatoState.getSelectedDeviceIndex) cableIndex$: Observable<any>
  @Select(GelatoState.getSelectedCableDeviceId) cableCountId$: Observable<any>
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>
  @Select(GelatoState.getSelectedLockin) selectedLockin$: Observable<AvailableLockinPeriod>
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>
  @Select(GelatoState.getOrdinalPosition) ordinalPostiion$: Observable<number>
  @Select(GelatoState.getSelectedDeviceIndex) Index$: Observable<[any]>
  @Select(GelatoState.getDeviceDetail) deviceDetails$: Observable<ResponseObject<DeviceDetail>>
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPlans) selectedPlan$: Observable<[Plan]>;
  @Select(GelatoState.getEditMode) edit$: Observable<boolean>;
  @Select(GelatoState.getApplyButtonFlag) applyButtonFlag$: Observable<boolean>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCableDeviceId) CableId$: Observable<[any]>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>
  @Input() CableBoxDevice: CableBoxDevice;
  @Input() index: number;
  @Input() deviceIndex: number[];
  selectedMesh:[Device]
  cableCountId:number[];
  cableIndex:number[];
  panelOpen: boolean = false;
  selected: AvailableLockinPeriod
  form: FormGroup
  hasError:boolean = false
  selectedDevice:Device[]
  buttonFlag:boolean = false;

  
  selectedPlan:Plan[] = []
  selectedDevices:Device[] =  []
  selectedCableLockin:[AvailableLockinPeriod]
  selectedMeshLockin:[AvailableLockinPeriod]
  selectedCableDevice:any[] = []
  selectedMeshDevice:any[] = []
  errorMessage = ""
  selectedLockin:AvailableLockinPeriod
  countt: [number]
  position:number
  billingSummaryPayload:any
  installFee:any[]=[]
  createYourOwnFlag:boolean = false
  constructor(private formBuilder: FormBuilder,public dialog: MatDialog,private store: Store,private gelatoService:GelatoService) { }

  ngOnInit() {
    this.selectedDevices$.subscribe(x => {
      this.selectedDevice = x
    })
  
    this.form = this.formBuilder.group({
      lockin: ['',Validators.required],
    })
   
    this.cableCountId$.subscribe(x => {
      this.cableCountId = x
    })

    this.cableIndex$.subscribe(x => {
      this.cableIndex = x
    })

    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
      else this.selectedCableDevice = []
    })
    this.selectedLockin$.subscribe(x => {
      this.selectedLockin = x
    })
    this.Index$.subscribe(x => {
      this.countt = x
      
    })
    this.ordinalPostiion$.subscribe(x=>{
      this.position = x
      
    })
    this.selectedPlan$.subscribe(x => {
      if(x) this.selectedPlan = x
     
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })
  
    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMeshDevice = x
     
    })

    this.createYourOwnFlag$.subscribe(x =>{
      if(x) this.createYourOwnFlag = x
    })
   
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })

    // Install Fee per Ordinal Position
         let formData = new FormData();
            formData.append(`device_id`,`${this.CableBoxDevice.id}`);
            formData.append(`lockin_period_id`,`${this.CableBoxDevice.lockin_period_id}`);
            formData.append(`ordinal_position`,`${this.index+1}`)
            this.gelatoService.AdditionalBoxCharges(formData).subscribe(x=>{
            if(x.status_code === 200){
              this.installFee = x.data['installation_fee']
            }
            })
   

  }

  openModal(id,index){
    //UPDATE PLAN_DETAIL
    const ordinalNumber = index+1
    const lockin = this.form.controls["lockin"].value
      this.gelatoService.getDeviceDetail(`${id}`).subscribe(x => {
        this.store.dispatch(new AddOrdinalPostion(ordinalNumber))
        this.store.dispatch(new UpdateDeviceDetail(x))
        this.store.dispatch(new UpdateSelectedAvailableLockinPeriod(lockin))
        const dialogRef = this.dialog.open(ModemViewDetailsModalComponent,{
          height: '95%',
          width: '660px',
        });
        
      })
    
    
  }
 

  // Cable  Device Add ons Box Label
  labelFlag(x,y){
      
    if( this.cableCountId){
      if( this.CableBoxDevice.id == this.cableCountId[0] && x == this.cableIndex[0] && y == 0   ||
        this.CableBoxDevice.id == this.cableCountId[0] && x == this.cableIndex[0] && y == 1
          

        ||this.CableBoxDevice.id == this.cableCountId[1] && x == this.cableIndex[1] && y == 0   ||
        this.CableBoxDevice.id == this.cableCountId[1] && x == this.cableIndex[1] && y == 1  
       

        ||this.CableBoxDevice.id == this.cableCountId[2] && x == this.cableIndex[2] && y == 0   ||
        this.CableBoxDevice.id == this.cableCountId[2] && x == this.cableIndex[2] && y == 1  
       

        ||this.CableBoxDevice.id == this.cableCountId[3] && x == this.cableIndex[3] && y == 0   ||
        this.CableBoxDevice.id == this.cableCountId[3] && x == this.cableIndex[3] && y == 1  
        
          ){
           
          return true
      }else if( 
       null ==  this.cableCountId[0] && x == this.cableIndex[0] && y == 0   ||
        null == this.cableCountId[0] && x == this.cableIndex[0] && y == 1

        ||null == this.cableCountId[1] && x == this.cableIndex[1] && y == 0   ||
        null == this.cableCountId[1] && x == this.cableIndex[1] && y == 1  
        
        || null == this.cableCountId[2] && x == this.cableIndex[2] && y == 0   ||
        null == this.cableCountId[2] && x == this.cableIndex[2] && y == 1   

      
        || null == this.cableCountId[3] && x == this.cableIndex[3] && y == 0   ||
        null == this.cableCountId[3] && x == this.cableIndex[3] && y == 1 ){
          return false
        }
    }else if(this.cableCountId == null ){
   
      return false
    }
    else{
      return false
    }
  }

  getBox(id,index){
    

    const ordinalNumber = index+1
    
    const lockin = this.form.controls["lockin"].value
      this.gelatoService.getDeviceDetail(`${id}`).subscribe(x => {
        this.store.dispatch(new AddOrdinalPostion(ordinalNumber))
        this.store.dispatch(new UpdateDeviceDetail(x))
        this.store.dispatch(new UpdateSelectedAvailableLockinPeriod(lockin))
        
        if(this.selectedCableDevice.length === 0){
          this.getThis(x.data['device'])
        }else{
          //REMOVE ITEM AND ADD NEW ITEM WITH SAME INDEX
          
          if(this.selectedCableDevice[index]){
            const id = this.selectedCableDevice[index].id
            let ds = [...this.selectedCableDevice]
            ds[index] = x.data['device']
            
            this.store.dispatch(new UpdateSelectedCableDevice(ds))
            this.store.dispatch(new UpdateSelectedCableDeviceId(ds.map(x=>x.id)))
            this.billingSummary()
          }else{
            this.getThis(x.data['device'])
          }
        }
          
      })
  }

  getThis(x){
    this.store.dispatch(new AddSelectedCableDevice(x))  
    this.store.dispatch(new AddSelectedCableLockin(this.selectedLockin)) 
    this.store.dispatch(new AddSelectedCableDeviceCountId(x.id))  
    this.billingSummary()
  }

    billingSummary(){
      const selected = []
      if(this.selectedMeshDevice){
      this.selectedMeshDevice.map(x => {
          selected.push({
            "device_id":x.id,
            "lockin_period_id":x.lockin_period_id || x.lockin_period['id']
          })
          
        })
        this.selectedCableDevice.map(x => {
          selected.push({
            "device_id":x.id,
            "lockin_period_id":x.lockin_period_id 
          })
        })
      }else{
        this.selectedCableDevice.map(x => {
          selected.push({
            "device_id":x.id,
            "lockin_period_id":x.lockin_period_id 
          })
        })
      }
  
    
    
       let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.devices",selected)
     //UPDATE BILLING SUMMARY PAYLOAD
     this.store.dispatch(new UpdateBillingSummaryPayload(payload))
     //UPDATE BILLING SUMMARY
     this.gelatoService.billingSummary(payload).subscribe(x => {
       if(x.status_code === 200){
         this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
       }else{ 
       }
       })  
    }

}


