import { Address } from 'src/app/shared/models/address.model';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable, Subscription } from 'rxjs';
import { Plan } from 'src/app/shared/models/plan.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Router } from '@angular/router';
import { UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Service } from 'src/app/shared/models/service.model';

@Component({
  selector: 'app-tv-plan-channel-lineup',
  templateUrl: './tv-plan-channel-lineup.component.html',
  styleUrls: ['./tv-plan-channel-lineup.component.css']
})
export class TvPlanChannelLineupComponent implements OnInit {

  @Select(GelatoState.getHelpMeChoose) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  flag:boolean

  selectedPlans:[]
  channelLineup:[]
  address:Address
  province_code:string = ""
  subscription: Subscription;
  constructor(private store: Store,private gelatoService:GelatoService,private router: Router) { 
    
    this.httpReqeuestFlag$.subscribe(x =>{
      this.flag = x
    })}

  ngOnInit() {
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    //GET ALL SELECTED PLANS IN HELP ME CHOOSE PAGE
    this.subscription = this.selectedPlans$.subscribe(x => {
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      //GET ALL THE DETAILS OF SELECTED PLANS FROM PLAN ID's
      this.gelatoService.compareChannelIDs(x.map(x => x.id),this.province_code).subscribe(x => {
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
        this.selectedPlans = x.data["selected_plans"]
        this.channelLineup = x.data["channel_lineup"]
      })
    })
    this.subscription.unsubscribe()
  }

  getThis(){
    this.router.navigate(['/tv-plan/create-plan/tv-packs'])
  }

}
