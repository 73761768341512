import { Component, OnInit,Input } from '@angular/core';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';
import { Plan } from 'src/app/shared/models/plan.model';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
@Component({
  selector: 'app-bundle-box-container',
  templateUrl: './bundle-box-container.component.html',
  styleUrls: ['./bundle-box-container.component.css']
})
export class BundleBoxContainerComponent implements OnInit {
  @Input() servicePlan: ServicePlan;
  constructor() { }

  ngOnInit() {
  }

}
