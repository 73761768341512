import { PlanDetail } from './../../models/planDetail.model';
import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs/internal/Observable';
import { ResponseObject } from '../../models/response-object.model';
import { Plan } from '../../models/plan.model';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { AddSelectedPlan, UpdateEditMode, AddSelectedDevice, RemoveSelectedPlan, RemoveSelectedDevice, UpdateSelectedCableTvPlan, UpdateSelectedCableTvDevice, UpdateBillingSummary, UpdateBillingSummaryPayload } from '../../../shared/actions/gelato.actions';
import { AvailableLockinPeriod } from '../../models/available-lockin-period.model';
import { Channel } from '../../models/channel.model';
import { Device } from '../../models/device.model';
import { Pack } from '../../models/pack.model';
import { VoucherCode } from '../../models/voucher-code';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-tv-modal-plan-detail',
  templateUrl: './tv-modal-plan-detail.component.html',
  styleUrls: ['./tv-modal-plan-detail.component.css']
})

export class TvModalPlanDetailComponent implements OnInit {
  @Select(GelatoState.getPlanDetail) planDetails$: Observable<ResponseObject<PlanDetail>>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getVoucherCode) discountCode$: Observable<ResponseObject<VoucherCode>>;
  @Select(GelatoState.getEditMode) edit$: Observable<boolean>;
  @Select(GelatoState.getApplyButtonFlag) applyButtonFlag$: Observable<boolean>;
  planDetail:PlanDetail
  isButtonVisible = true;
  buttonName = "See more";
  length:number
  buttonhide:boolean = true
  more: number=1;
  constructor(private store: Store,private router: Router,private gelatoService:GelatoService,
    public dialogRef: MatDialogRef<TvModalPlanDetailComponent>) 
    {
      
    this.planDetails$.subscribe(x=> {
      this.planDetail = x.data["plan_details"]
      this.length=this.planDetail.channel_lineup['data'].length
    
      if(this.length == 1){
    
        this.buttonhide = false
      }else{
        this.buttonhide = true
      }
    })
    
    window.scrollTo({top: 0})
   
  

  }

  SeeMore(){
      this.more = this.more + 11;
      if(this.more >= 10){
          this.isButtonVisible = false;
          this.buttonName = "See Less";        
      }
    }
  
    SeeLess(){
        this.more = 0;
        this.isButtonVisible = true;
    }

  ngOnInit() {
   
  }
  

  editPlanName(planName){
    var split = planName.split(" ")
    if(split.length > 1){
      return split[1]
    }
    else{
      return planName
    }
  }
  
  closeDialog(){
    this.dialogRef.close();
  }

}




