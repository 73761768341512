import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-error-modal',
  templateUrl: './generic-error-modal.component.html',
  styleUrls: ['./generic-error-modal.component.css']
})
export class GenericErrorModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
