import { Observable } from 'rxjs';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { DeviceCollection } from '../../models/device-collection.model';
import { Select } from '@ngxs/store';
import { ResponseObject } from '../../models/response-object.model';

@Component({
  selector: 'app-two-device-modal',
  templateUrl: './two-device-modal.component.html',
  styleUrls: ['./two-device-modal.component.css']
})
export class TwoDeviceModalComponent implements OnInit {

  @Select(GelatoState.getDeviceCollection) devices$: Observable<ResponseObject<DeviceCollection>>;
  devices: DeviceCollection[]
  constructor(  @Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<TwoDeviceModalComponent> ) {
    this.devices$.subscribe(x => {
      let collection = x.data['device_collections']
      this.devices = collection.filter(x => x.id == Object.values(this.data)[0])
    })
  }

  ngOnInit() {
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
