import { InternetAddonComponent } from './../../shared/components/internet-addon/internet-addon.component';
import { CompareMeshDevicesComponent } from './pages/compare-mesh-devices/compare-mesh-devices.component';
import { MeshDeviceDetailsComponent } from './pages/mesh-device-details/mesh-device-details.component';
import { InternetCompareDeviceComponent } from './pages/internet-compare-device/internet-compare-device.component';
import { InternetSelectDeviceComponent } from './pages/internet-select-device/internet-select-device.component';
import { InternetHelpMeChooseComponent } from './pages/internet-help-me-choose/internet-help-me-choose.component';
import { FinalStepsComponent } from './pages/final-steps/final-steps.component';
import { SelectInternetComponent } from './pages/select-internet/select-internet.component';
import { HelpMeChooseComponent } from './../../shared/components/help-me-choose/help-me-choose.component';
import { InternetPlanContainerComponent } from './pages/internet-plan-container/internet-plan-container.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FinalizePlanComponent } from 'src/app/shared/components/finalize-plan/finalize-plan.component';
import { BundlePromoComponent } from './pages/bundle-promo/bundle-promo.component';

const routes: Routes = [
  { 
    path:"internet-plan", component:InternetPlanContainerComponent,
    children:[
      { path:"help-me-choose",component:InternetHelpMeChooseComponent },
      { path:"select-plan",component:SelectInternetComponent },
      { path:"finalize-plan",component:FinalStepsComponent },
      { path:"select-device",component:InternetSelectDeviceComponent },
      { path:"select-addon",component:InternetAddonComponent },
      { path:"compare-modem-devices",component:InternetCompareDeviceComponent },
      { path:"compare-mesh-devices",component:CompareMeshDevicesComponent },
      { path:"mesh-device-details",component:MeshDeviceDetailsComponent },
      { path:"plan-summary",component:FinalizePlanComponent },
      { path:"bundle-promo",component:BundlePromoComponent },
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InternetPlanRoutingModule { }
