import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BundlePlanRoutingModule } from './bundle-plan-routing.module';
import { BundlePlanContainerComponent } from './pages/bundle-plan-container/bundle-plan-container.component';
import { SelectBundleComponent } from './pages/select-bundle/select-bundle.component';
import { BundleBoxComponent } from './components/bundle-box/bundle-box.component';
import { BundleBoxContainerComponent } from './components/bundle-box-container/bundle-box-container.component';
import { BundleHelpMeChooseComponent } from './pages/bundle-help-me-choose/bundle-help-me-choose.component';
import { BundleViewDetailsModalComponent } from './components/bundle-view-details-modal/bundle-view-details-modal.component';
import { BundleViewchannelLineupComponent } from './pages/bundle-viewchannel-lineup/bundle-viewchannel-lineup.component';
import { BundleSelectDeviceComponent } from './pages/bundle-select-device/bundle-select-device.component';
import { BundleCompareDeviceComponent } from './pages/bundle-compare-device/bundle-compare-device.component';
import { FinalStepsComponent } from './pages/final-steps/final-steps.component';
import { BundleCompareChannelLineupComponent } from './pages/bundle-compare-channel-lineup/bundle-compare-channel-lineup.component';
import { BundledMeshDetailsComponent } from './pages/bundled-mesh-details/bundled-mesh-details.component';
import { BundleMeshDeviceDetailsComponent } from './pages/bundle-mesh-device-details/bundle-mesh-device-details.component';
import { BundleCompareMeshDevicesComponent } from './pages/bundle-compare-mesh-devices/bundle-compare-mesh-devices.component';


@NgModule({
  declarations: [BundlePlanContainerComponent, SelectBundleComponent, BundleBoxComponent, BundleBoxContainerComponent, BundleHelpMeChooseComponent, BundleViewDetailsModalComponent, BundleSelectDeviceComponent,BundleViewchannelLineupComponent,BundleCompareDeviceComponent, FinalStepsComponent, BundleCompareChannelLineupComponent, BundledMeshDetailsComponent, BundleMeshDeviceDetailsComponent, BundleCompareMeshDevicesComponent],
  imports: [
    BundlePlanRoutingModule,
    SharedModule
  ]
})
export class BundlePlanModule { }
