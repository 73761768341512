import { environment } from 'src/environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../shared/shared.module';


import { NgModule } from '@angular/core'

import { SetLocationComponent } from './pages/set-location/set-location.component';
import { AvailableLocationComponent } from './pages/available-location/available-location.component';
import { OnboardingRoutingModule } from './onboarding-routing.module';
import { SelectLocationComponent } from './pages/select-location/select-location.component';
import { OnboardingContainerComponent } from './pages/onboarding-container/onboarding-container.component';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { ValidateAddressComponent } from './pages/validate-address/validate-address.component';
import { NgxCaptchaModule } from 'ngx-captcha';




@NgModule({
  declarations: [SetLocationComponent, AvailableLocationComponent, SelectLocationComponent, OnboardingContainerComponent, ValidateAddressComponent],
  imports: [
    OnboardingRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_CLOUD_KEY,
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule,
    NgxCaptchaModule
  ]
})
export class OnboardingModule { }
