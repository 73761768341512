import { environment } from 'src/environments/environment';
import { AvailableLockinPeriod } from './../../models/available-lockin-period.model';
import { AddSelectedDevice, RemoveSelectedDevice, AddSelectedMeshDevice, AddSelectedMeshLockin, AddSelectedCableDevice, AddSelectedCableLockin } from './../../actions/gelato.actions';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';
import { ResponseObject } from '../../models/response-object.model';
import { DeviceDetail } from '../../models/device-detail.model';
import { MatDialogRef } from '@angular/material/dialog';
import { Devices } from '../../models/devices.model';
import { Device } from '../../models/device.model';
import { Plan } from '../../models/plan.model';

@Component({
  selector: 'app-cable-box-view-details',
  templateUrl: './cable-box-view-details.component.html',
  styleUrls: ['./cable-box-view-details.component.css']
})
export class CableBoxViewDetailsComponent implements OnInit  {
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>
  @Select(GelatoState.getSelectedLockin) selectedLockin$: Observable<AvailableLockinPeriod>
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>
  @Select(GelatoState.getDeviceDetail) deviceDetails$: Observable<ResponseObject<DeviceDetail>>
  selectedDevice:[Device]
  selectedLockin:AvailableLockinPeriod
  constructor(private store: Store,public dialogRef: MatDialogRef<CableBoxViewDetailsComponent>) { }

  ngOnInit() {
    this.selectedDevices$.subscribe(x => {
      this.selectedDevice = x
    })
    this.selectedLockin$.subscribe(x => {
      this.selectedLockin = x
    })
  }

  getThis(x){
    this.selectedPlans$.subscribe(x => {
      if(x){
        if(x.length > 0){
          x.map(x => x.comes_with.map(x => {
            //2 = CABLE
            if(x.device_type_id === environment.DEVICE_CABLE) {
              this.store.dispatch(new RemoveSelectedDevice(x.id))
            }
          }))
        }
      }
      
    })
    if(x.device_type_id === environment.DEVICE_CABLE){
      this.store.dispatch(new AddSelectedCableDevice(x)) 
      this.store.dispatch(new AddSelectedDevice(x)) 
    }else{
      this.store.dispatch(new AddSelectedDevice(x))
    }
    
    this.dialogRef.close()
    
  }

  getThisFlag(device:Device){

    if(this.selectedDevice){
      if(this.selectedDevice.some(y => y.id === device.id)){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
}
  
closeDialog(){
  this.dialogRef.close();
}


}

