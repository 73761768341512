import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { HeaderText } from '../../../shared/models/header-text.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
@Component({
  selector: 'app-help-me-choose',
  templateUrl: './help-me-choose.component.html',
  styleUrls: ['./help-me-choose.component.css']
})
export class HelpMeChooseComponent implements OnInit {
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  showCompare: boolean = false;
  
  constructor(private srv: GelatoState) {
   }
   header: any;
   message: any;
   
  ngOnInit() {

    this.headerText$.subscribe(x => {
      this.header = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_text
      this.message = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_subtext
    })
    
    
    // design functions
    var add_choice = document.getElementsByClassName("add-choice")
    for(let i = 0; i <= add_choice.length; i++){
      if(add_choice[i]){
        add_choice[i].addEventListener("click", function(){
            const height = document.getElementsByClassName("add-choices")[0].scrollHeight
            var choices = document.getElementsByClassName("choices")[0]
            choices['style'].display == "none" ? choices['style'].display = "block" : choices['style'].display = "none"
            choices.animate([
                // keyframes
                { opacity: 0, transform: `translateY(-${height}px)` }, 
                { opacity: 1, transform: `translateY(-${height}px)`  }
              ], { 
                // timing options
                duration: 700,
                iterations: 1
              });

              document.getElementsByClassName("add-choices")[0].animate([
                // keyframes
                { opacity: 1 }, 
                { opacity: 0 }
              ], { 
                // timing options
                duration: 700,
                iterations: 1
              });
        
              setTimeout(()=>{
                document.getElementsByClassName("add-choices")[0]['style'].display = "none";
              }, 700)
        })
      }
    }

    var choice = document.getElementsByClassName("choice")
    for(let i = 0; i <= choice.length; i++){
      if(add_choice[i]){
        choice[i].addEventListener("click", function(){
          const height = document.getElementsByClassName("choices")[0].scrollHeight
            var selected_choice = document.getElementsByClassName("selected-choice")[0]
            selected_choice['style'].display == "none" ? selected_choice['style'].display = "block" : selected_choice['style'].display = "none"
            selected_choice.animate([
                // keyframes
                { opacity: 0, transform: `translateY(-${height}px)` }, 
                { opacity: 1, transform: `translateY(-${height}px)`  }
              ], { 
                // timing options
                duration: 700,
                iterations: 1
              });

              document.getElementsByClassName("choices")[0].animate([
                // keyframes
                { opacity: 1 }, 
                { opacity: 0 }
              ], { 
                // timing options
                duration: 700,
                iterations: 1
              });
        
              setTimeout(()=>{
                document.getElementsByClassName("choices")[0]['style'].display = "none";
              }, 700)
        })
      }
    }
  }

  compare(){
   this.showCompare = true
  }

}
