import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { Plan } from 'src/app/shared/models/plan.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Router } from '@angular/router';
import { UpdateHTTPRequestFlag, UpdateDevices, AddSelectedDevice, RemoveSelectedDevice, UpdateBillingSummaryPayload, UpdateBillingSummary, AddSelectedMeshDevice, AddSelectedMeshLockin } from 'src/app/shared/actions/gelato.actions';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Devices } from 'src/app/shared/models/devices.model';
import { SelectedDevice } from 'src/app/shared/models/selected-device.model';
import { DeviceComparison } from 'src/app/shared/models/device-comparison.model';
import { CableDeviceBoxComponent } from 'src/app/shared/components/cable-device-box/cable-device-box.component';
import { CableBoxDevice } from 'src/app/shared/models/cable-box-device.model';
import { DeviceFeature } from 'src/app/shared/models/device-feature.model';
import { map } from 'rxjs/operators';
import { Device } from '../../models/device.model';
import * as _ from 'lodash';
@Component({
  selector: 'app-compare-cable-table',
  templateUrl: './compare-cable-table.component.html',
  styleUrls: ['./compare-cable-table.component.css']
})
export class CompareCableTableComponent implements OnInit {
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>
  @Select(GelatoState.getDevices) devices$: Observable<ResponseObject<Devices>>
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  
  deviceFeature:DeviceComparison
  selectedDevices:Device[] =  []
  selectedPlan:Plan[] = []
  billingSummaryPayload:any
  createYourOwnFlag:boolean = false
  selectedService: number
  constructor(private store: Store,private gelatoService:GelatoService, private router: Router) { 
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
  }

  ngOnInit() {
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    this.createYourOwnFlag$.subscribe(x =>{
      if(x) this.createYourOwnFlag = x
    })
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    this.selectedPlans$.subscribe(x => {
      if(x) this.selectedPlan = x
    })
    //GET CABLE PLAN ID TO FILTER AVAILABLE DEVICES
    let planId = this.selectedPlan.filter(x => x.service_id === environment.BIZ_BROADBAND).map(x => x.id)[0]
    this.gelatoService.getDevices(planId).subscribe(x => {
      const cable = x.data["cable_box_devices"] as [CableBoxDevice]
      if(cable){
        this.gelatoService.compareDevicesByID("Cable Box",cable.map(x => x.id),planId).subscribe(x => {
          if(x){
            this.deviceFeature = x.data
          }
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })
      }
    })

    this.selectedDevices$.subscribe(x => {
      this.selectedDevices = x
    })
    
  }
  getThis(device){
   
    //ADD MESH DEVICE
    if(device.device_type_id === environment.DEVICE_MESH){
    
    }else if(this.createYourOwnFlag){
      if(this.selectedDevices){
        // CHANGE CABLE TV DEVICE
        if(this.selectedDevices.length > 0 && this.selectedDevices.some(x=>x.device_type_id === device.device_type_id)){
          const selected = []
          this.selectedDevices.filter(x =>x.device_type_id === device.device_type_id).map((y)=>{
            this.store.dispatch(new RemoveSelectedDevice(y.id))
            this.store.dispatch(new AddSelectedDevice(device))
          

            selected.push({
              "plan_id":0,
              device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id)
            })
            
              this.selectedPlan.map(x=>{
                selected.push({
                  "plan_id":x.id,
                  device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id)
                })
              })
              
             
            
          })
          this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
          //UPDATE BILLING SUMMARY
          
          
          this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
            if(x.status_code === 200){
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }else{
            }
          })
        }
        
        else{
          this.store.dispatch(new AddSelectedDevice(device))
      
          const selected = [];
         this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map((device,i) => {
      
            selected.push({
              "plan_id":0,
              device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id)
            })
          })
    
          this.selectedPlan.map(x => {
            selected.push({
              "plan_id":x.id,
              device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id)
            })
          })
         
          this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
          //UPDATE BILLING SUMMARY
          this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
            if(x.status_code === 200){
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }else{
            }
          })  
        }
      }else{
        this.store.dispatch(new AddSelectedDevice(device))
      
        const selected = [];
       this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map((device,i) => {
    
          selected.push({
            "plan_id":0,
            device_id:[device.id]
          })
        })
  
        this.selectedPlan.map(x => {
          selected.push({
            "plan_id":x.id,
            device_id:x.comes_with.map(x=>x.id)
          })
        })
       
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
        //UPDATE BILLING SUMMARY PAYLOAD
        this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
        //UPDATE BILLING SUMMARY
        this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
          if(x.status_code === 200){
            this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
          }else{
          }
        })  
      }
        
            
         
      // END CREATEYOUROWN PLAN
    }else{
      if(this.selectedDevices){
        if(this.selectedDevices.some(x=>x.device_type_id===device.device_type_id)){
        
          this.selectedDevices.filter(x=>x.device_type_id === device.device_type_id).map(x=>{
            this.store.dispatch(new RemoveSelectedDevice(x.id))
          })
          this.store.dispatch(new AddSelectedDevice(device))
          

          //IF BUNDLE
          if(this.selectedService === environment.BIZ_BROADBAND_CABLE){
            //START UPDATE BUNDLE BILLING SUMMARY PAYLOAD
            const bundle = this.selectedPlan.filter(x => x.service_id === environment.BIZ_BROADBAND_CABLE).map(plan => {
              return{
                plan_id:plan.id,
                device_id:this.selectedDevices.map(x => x.id)
              }
            })
            
            let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",bundle)
            //UPDATE BILLING SUMMARY PAYLOAD
            this.store.dispatch(new UpdateBillingSummaryPayload(payload))
            //UPDATE BILLING SUMMARY
            this.gelatoService.billingSummary(payload).subscribe(x => {
              if(x.status_code === 200){
                this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
              }else{ 
              }
            })  
            //END UPDATE BUNDLE BILLING SUMMARY PAYLOAD
          }else{
            //INTERNET AND TV PLANS
            //START UPDATE INTERNET OR TV BILLING SUMMARY PAYLOAD
           
            const x = this.selectedPlan.filter(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND).map(plan => {
              return{
                plan_id:plan.id,
                //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
                //IF TV GET BOX DEVICES
                device_id:plan.service_id === environment.HOMEBIZ? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
               
              }
            })
            
            let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
            //UPDATE BILLING SUMMARY PAYLOAD
            this.store.dispatch(new UpdateBillingSummaryPayload(payload))
            //UPDATE BILLING SUMMARY
            this.gelatoService.billingSummary(payload).subscribe(x => {
              if(x.status_code === 200){
                this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
              }else{ 
              }
            })
          
            //END UPDATE INTERNET OR TV BILLING SUMMARY PAYLOAD

          }
        }else{
            //START ADD DEVICE IF INTERNET PLAN SELECTED
          this.store.dispatch(new AddSelectedDevice(device))
            const x = this.selectedPlan.filter(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND).map(plan => {
              return{
                plan_id:plan.id,
                //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
                //IF TV GET BOX DEVICES
                device_id:plan.service_id === environment.HOMEBIZ? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
               
              }
            })
            
            let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
            //UPDATE BILLING SUMMARY PAYLOAD
            this.store.dispatch(new UpdateBillingSummaryPayload(payload))
            //UPDATE BILLING SUMMARY
            this.gelatoService.billingSummary(payload).subscribe(x => {
              if(x.status_code === 200){
                this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
              }else{ 
              }
            })
        }
      }else{
        // ADD DEVICE
        const selected = []
          this.store.dispatch(new AddSelectedDevice(device))
            this.selectedPlan.map(x=>{
              selected.push({
                "plan_id":x.id,
                device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE || x.device_type_id === environment.DEVICE_MODEM).map(x => x.id)
              })
            })
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
        //UPDATE BILLING SUMMARY PAYLOAD
        this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
        //UPDATE BILLING SUMMARY
        
        
        this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
          if(x.status_code === 200){
            this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
          }else{
          }
        })
        
      }
    }
    if(device.device_type_id === environment.DEVICE_MODEM || device.device_type_id === environment.DEVICE_MESH){
      let meshDeviceFlag = this.selectedDevices.some(x => x.device_type_id === environment.DEVICE_MESH)
      //IF PLAN COMES WITH A MESH DEVICE
      if(meshDeviceFlag){
        this.router.navigate(['/internet-plan/plan-summary'])
      }else{
        this.store.dispatch(new UpdateHTTPRequestFlag(true))
          this.gelatoService.getDevices().subscribe(x => {
            this.store.dispatch(new UpdateDevices(x))
            this.store.dispatch(new UpdateHTTPRequestFlag(false))
          })
        this.router.navigate(['/internet-plan/select-addon'])
      }
    }else{
      this.router.navigate(['/tv-plan/select-addon'])
    }
  }

  getThisFlag(device:Device){
    
    if(this.selectedDevices){
      if(this.selectedDevices.some(y => y.id === device.id)){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
}

}
