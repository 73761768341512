import { OnboardingContainerComponent } from './pages/onboarding-container/onboarding-container.component';
import { SelectLocationComponent } from './pages/select-location/select-location.component';
import { AvailableLocationComponent } from './pages/available-location/available-location.component';
import { SetLocationComponent } from './pages/set-location/set-location.component';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ValidateAddressComponent } from './pages/validate-address/validate-address.component';



const routes: Routes = [
  {
    path: "onboarding", component: OnboardingContainerComponent,
    children: [
      { path: "set-location", component: SetLocationComponent },
      { path: "select-location", component: SelectLocationComponent },
      { path: "available-location", component: AvailableLocationComponent },
      { path: "validate-location", component: ValidateAddressComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingRoutingModule { }
