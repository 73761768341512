import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-final-steps',
  templateUrl: './final-steps.component.html',
  styleUrls: ['./final-steps.component.css']
})
export class FinalStepsComponent implements OnInit {

  otherContent : boolean = false
  modemChoose : boolean = false
  meshChoose : boolean = false
  meshBenefits : boolean = false

  constructor() { }

  ngOnInit() {


    // Animations
    var internet_cards = document.getElementsByClassName('internet-card')
    for(let i = 0; i < internet_cards.length; i++){
        internet_cards[i].addEventListener("click", function(){
           
            // toggle internet-card-active
            this.className.includes("internet-card-active") ? this.className = "internet-card mx-auto col-12 d-flex flex-column p-0" : this.className += " internet-card-active"
            var content = this.children[1];
            if(content.style.maxHeight) {
                content.style.maxHeight = null;
                document.getElementsByClassName("internet-card-content")[i].children[0]['style'].display = "none"
                // document.getElementsByClassName("service-fee-toggle")[0]['style'].display = "none"
            }
            else{
                content.style.maxHeight = "900px";
                document.getElementsByClassName("internet-card-content")[i].children[0]['style'].display = "block"
                // document.getElementsByClassName("service-fee-toggle")[0]['style'].display = "block"
            }
           
        })
    }
  }

  showContent(choice?: any, e?:any){
   
    switch (choice) {
      case 1:
        this.otherContent = e
        break;
      case 2:
        window.scrollTo({top: 0, behavior: 'smooth'})
        this.modemChoose = e
        break;
      case 3:
        window.scrollTo({top: 0, behavior: 'smooth'})
        this.meshChoose = e
        break;
      case 4:
        window.scrollTo({top: 0, behavior: 'smooth'})
        this.meshBenefits = e
        break;
      case undefined:
        this.modemChoose = false
        this.meshBenefits = false
        this.meshChoose = false
      default:
        break;
    }
  }

}
