import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { Address } from 'src/app/shared/models/address.model';
import { UpdateGoogleAddress, UpdateGoogleLATLONG, UpdateSelectedService, UpdateSelectedServiceCategory } from 'src/app/shared/actions/gelato.actions';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.css']
})
export class SelectLocationComponent implements OnInit {
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  @Select(GelatoState.getGoogleCoordinates) googleCoordinates$: Observable<number[]>;
  latitude:number
  longitude:number
  zoom:number = 18
  googleAddress:string
  constructor(private router:Router,private store:Store) { }

  ngOnInit() {
    this.googleCoordinates$.subscribe(x => {
      this.latitude = x[0]
      this.longitude = x[1]
    })
    this.googleAddress$.subscribe(x => {
      if(x) this.googleAddress = x
    })
  }

  confirm(){
    const mysky = localStorage.getItem("mysky")
    
    if(mysky === "homeinternet"){
      this.store.dispatch(new UpdateSelectedService(1))
      this.store.dispatch(new UpdateSelectedServiceCategory("Internet"))
      this.router.navigate(["/internet-plan/select-plan"])
    }else if(mysky === "cable"){
      this.store.dispatch(new UpdateSelectedService(2))
      this.store.dispatch(new UpdateSelectedServiceCategory("Cable TV"))
      // Remove standard plan and uncomment choose-plan to show choose tv plan page
      this.router.navigate(["/tv-plan/standard-plan"])
    }else if(mysky === "homeinternet cabletv"){
      this.store.dispatch(new UpdateSelectedService(3))
      this.store.dispatch(new UpdateSelectedServiceCategory("Internet + Cable TV"))
      this.router.navigate(["/bundle-plan/select-plan"])
    }else{
      this.router.navigate(["/onboarding/available-location"])
    }
  }
  changeAddress(){
    const mysky = localStorage.getItem("mysky")
    const application_type = localStorage.getItem("application-type")
    window.location.href = `/onboarding/set-location?application-type=${application_type}`
  }

  markerDragEnd($event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    let geocoder = new google.maps.Geocoder();
    
    const latlng = {
      lat: this.latitude,
      lng: this.longitude,
    };
    geocoder.geocode( { location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          localStorage.setItem("google_coordinates",JSON.stringify([results[0].geometry.location.lat(),results[0].geometry.location.lng()]))
          localStorage.setItem("google_address",results[0].formatted_address)
          this.store.dispatch(new UpdateGoogleLATLONG(results[0].geometry.location.lat(),results[0].geometry.location.lng()))
          this.store.dispatch(new UpdateGoogleAddress(results[0].formatted_address))
        }
      }
    })
    
    this.store.dispatch(new UpdateGoogleLATLONG(this.latitude,this.longitude))
  }
}
