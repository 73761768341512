import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet-mesh-details',
  templateUrl: './internet-mesh-details.component.html',
  styleUrls: ['./internet-mesh-details.component.css']
})
export class InternetMeshDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
