import { environment } from 'src/environments/environment';
import { UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { UpdateDevices,UpdateDeviceDetail } from './../../../../shared/actions/gelato.actions';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs/internal/Observable';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Devices } from 'src/app/shared/models/devices.model';
import { MatDialog } from '@angular/material/dialog';
import { TvSelectDeviceModalComponent } from '../../../../shared/components/tv-select-device-modal/tv-select-device-modal.component'
import { Plan } from 'src/app/shared/models/plan.model';
import { Device } from 'src/app/shared/models/device.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { Pack } from 'src/app/shared/models/pack.model';

@Component({
  selector: 'app-addon',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.css']
})
export class AddonComponent implements OnInit {
  @Select(GelatoState.getDevices) devices$: Observable<ResponseObject<Devices>>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$:Observable<[Plan]>
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getSelectedDevices) selectedDevice$: Observable<[Device]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  selectedPlans:Plan[] = []
  meshFlag:boolean = false
  dialog: any;
  selectedId:any;
  requestFlag:boolean = true
  hasInternetPlanSelectedFlag:boolean = false
  hasCablePackCHannelFlag:boolean = false
  hasTvPlanSelectedFlag:boolean = false
  selectedPacks:Pack[] = []
  selectedChannels:Channel[] = []
  selectedDevice:Device[] = []
  selectedService:number
  constructor(private router: Router,private store: Store,private gelatoService:GelatoService) {
   
   }

  ngOnInit() {
    
    this.selectedService$.subscribe(x => {
      if(x) this.selectedService = x
    })
    this.selectedPlans$.subscribe(x => {
      if(x){
        this.selectedPlans = x
        if(x.length > 0) this.hasTvPlanSelectedFlag = x.some(x => x.service_id === environment.BIZ_BROADBAND)

      }else if(this.selectedChannels.map(x=>x.id).length > 0 || this.selectedPacks.map(x=>x.id).length>0){
        this.hasCablePackCHannelFlag = true
      }
      
    })

   
    //GET CABLE PLAN ID TO FILTER AVAILABLE DEVICES
    let planId = this.selectedPlans.filter(x => x.service_id === environment.BIZ_BROADBAND).map(x => x.id)[0]
    this.gelatoService.getDevices(planId).subscribe(x => {
      this.store.dispatch(new UpdateDevices(x))
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.requestFlag = false
      //UNUSED CODE
      if(x.data["cable_box_devices"].length==2){
      }
    })

    this.selectedDevice$.subscribe(x => {
      if(x){
        this.selectedDevice = x
      }
    })

  }
  
  nextFlag(){
    
    return !(this.selectedDevice.filter(x => x.device_type_id === environment.DEVICE_CABLE).length > 0)
  }

  toggleMesh(){
    this.meshFlag = !this.meshFlag
  }

  

  nextClick(){
    if(this.selectedService === environment.BIZ_BROADBAND){
      if(this.selectedPlans.some(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND_CABLE)){
        //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
        if(this.selectedPlans.filter(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND_CABLE).some(x => x.is_upgradeable === 1)){
          this.router.navigate(['/internet-plan/select-device'])
        }else{
          let meshDeviceFlag = this.selectedDevice.some(x => x.device_type_id === environment.DEVICE_MESH)
          //IF PLAN COMES WITH A MESH DEVICE
          if(meshDeviceFlag){
            this.router.navigate(['/internet-plan/plan-summary'])
          }else{
            this.router.navigate(['/internet-plan/select-addon'])
          }
        }
      }else{
        this.router.navigate(['/tv-plan/plan-summary'])
      }
      
    }else{
      this.router.navigate(['/tv-plan/plan-summary'])
    }
    
  }
  back(){
    window.history.back()
  }
  


}
