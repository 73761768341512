import { environment } from './../../../../environments/environment';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit {

  @Input() progress: number;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getVisitedPages) visitedPages$: Observable<[any]>;
  visitedPages: [any]
  planRoute: String
  summaryRoute: String

  constructor(private router: Router) {
    this.selectedService$.subscribe(x => {
      switch (x) {
        case environment.FIBER_CONNECT_PLANS:
          this.planRoute = 'internet-plan/select-plan'
          this.summaryRoute = 'internet-plan/plan-summary'
          break;
        case environment.BIZ_BROADBAND:
          this.planRoute = 'internet-plan/select-plan'
          this.summaryRoute = 'internet-plan/plan-summary'
          break;
        case environment.HOMEBIZ:
          this.planRoute = 'internet-plan/select-plan'
          this.summaryRoute = 'internet-plan/plan-summary'
          break;
        case environment.FIBER_CONNECT_HDTV_PLANS:
          this.planRoute = 'bundle-plan/select-plan'
          this.summaryRoute = 'bundle-plan/plan-summary'
          break;
        case environment.BIZ_BROADBAND_CABLE:
          this.planRoute = 'bundle-plan/select-plan'
          this.summaryRoute = 'bundle-plan/plan-summary'
          break;
        default:
          break;
      }
    })

    this.visitedPages$.subscribe(x => {
      this.visitedPages = x
    })
   }

  ngOnInit() {
    for(let x = 0; x <= 3; ++x){
      if(this.progress > x){
        document.getElementsByClassName('progressbar')[0].children[x].classList.add('active');
        document.getElementsByClassName('progressbar')[0].children[x+1]['style'].color = "#0A3797";
        document.getElementsByClassName('progressbar')[0].children[x+1].children[0]['style'].background = "#E64236";
      }
    }
    
  }

  goTo(e,route){
      switch (route) {
        case 1:
          if(e.includes('active')){
            this.router.navigate(['onboarding/available-location'])
          }
          else if(this.visitedPages.includes('available')){
            this.router.navigate(['onboarding/available-location'])
          }
          break;

        case 2:
          if(e.includes('active')) {
            this.router.navigate([this.planRoute])
          }
          else if(this.visitedPages.includes('select-plan')){
            this.router.navigate([this.planRoute])
          }
          break;
        
        case 3:
          if(e.includes('active')){
            this.router.navigate([this.summaryRoute])
          }
          else if(this.visitedPages.includes('summary')){
            this.router.navigate([this.summaryRoute])
          }
          break;
        
        case 4:
          if(e.includes('active')){
            this.router.navigate(['application-form'])
          }
          else if(this.visitedPages.includes('form')){
            this.router.navigate(['application-form'])
          }
          break;

        default:
          break;
      }
  }

}
