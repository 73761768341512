import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { Plan } from 'src/app/shared/models/plan.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { Pack } from 'src/app/shared/models/pack.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Service } from 'src/app/shared/models/service.model';
import { Address } from 'src/app/shared/models/address.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { UpdateDevices, UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { Device } from 'src/app/shared/models/device.model';

@Component({
  selector: 'app-create-tv-plan',
  templateUrl: './create-tv-plan.component.html',
  styleUrls: ['./create-tv-plan.component.css']
})
export class CreateTvPlanComponent implements OnInit {
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getCableLite) cableLite$: Observable<boolean>;
  @Select(GelatoState.getSelectedDevices) selectedDevice$: Observable<[Device]>;
  device: Device[] = []
  selectedPacks: [Pack]
  selectedChannels: [Channel]
  editMode: boolean
  selectedService: number
  selectedPlans:[Plan]
  Address:string
  message:String = ""
  noService: boolean = false
  services:Service[] = []
  cableLite:boolean = false
  constructor(private router: Router,private store:Store,private gelatoService:GelatoService) { 
    this.selectedChannels$.subscribe(x => {
      this.selectedChannels = x
    })

    this.selectedPacks$.subscribe(x => {
      this.selectedPacks = x
    })
  }

  ngOnInit() {
    this.services$.subscribe(x =>{
      if(x) this.services = x.data['services']
    })
    this.selectedPlans$.subscribe(x => {
      if (x) this.selectedPlans = x
    })
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    
    this.editMode$.subscribe(x => {
      this.editMode = x
    })

    this.cableLite$.subscribe(x => {
      this.cableLite = x
    })
    this.selectedDevice$.subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.device = x
        }
      }
    })
  }

  nextClick(){
    if(this.cableLite){
      this.router.navigate(['/tv-plan/lite-select-device'])
    }
    else if(this.selectedService == environment.BIZ_BROADBAND){
      let meshDeviceFlag = this.device.some(x => x.device_type_id === environment.DEVICE_MESH)
      //IF PLAN COMES WITH A MESH DEVICE
      if(meshDeviceFlag){
        this.router.navigate(['/internet-plan/plan-summary'])
      }else{
        let hasAvailableMesh: boolean
        this.store.dispatch(new UpdateHTTPRequestFlag(true))
        let sub2 = this.gelatoService.getDevices().subscribe(x => {
          this.store.dispatch(new UpdateDevices(x))
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
          x.data.mesh_devices.length > 0 ? hasAvailableMesh = true : hasAvailableMesh = false
          if(hasAvailableMesh){
            if(this.selectedPlans.some(plan => plan.with_mesh == 1)){
              this.router.navigate(['/internet-plan/select-addon']).then(x => {
                sub2.unsubscribe()
              })
            }
            else{
              this.router.navigate(['/internet-plan/plan-summary']).then(x => {
                sub2.unsubscribe()
              })
            }
          }
          else{
            this.router.navigate(['/internet-plan/plan-summary']).then(x => {
              sub2.unsubscribe()
            })
          }
        })
      }
    }else if(this.selectedService == environment.BIZ_BROADBAND_CABLE){
      let sub1 = this.selectedCategory$.subscribe(x => {
        if(x){
          if(x === "FTTH" || x === "FTTO"){
            this.router.navigate(['/bundle-plan/plan-summary'])
          }else{
            let meshDeviceFlag = this.device.some(x => x.device_type_id === environment.DEVICE_MESH)
            //IF PLAN COMES WITH A MESH DEVICE
            if(meshDeviceFlag){
              this.router.navigate(['/internet-plan/plan-summary'])
            }else{
              let hasAvailableMesh: boolean
              this.store.dispatch(new UpdateHTTPRequestFlag(true))
              let sub2 = this.gelatoService.getDevices().subscribe(x => {
                this.store.dispatch(new UpdateDevices(x))
                this.store.dispatch(new UpdateHTTPRequestFlag(false))
                x.data.mesh_devices.length > 0 ? hasAvailableMesh = true : hasAvailableMesh = false
                if(hasAvailableMesh){
                  if(this.selectedPlans.some(plan => plan.with_mesh == 1)){
                    this.router.navigate(['/internet-plan/select-addon']).then(x => {
                      sub1.unsubscribe()
                      sub2.unsubscribe()
                    })
                  }
                  else{
                    this.router.navigate(['/internet-plan/plan-summary']).then(x => {
                      sub1.unsubscribe()
                      sub2.unsubscribe()
                    })
                  }
                }
                else{
                  this.router.navigate(['/internet-plan/plan-summary']).then(x => {
                    sub1.unsubscribe()
                    sub2.unsubscribe()
                  })
                }
              })
            }
          }
        }
      })
    }else{
      let sub1 = this.selectedCategory$.subscribe(x => {
        if(x){
          if(x === "FTTH" || x === "FTTO"){
            this.router.navigate(['/internet-plan/plan-summary'])
          }else{
            //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
            if(this.selectedPlans.filter(x => x.service_id === environment.HOMEBIZ).some(x => x.is_upgradeable === 1)){
              this.router.navigate(['/internet-plan/select-device'])
            }else{
              let meshDeviceFlag = this.device.some(x => x.device_type_id === environment.DEVICE_MESH)
              //IF PLAN COMES WITH A MESH DEVICE
              if(meshDeviceFlag){
                this.router.navigate(['/internet-plan/plan-summary'])
              }else{
                let hasAvailableMesh: boolean
                this.store.dispatch(new UpdateHTTPRequestFlag(true))
                let sub2 = this.gelatoService.getDevices().subscribe(x => {
                  this.store.dispatch(new UpdateDevices(x))
                  this.store.dispatch(new UpdateHTTPRequestFlag(false))
                  x.data.mesh_devices.length > 0 ? hasAvailableMesh = true : hasAvailableMesh = false
                  if(hasAvailableMesh){
                    this.router.navigate(['/internet-plan/select-addon']).then(x => {
                      sub1.unsubscribe()
                      sub2.unsubscribe()
                    })
                  }
                  else{
                    this.router.navigate(['/internet-plan/plan-summary']).then(x => {
                      sub1.unsubscribe()
                      sub2.unsubscribe()
                    })
                  }
                })
              }
            }
          }
        }
      })
    }
  }

  nextFlag(){
    if(this.selectedPacks || this.selectedChannels){
      if(this.selectedPacks && this.selectedPacks.length > 0){
        return true
      }
      if(this.selectedChannels && this.selectedChannels.length > 0){
        return true
      }
      else{
        return false
      }
    }
    else{
      return false
    }
  }

 

}
