import { Building } from './../../../../shared/models/building.model';
import { map } from 'rxjs/operators';
import { HeaderText } from './../../../../shared/models/header-text.model';
import { Street } from './../../../../shared/models/street.model';
import { Barangay } from './../../../../shared/models/barangay.model';
import { Province } from 'src/app/shared/models/province.model';
import { UpdateProvinces, UpdateCities, UpdateBarangays, UpdateStreets, UpdateFormAddress, UpdateHTTPRequestFlag, UpdateBuildings, UpdateGoogleLATLONG, UpdateGoogleAddress, UpdateApplicationType } from './../../../../shared/actions/gelato.actions';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GelatoState } from './../../../../shared/state/gelato.state';
import { GelatoService } from './../../../../core/services/gelato.service';
import { Store, Select } from '@ngxs/store';
import {  UpdateHeaderText } from '../../../../shared/actions/gelato.actions'
import { Observable } from 'rxjs';
import { City } from 'src/app/shared/models/city.model';


@Component({
  selector: 'app-set-location',
  templateUrl: './set-location.component.html',
  styleUrls: ['./set-location.component.css']
})
export class SetLocationComponent implements OnInit {
  
  

  addressForm: FormGroup
  isDisabled : boolean = true
  submitted: boolean = false
  latitude:number =  14.6090537
  longitude:number = 121.0222565
  zoom:number = 18
  autoCompleteOptions:any
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getProvinces) provinces$: Observable<[Province]>;
  @Select(GelatoState.getCities) cities$: Observable<[City]>;
  @Select(GelatoState.getBuildings) buildings$: Observable<[Building]>;
  @Select(GelatoState.getBarangays) barangays$: Observable<[Barangay]>;
  @Select(GelatoState.getStreets) streets$: Observable<[Street]>;
  

  constructor(private route:ActivatedRoute, private formBuilder: FormBuilder, private router: Router,private store: Store,private gelatoService:GelatoService) { 
    this.route.queryParams.subscribe(params => {
      if(params['service']){
        localStorage.setItem("mysky",params['service'])
      } if(params['application-type']){
        this.store.dispatch(new UpdateApplicationType(params['application-type']))
        localStorage.setItem("application-type",params['application-type'])
      } else{
        localStorage.removeItem("mysky")
        localStorage.removeItem("application-type")
      }
    });
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    gelatoService.getHeaderText().subscribe(x => {
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.store.dispatch(new UpdateHeaderText(x.data))
    })
    //LOAD PROVINCES
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    gelatoService.getProvinces().subscribe(x => {
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.store.dispatch(new UpdateProvinces(x.data["provinces"]))
    })
    

  }

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      unit_no: ['',Validators.compose([Validators.required,Validators.pattern("^[a-zA-Z0-9 ]+$")])],
      street: ['',Validators.required],
      subd: ['',Validators.compose([Validators.pattern("^[a-zA-Z0-9\\-\\s\\ñ\\Ñ']+$")])],
      province: ['',Validators.required],
      city: ['',Validators.required],
      building: [''],
      barangay: ['',Validators.required],
      zip_code: ['',Validators.compose([Validators.required, Validators.pattern('[0-9]*')])]
    })
   
    
    this.autoCompleteOptions = {
      bounds:{"south":14.568294,"west":120.7404375,"north":14.6090537,"east":121.0222565},
      location:{lat: 14.568294,lng: 120.7404375},
      
      componentRestrictions: {country: 'ph'},
      strictBounds: true
    }
    
  }

  loadSelectData(event) {

    if(event.target.name === "province"){
      //LOAD CITIES
      const province = this.addressForm.controls["province"].value
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.getCities(province.province_code).subscribe(x => {
        this.store.dispatch(new UpdateCities(x.data["cities"]))
        this.store.dispatch(new UpdateBarangays(x.data[""]))
        this.store.dispatch(new UpdateStreets(x.data[""]))
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })

      // Reset other select
      document.getElementsByClassName('province')[0]['style'].color = 'black'
      document.getElementsByClassName('city')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('barangay')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('building')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      this.addressForm.controls['city'].patchValue('')
      this.addressForm.controls['barangay'].patchValue('')
      this.addressForm.controls['street'].patchValue('')
    }else if(event.target.name === "city"){
      //LOAD BARANGAYS
      const province = this.addressForm.controls["province"].value
      const city = this.addressForm.controls["city"].value
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.getBarangays(province.province_code,city.city_code).subscribe(x => {
        this.store.dispatch(new UpdateBarangays(x.data["barangays"]))
        this.store.dispatch(new UpdateStreets(x.data[""]))
      })
      this.gelatoService.getBuildings(province.province_code,city.city_code).subscribe(x => {
        this.store.dispatch(new UpdateBuildings(x.data["buildings"]))
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })

      // Reset other select
      document.getElementsByClassName('city')[0]['style'].color = 'black'
      document.getElementsByClassName('barangay')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('building')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      this.addressForm.controls['barangay'].patchValue('')
      this.addressForm.controls['street'].patchValue('')
    }else if(event.target.name === "barangay"){
      //LOAD STREETS
      const province = this.addressForm.controls["province"].value
      const city = this.addressForm.controls["city"].value
      const barangay = this.addressForm.controls["barangay"].value
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.gelatoService.getStreets(province.province_code,city.city_code,barangay.brgy_code).subscribe(x => {
        this.store.dispatch(new UpdateStreets(x.data["streets"]))
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })

      // Reset other select
      document.getElementsByClassName('barangay')[0]['style'].color = 'black'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      this.addressForm.controls['street'].patchValue('')
    }else if(event.target.name === "building"){
      // Reset other select
      document.getElementsByClassName('building')[0]['style'].color = 'black'
      document.getElementsByClassName('barangay')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      document.getElementsByClassName('street')[0]['style'].color = 'rgba(0, 0, 0, 0.38)'
      
    }
    else document.getElementsByClassName('street')[0]['style'].color = 'black'
    
  }

  get f() { return this.addressForm.controls; }
  setLocation(){
    this.submitted = true
    if (this.addressForm.dirty && this.addressForm.valid) {
      this.store.dispatch(new UpdateFormAddress(this.addressForm.value))
      this.store.dispatch(new UpdateGoogleAddress(null))
      localStorage.setItem("address",JSON.stringify(this.addressForm.value))
      localStorage.removeItem("google_coordinates")
      localStorage.removeItem("google_address")
      this.router.navigate(['/onboarding/select-location'])
    } 
  }
 
  onAutocompleteSelected(result: any) {
    localStorage.setItem("google_coordinates",JSON.stringify([result.geometry.location.lat(),result.geometry.location.lng()]))
    localStorage.setItem("google_address",result.formatted_address)
    this.store.dispatch(new UpdateGoogleLATLONG(result.geometry.location.lat(),result.geometry.location.lng()))
    this.store.dispatch(new UpdateGoogleAddress(result.formatted_address))
    this.store.dispatch(new UpdateFormAddress(null))
    localStorage.removeItem("address")
    this.router.navigate(['/onboarding/select-location'])
  }
  setGoogleLocation(){
    if (navigator.geolocation) {
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          
          let geocoder = new google.maps.Geocoder();
          
          geocoder.geocode( { location: pos }, (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                this.store.dispatch(new UpdateHTTPRequestFlag(false))
                localStorage.setItem("google_coordinates",JSON.stringify([position.coords.latitude,position.coords.longitude]))
                localStorage.setItem("google_address",results[0].formatted_address)
                this.store.dispatch(new UpdateGoogleAddress(results[0].formatted_address))
                this.store.dispatch(new UpdateGoogleLATLONG(position.coords.latitude,position.coords.longitude))
                this.router.navigate(['/onboarding/select-location'])
              }
            }
          })
           
           
        },
        (e) => {
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
          console.log(e)
        }
      );
    } else {
      // Browser doesn't support Geolocation
      console.log("Browser doesn't support Geolocation")
    }
    
  }
  onLocationSelected(location: any) {
    
    this.latitude = location.latitude;
    this.longitude = location.longitude;
    this.zoom = 18
  }
  noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true }
  }
}
