import { Component, OnInit, Input } from '@angular/core';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';

@Component({
  selector: 'app-plan-box-container',
  templateUrl: './plan-box-container.component.html',
  styleUrls: ['./plan-box-container.component.css']
})
export class PlanBoxContainerComponent implements OnInit {
  @Input() servicePlan: ServicePlan;
  constructor() { }

  ngOnInit() {
  }

}
