import { AddSelectedDevice, AddSelectedMeshDevice, AddSelectedMeshLockin, RemoveSelectedDevice, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdateSelectedAvailableLockinPeriod, RemoveSelectedMeshDevice, RemoveSelectedMeshLockin } from './../../actions/gelato.actions';
import { Device } from 'src/app/shared/models/device.model';
import { MeshDevice } from './../../models/mesh-device.model';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateDeviceDetail } from '../../actions/gelato.actions';
import { ModemViewDetailsModalComponent } from '../modem-view-details-modal/modem-view-details-modal.component';
import { AvailableLockinPeriod } from '../../models/available-lockin-period.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeviceDetail } from '../../models/device-detail.model';
import { Plan } from '../../models/plan.model';
import { ResponseObject } from '../../models/response-object.model';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mesh-device-box',
  templateUrl: './mesh-device-box.component.html',
  styleUrls: ['./mesh-device-box.component.css']
})
export class MeshDeviceBoxComponent implements OnInit {
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>;
  @Input() meshDevice: MeshDevice;
  selectedMesh:[Device]
  panelOpen: boolean = false;
  selected: AvailableLockinPeriod
  form: FormGroup
  hasError:boolean = false

  
  @Select(GelatoState.getSelectedLockin) selectedLockin$: Observable<AvailableLockinPeriod>
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>
  @Select(GelatoState.getDeviceDetail) deviceDetails$: Observable<ResponseObject<DeviceDetail>>
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>
  @Select(GelatoState.getSelectedPlans) selectedPlan$: Observable<[Plan]>;
  @Select(GelatoState.getEditMode) edit$: Observable<boolean>;
  @Select(GelatoState.getApplyButtonFlag) applyButtonFlag$: Observable<boolean>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCableDeviceId) CableId$: Observable<[any]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>

  selectedPlan:Plan[] = []
  selectedDevices:Device[] =  []
  selectedCableLockin:[AvailableLockinPeriod]
  selectedMeshLockin:[AvailableLockinPeriod]
  selectedCableDevice:any[] = []
  selectedMeshDevice:any[] = []
  errorMessage = ""
  selectedLockin:AvailableLockinPeriod
  createYourOwnFlag:boolean = false
  selectedService: number
  billingSummaryPayload:any
  selectedPlans:Plan[] = []
  selectedDevice:[Device]

  constructor(private router: Router, private _snackBar: MatSnackBar,private formBuilder: FormBuilder,public dialog: MatDialog,private store: Store,private gelatoService:GelatoService) { }

  ngOnInit() {
    
    this.form = this.formBuilder.group({
      lockin: ['',Validators.required],
    })

    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMesh = x
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevice = x
    })
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    this.selectedLockin$.subscribe(x => {
      this.selectedLockin = x
    })

    this.createYourOwnFlag$.subscribe(x =>{
      if(x) this.createYourOwnFlag = x
    })
    this.selectedPlan$.subscribe(x => {
      if(x) this.selectedPlan = x
     
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })
    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
      else this.selectedCableDevice = []
    })
  
    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMeshDevice = x
      else this.selectedMeshDevice = []
    })
   
    
    this.selectedPlans$.subscribe(x => {
      if(x) this.selectedPlans = x
    })
  }

  openModal(id){
    //UPDATE PLAN_DETAIL
    this.gelatoService.getDeviceDetail(`${id}`).subscribe(x => {
      this.store.dispatch(new UpdateDeviceDetail(x))
      const dialogRef = this.dialog.open(ModemViewDetailsModalComponent,{
        height: '95%',
        width: '660px',
      });
    })
    

    
  }

  getFlag(mesh){
    if(this.selectedMesh){
      return this.selectedMesh.some(x => x.id === mesh.id)
    }else{
      return false
    }
  }

  getThis(id){
    this.gelatoService.getDeviceDetail(`${id}`).subscribe(x => {
    //   if(this.selectedPlan.some(e=>e.service_category === "Internet"))
    //   {
        this.panelOpen = true
        this.getBox(x.data['device'])
    //   }
    //   else
    //   {
    //     this.openSnackBar("Please select a plan first.","Close")
    //   }
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
    
    this.router.navigate(['/internet-plan/select-plan'])
  }

  getBox(device){
   
    //ADD MESH DEVICE
    if(device.device_type_id === environment.DEVICE_MESH){
      this.selectedMeshDevice.map(x => {
        this.store.dispatch(new RemoveSelectedMeshDevice(x.id))
        this.store.dispatch(new RemoveSelectedMeshLockin(x.lockin_period_id))
      })
      this.store.dispatch(new AddSelectedMeshDevice(device))  
      this.store.dispatch(new AddSelectedMeshLockin(device.lockin_period_id))  
      const selected = []
      const selectedMesh = this.selectedMeshDevice.map(x => {
        selected.push({
          "device_id":x.id,
          "lockin_period_id":x.lockin_period_id || x.lockin_period['id'],
          "service_id": this.selectedService
        })
       return{
        device_id:x.id,
        "lockin_period_id":x.lockin_period_id || x.lockin_period['id'],
        "service_id": this.selectedService
       }
        
      })
      const selectedCable = this.selectedCableDevice.map(x => {
        selected.push({
          "device_id":x.id,
          "lockin_period_id":x.lockin_period_id 
        })
        return {
          
            device_id:x.id,
            "lockin_period_id":x.lockin_period_id 
        }
      })
      let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.devices",selected)
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(payload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(payload).subscribe(x => {
        if(x.status_code === 200){
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }else{ 
        }
      })  
      //END UPDATE MESH BILLING SUMMARY PAYLOAD
      //CREATEYOUROWN PLAN 
    }else if(this.createYourOwnFlag){
      if(this.selectedDevice){
        // CHANGE CABLE TV DEVICE
        if(this.selectedDevice.length > 0 && this.selectedDevice.some(x=>x.device_type_id === device.device_type_id)){
          const selected = []
          this.selectedDevice.filter(x =>x.device_type_id === device.device_type_id).map((y)=>{
            this.store.dispatch(new RemoveSelectedDevice(y.id))
            this.store.dispatch(new AddSelectedDevice(device))
          

            selected.push({
              "plan_id":0,
              device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id)
            })
            
              this.selectedPlan.map(x=>{
                selected.push({
                  "plan_id":x.id,
                  device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id)
                })
              })
              
             
            
          })
          this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
          //UPDATE BILLING SUMMARY
          
          
          this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
            if(x.status_code === 200){
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }else{
            }
          })
        }
        
        else{
          this.store.dispatch(new AddSelectedDevice(device))
      
          const selected = [];
         this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map((device,i) => {
      
            selected.push({
              "plan_id":0,
              device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id)
            })
          })
    
          this.selectedPlan.map(x => {
            selected.push({
              "plan_id":x.id,
              device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id)
            })
          })
         
          this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
          //UPDATE BILLING SUMMARY
          this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
            if(x.status_code === 200){
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }else{
            }
          })  
        }
      }else{
        this.store.dispatch(new AddSelectedDevice(device))
      
        const selected = [];
       this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map((device,i) => {
    
          selected.push({
            "plan_id":0,
            device_id:[device.id]
          })
        })
  
        this.selectedPlan.map(x => {
          selected.push({
            "plan_id":x.id,
            device_id:x.comes_with.map(x=>x.id)
          })
        })
       
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
        //UPDATE BILLING SUMMARY PAYLOAD
        this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
        //UPDATE BILLING SUMMARY
        this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
          if(x.status_code === 200){
            this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
          }else{
          }
        })  
      }
        
            
         
      // END CREATEYOUROWN PLAN
    }else{
      if(this.selectedDevice){
        if(this.selectedDevices.some(x=>x.device_type_id===device.device_type_id)){
        
          this.selectedDevices.filter(x=>x.device_type_id === device.device_type_id).map(x=>{
            this.store.dispatch(new RemoveSelectedDevice(x.id))
          })
          this.store.dispatch(new AddSelectedDevice(device))
          

          //IF BUNDLE
          if(this.selectedService === environment.BIZ_BROADBAND_CABLE){
            //START UPDATE BUNDLE BILLING SUMMARY PAYLOAD
            const bundle = this.selectedPlan.filter(x => x.service_id === environment.BIZ_BROADBAND_CABLE).map(plan => {
              return{
                plan_id:plan.id,
                device_id:this.selectedDevices.map(x => x.id)
              }
            })
            
            let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",bundle)
            //UPDATE BILLING SUMMARY PAYLOAD
            this.store.dispatch(new UpdateBillingSummaryPayload(payload))
            //UPDATE BILLING SUMMARY
            this.gelatoService.billingSummary(payload).subscribe(x => {
              if(x.status_code === 200){
                this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
              }else{ 
              }
            })  
            //END UPDATE BUNDLE BILLING SUMMARY PAYLOAD
          }else{
            //INTERNET AND TV PLANS
            //START UPDATE INTERNET OR TV BILLING SUMMARY PAYLOAD
           
            const x = this.selectedPlan.filter(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND).map(plan => {
              return{
                plan_id:plan.id,
                //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
                //IF TV GET BOX DEVICES
                device_id:plan.service_id === environment.HOMEBIZ? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
               
              }
            })
            
            let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
            //UPDATE BILLING SUMMARY PAYLOAD
            this.store.dispatch(new UpdateBillingSummaryPayload(payload))
            //UPDATE BILLING SUMMARY
            this.gelatoService.billingSummary(payload).subscribe(x => {
              if(x.status_code === 200){
                this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
              }else{ 
              }
            })
          
            //END UPDATE INTERNET OR TV BILLING SUMMARY PAYLOAD

          }
        }else{
            //START ADD DEVICE IF INTERNET PLAN SELECTED
          this.store.dispatch(new AddSelectedDevice(device))
            const x = this.selectedPlan.filter(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND).map(plan => {
              return{
                plan_id:plan.id,
                //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
                //IF TV GET BOX DEVICES
                device_id:plan.service_id === environment.HOMEBIZ? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
               
              }
            })
            
            let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
            //UPDATE BILLING SUMMARY PAYLOAD
            this.store.dispatch(new UpdateBillingSummaryPayload(payload))
            //UPDATE BILLING SUMMARY
            this.gelatoService.billingSummary(payload).subscribe(x => {
              if(x.status_code === 200){
                this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
              }else{ 
              }
            })
        }
      }else{
        // ADD DEVICE
        const selected = []
          this.store.dispatch(new AddSelectedDevice(device))
            this.selectedPlan.map(x=>{
              selected.push({
                "plan_id":x.id,
                device_id:this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE || x.device_type_id === environment.DEVICE_MODEM).map(x => x.id)
              })
            })
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
        //UPDATE BILLING SUMMARY PAYLOAD
        this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
        //UPDATE BILLING SUMMARY
        
        
        this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
          if(x.status_code === 200){
            this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
          }else{
          }
        })
        
      }
    }
    
  }

}
