import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { HeaderText } from './../../../../shared/models/header-text.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { CableBoxDevice } from './../../../../shared/models/cable-box-device.model';
import { UpdateServicePlan,UpdateDeviceDetail, UpdateDevices } from './../../../../shared/actions/gelato.actions';
import { ProgressComponent } from './../../../../shared/components/progress/progress.component';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { MatDialog } from '@angular/material/dialog';
import { TvSelectDeviceModalComponent } from '../../../../shared/components/tv-select-device-modal/tv-select-device-modal.component'

@Component({
  selector: 'app-final-steps',
  templateUrl: './final-steps.component.html',
  styleUrls: ['./final-steps.component.css']
})
export class FinalStepsComponent implements OnInit {
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  flag: boolean
  editMode: boolean
  otherContent: boolean = false
  boxChoose: boolean = false
  servicePlan:CableBoxDevice[]
  
  selectedService: number
  constructor(public dialog: MatDialog,private router : Router, private srv: GelatoState,private store: Store,private gelatoService:GelatoService) { }
  public help: any;
  ngOnInit() {
    // Animation
    var internet_cards = document.getElementsByClassName('internet-card')
   
    for(let i = 0; i < internet_cards.length; i++){
        internet_cards[i].addEventListener("click", function(){
        
            // toggle internet-card-active
            this.className.includes("internet-card-active") ? this.className = "internet-card mx-auto col-12 d-flex flex-column p-0" : this.className += " internet-card-active"
            var content = this.children[1];
            if(content.style.maxHeight) {
                content.style.maxHeight = null;
                document.getElementsByClassName("internet-card-content")[i].children[0]['style'].display = "none"
              
            }
            else{
                content.style.maxHeight = "900px";
                document.getElementsByClassName("internet-card-content")[i].children[0]['style'].display = "block"
             
            }
           
        })
    }

    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.gelatoService.getDevices().subscribe(x => {
      this.servicePlan = x.data["cable_box_devices"]
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.store.dispatch(new UpdateDeviceDetail(x.data["service_plans"]))
    })
    
    this.httpReqeuestFlag$.subscribe(x =>{
      this.flag = x
    })

  }

  // Modal
  openPlanDetail(id) {
    
    //UPDATE PLAN_DETAIL
    this.gelatoService.getDeviceDetail(id).subscribe(x => {
      this.store.dispatch(new UpdateDeviceDetail(x))
      const dialogRef = this.dialog.open(TvSelectDeviceModalComponent,{
        height: '800px',
        width: '660px',
      });
      
      
      dialogRef.afterClosed().subscribe(result => {
      });
    })
    
  }





  showContent(choice?: any, e?:any){
    
    switch (choice) {
      case 1:
        this.otherContent = e
        break;
      case 2:
        window.scrollTo({top: 0, behavior: 'smooth'})
        this.boxChoose = e
        break;
      case undefined:
        this.boxChoose = false
      default:
        break;
    }
  }

}
