import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { Select } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { Street } from 'src/app/shared/models/street.model';


@Component({
  selector: 'app-onboarding-container',
  templateUrl: './onboarding-container.component.html',
  styleUrls: ['./onboarding-container.component.css']
})
export class OnboardingContainerComponent implements OnInit {
  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  flag:boolean
  constructor(private router: Router) { 
    this.router.url === '/onboarding' || this.router.url === '/' ? this.router.navigate(['/onboarding/set-location']) : null
  }

  ngOnInit() {
    this.httpReqeuestFlag$.subscribe(x =>{
      this.flag = x
    })
  }

}
