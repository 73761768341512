import { LiteSelectDeviceComponent } from './pages/lite-select-device/lite-select-device.component';
import { CableAddonComponent } from './../../shared/components/cable-addon/cable-addon.component';
import { ChannelDetailsComponent } from './pages/channel-details/channel-details.component';
import { TvCompareDeviceComponent } from './pages/tv-compare-device/tv-compare-device.component';
import { TvSelectDeviceComponent } from './pages/tv-select-device/tv-select-device.component';
import { TvPlanChannelLineupComponent } from './pages/tv-plan-channel-lineup/tv-plan-channel-lineup.component';
import { TvHelpMeChooseComponent } from './pages/tv-help-me-choose/tv-help-me-choose.component';
import { FinalStepsComponent } from './pages/final-steps/final-steps.component';
import { PackInfoComponent } from './pages/pack-info/pack-info.component';
import { ChannelInfoComponent } from './pages/channel-info/channel-info.component';
import { TvPacksComponent } from './pages/tv-packs/tv-packs.component';
import { ChannelLineupComponent } from './../../shared/components/channel-lineup/channel-lineup.component';
import { CreateTvPlanComponent } from './pages/create-tv-plan/create-tv-plan.component';
import { StandardTvPlanComponent } from './pages/standard-tv-plan/standard-tv-plan.component';
import { ChoosePlanComponent } from './pages/choose-plan/choose-plan.component';
import { TvPlanContainerComponent } from './pages/tv-plan-container/tv-plan-container.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FinalizePlanComponent } from 'src/app/shared/components/finalize-plan/finalize-plan.component';
import { AddonComponent } from './pages/addon/addon.component';
import { CableLiteComponent } from './pages/cable-lite/cable-lite.component'
import { InternetAddonComponent } from 'src/app/shared/components/internet-addon/internet-addon.component';


const routes: Routes = [
  { 
    path:"tv-plan", component:TvPlanContainerComponent,
    children:[
      { path:"help-me-choose",component:TvHelpMeChooseComponent },
      { path:"choose-plan",component:ChoosePlanComponent },
      { path:"standard-plan",component:StandardTvPlanComponent },
      { path:"free-plan",component:CableLiteComponent },
      { path:"create-plan",component:CreateTvPlanComponent,
        children:[
          {path: "tv-packs", component: TvPacksComponent},
          {path: "channel-info", component: ChannelInfoComponent}, 
          {path: "channel-details", component: ChannelDetailsComponent}, 
          {path: "pack-info", component: PackInfoComponent}, 
        ]
      },
      { path:"channel-lineup",component:TvPlanChannelLineupComponent },
      { path:"finalize-plan",component:FinalStepsComponent },
      { path:"select-device",component:TvSelectDeviceComponent },
      { path:"lite-select-device",component:LiteSelectDeviceComponent },
      { path:"select-addon",component:AddonComponent },
      { path:"lite-addon",component:InternetAddonComponent },
      { path:"compare-device",component:TvCompareDeviceComponent },
      { path:"plan-summary",component:FinalizePlanComponent },
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TvPlanRoutingModule { }
