import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { AddSelectedDevice, RemoveSelectedDevice, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdateDeviceDetail } from '../../actions/gelato.actions';
import { Device } from '../../models/device.model';
import { Devices } from '../../models/devices.model';
import { ResponseObject } from '../../models/response-object.model';
import { GelatoState } from '../../state/gelato.state';
import { environment } from 'src/environments/environment';
import { Plan } from '../../models/plan.model';
import * as _ from 'lodash';
import { ModemViewDetailsModalComponent } from '../modem-view-details-modal/modem-view-details-modal.component';
@Component({
  selector: 'app-two-one-device-box',
  templateUrl: './two-one-device-box.component.html',
  styleUrls: ['./two-one-device-box.component.css']
})

export class TwoOneDeviceBoxComponent implements OnInit {
  @Input() modemDevices: ResponseObject<Devices>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  selectedDevices:Device[] =  []
  selectedService: number
  selectedPlan:Plan[] = []
  billingSummaryPayload:any
  constructor(public dialog: MatDialog,private store: Store,private gelatoService:GelatoService,private router: Router) { }

  ngOnInit() {

    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    })
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    this.selectedPlans$.subscribe(x => {
      if(x) this.selectedPlan = x
     
    })
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    
  }

  getBox(id){
    // this.panelOpen = true
    this.gelatoService.getDeviceDetail(`${id}`).subscribe(x => {
      this.selectedDevices.filter(x => x.device_type_id != environment.DEVICE_MESH).map(x => {
        this.store.dispatch(new RemoveSelectedDevice(x.id))
      })

      this.store.dispatch(new UpdateDeviceDetail(x))
      this.getThis(x.data['device'])
    })
  }

  getThis(device){
    if(this.selectedDevices){
      if(this.selectedDevices.some(x=>x.device_type_id===device.device_type_id)){
        this.selectedDevices.filter(x=>x.device_type_id === device.device_type_id).map(x=>{
          this.store.dispatch(new RemoveSelectedDevice(x.id))
          
        })
        this.store.dispatch(new AddSelectedDevice(device))
        

        //IF BUNDLE
        if(this.selectedService === environment.BIZ_BROADBAND_CABLE){
          //START UPDATE BUNDLE BILLING SUMMARY PAYLOAD
          const bundle = this.selectedPlan.filter(x => x.service_id === environment.BIZ_BROADBAND_CABLE).map(plan => {
            return{
              plan_id:plan.id,
              device_id:this.selectedDevices.map(x => x.id)
            }
          })
          
          let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",bundle)
          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(payload))
          //UPDATE BILLING SUMMARY
          this.gelatoService.billingSummary(payload).subscribe(x => {
            if(x.status_code === 200){
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }else{ 
            }
          })  
          //END UPDATE BUNDLE BILLING SUMMARY PAYLOAD
        }else{
          //INTERNET AND TV PLANS
          //START UPDATE INTERNET OR TV BILLING SUMMARY PAYLOAD
         
          const x = this.selectedPlan.filter(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND).map(plan => {
            return{
              plan_id:plan.id,
              //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
              //IF TV GET BOX DEVICES
              device_id:plan.service_id === environment.HOMEBIZ? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
             
            }
          })
          
          let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(payload))
          //UPDATE BILLING SUMMARY
          this.gelatoService.billingSummary(payload).subscribe(x => {
            if(x.status_code === 200){
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }else{ 
            }
          })
        
          //END UPDATE INTERNET OR TV BILLING SUMMARY PAYLOAD

        }
      }else{
          //START ADD DEVICE IF INTERNET PLAN SELECTED
        this.store.dispatch(new AddSelectedDevice(device))
          const x = this.selectedPlan.filter(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND).map(plan => {
            return{
              plan_id:plan.id,
              //IF INTERNET GET SELECTED MODEM AND MESH DEVICES
              //IF TV GET BOX DEVICES
              device_id:plan.service_id === environment.HOMEBIZ? this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_MODEM || x.device_type_id === environment.DEVICE_MESH).map(x => x.id) : this.selectedDevices.filter(x => x.device_type_id === environment.DEVICE_CABLE).map(x => x.id) 
             
            }
          })
          
          let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",x)
          //UPDATE BILLING SUMMARY PAYLOAD
          this.store.dispatch(new UpdateBillingSummaryPayload(payload))
          //UPDATE BILLING SUMMARY
          this.gelatoService.billingSummary(payload).subscribe(x => {
            if(x.status_code === 200){
              this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
            }
          })
      }
    }
  }

  openModal(id){
    //UPDATE PLAN_DETAIL
    this.gelatoService.getDeviceDetail(`${id}`).subscribe(x => {
      this.store.dispatch(new UpdateDeviceDetail(x))
      const dialogRef = this.dialog.open(ModemViewDetailsModalComponent,{
        height: '95%',
        width: '660px',
      });

      dialogRef.afterClosed().subscribe(result => {
       
      });
    })
  }

  getStatus(id){
    return(this.selectedDevices.some(x => x.id === id))
  }

}
