import { Address } from 'src/app/shared/models/address.model';
import { Component, OnInit, Input } from '@angular/core';
import { Plan } from 'src/app/shared/models/plan.model';
import { MatDialog } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { AddSelectedDevice, AddSelectedPlan, RemoveSelectedDevice, RemoveSelectedPlan, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdateCableLite, UpdatePlanDetails } from 'src/app/shared/actions/gelato.actions';
import { TvModalPlanDetailComponent  } from 'src/app/shared/components/tv-modal-plan-detail/tv-modal-plan-detail.component';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable, Subscription } from 'rxjs';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Service } from 'src/app/shared/models/service.model';
import { environment } from 'src/environments/environment';
import { Device } from 'src/app/shared/models/device.model';
@Component({
  selector: 'app-lite-plan-box',
  templateUrl: './lite-plan-box.component.html',
  styleUrls: ['./lite-plan-box.component.css']
})
export class LitePlanBoxComponent implements OnInit {
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  selectedPlans:[Plan]
  @Input() plan: Plan;
  panelOpen: boolean = false;
  twoWordPlan: boolean = false;
  firstWord: String = "";
  subscription: Subscription;
  address:Address
  selectedDevices:Device[] =  []
  province_code:string = ""
  errorMessage = ""
  billingSummaryPayload:any
  constructor(public dialog: MatDialog,private store: Store,private gelatoService:GelatoService) { }

  ngOnInit() {
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })

    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
  }
  openPlanDetail(id) {
    this.subscription = this.selectedCategory$.subscribe(x => {
      if(x){ 
        //UPDATE PLAN_DETAIL
        this.gelatoService.getPlanDetailsByID(id,this.province_code,x).subscribe(x => {
          this.store.dispatch(new UpdatePlanDetails(x))
          const dialogRef = this.dialog.open(TvModalPlanDetailComponent,{
            height: '800px',
            width: '660px',
          });
          dialogRef.afterClosed().subscribe(result => {
            this.subscription.unsubscribe()
          });
         })
      }
    })
    
    
  }

  editPlanName(planName){
    var split = planName.split(" ")
    if(split.length > 1){
      return split[1]
    }
    else{
      return planName
    }
  }

  getFlag(plan:Plan){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === plan.id)
    }else{
      return false
    }
    
  }
  
  getThis(plan:Plan) {
    this.panelOpen = true
    this.store.dispatch(new UpdateCableLite(true))
    if(this.selectedPlans){
      if(this.selectedPlans.some(x =>x.service_id === environment.BIZ_BROADBAND)){
        this.selectedPlans.filter(x => x.service_id === environment.BIZ_BROADBAND).map(y => {
          this.store.dispatch(new RemoveSelectedPlan(y.id))
          this.store.dispatch(new AddSelectedPlan(plan))
          this.billingSummary()
          
          })
        }else{
          this.store.dispatch(new AddSelectedPlan(plan))
          this.billingSummary()
        }
    
      }
      else{
        if(plan.service_id === environment.BIZ_BROADBAND){
      this.store.dispatch(new AddSelectedPlan(plan))
      }else{
        this.store.dispatch(new AddSelectedPlan(plan))
        plan.comes_with.map(x => {
          this.store.dispatch(new AddSelectedDevice(x))
        })
      }
      this.billingSummary()
    }
    
  }

  billingSummary(){
      //CONSTRUCT SELECTED PLANS
      const selected = this.selectedPlans.map(x => {
         return {
          "plan_id":x.id,
          device_id:this.selectedDevices.filter(y=>(y.device_type_id === environment.DEVICE_MODEM || y.device_type_id === environment.DEVICE_MESH) && x.service_id === environment.HOMEBIZ).map(x=>x.id)
        }
      })
      this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected}
  
      
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
        
        if(x.status_code === 200){
          this.errorMessage = ""
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }else{
          this.errorMessage = x.message
        }
      })    
  
  }

}
