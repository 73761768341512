import { ChannelInclusions } from './../../../../shared/models/channel-inclusions.model';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Pack } from 'src/app/shared/models/pack.model';
import { UpdatePackDetail, UpdateChannelDetail } from 'src/app/shared/actions/gelato.actions';
import { Channel } from 'src/app/shared/models/channel.model';
import { Address } from 'src/app/shared/models/address.model';
import { Observable } from 'rxjs';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Service } from 'src/app/shared/models/service.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
@Component({
  selector: 'app-channel-inclusions',
  templateUrl: './channel-inclusions.component.html',
  styleUrls: ['./channel-inclusions.component.css']
})
export class ChannelInclusionsComponent implements OnInit {
  @Input() channelInclusions:[ChannelInclusions]
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  address:Address
  province_code:string = ""
  constructor(private router: Router,private store: Store,private gelatoService:GelatoService) { }

  ngOnInit() {
    this.services$.subscribe(x => {
      if(x) this.province_code = x.data["province"]["code"]
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
  }

  learnMore(channel:Channel){
    this.gelatoService.getChannelDetail(`${channel.id}`,`${this.province_code}`).subscribe(x => {
      this.store.dispatch(new UpdateChannelDetail(x))
      this.router.navigate(['/tv-plan/create-plan/channel-info'])
    })
  }
}
