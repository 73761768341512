import { DeviceCollection } from './../models/device-collection.model';
import { BillingSummary } from './../models/billing-summary';
import { Building } from './../models/building.model';
import { MeshDevice } from './../models/mesh-device.model';
import { VoucherCode } from './../models/voucher-code';
import { Plan } from 'src/app/shared/models/plan.model';
import { DeviceComparison } from './../models/device-comparison.model';
import { DeviceDetail } from './../models/device-detail.model';
import { Devices } from './../models/devices.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { ChannelObject } from './../models/channel-object.model';
import { PackDetail } from './../models/pack-detail.model';
import { ChannelPack } from './../models/channel-pack.model';
import { ResponseObject } from './../models/response-object.model';
import { ChannelComparison } from './../models/channel-comparison.model';
import { PlanDetail } from './../models/planDetail.model';
import { ServicePlan } from './../models/servicePlan.model';
import { Service } from './../models/service.model';
import { Address } from './../models/address.model';
import { Street } from './../models/street.model';
import { Barangay } from './../models/barangay.model';
import { UpdateCities, UpdateBarangays, UpdateStreets, UpdateFormAddress, UpdateServices, UpdateHTTPRequestFlag, UpdateServicePlan, UpdateCompareChannels, UpdateComparePlans, UpdatePlanDetails, UpdateGenres, UpdateGenreDetail, UpdateChannelPacks, UpdatePackDetail, UpdateChannels, UpdateChannelDetail, UpdateDevices, UpdateDeviceDetail, UpdateCompareDevices, UpdateHelpMeChoose, ResetHelpMeChoose, UpdateSelectedService, AddSelectedPlan, RemoveSelectedPlan, AddSelectedPack, RemoveSelectedPack, AddSelectedChannel, RemoveSelectedChannel, UpdateVoucherCode, UpdateEditMode, UpdateEditOriginLink, UpdateApplyButtonFlag, AddSelectedDevice, RemoveSelectedDevice, UpdateLockinPeriod, UpdateSelectedAvailableLockinPeriod, AddSelectedMeshDevice, RemoveSelectedMeshDevice, AddSelectedMeshLockin, RemoveSelectedMeshLockin, AddSelectedCableDevice, RemoveSelectedCableDevice, AddSelectedCableLockin, RemoveSelectedCableLockin, AddSelectedCableDeviceCountId, RemoveSelectedCableDeviceId, AddSelectedCableDeviceIndex, RemoveCableIndexId, ResetCableIndexId, UpdateBuildings, UpdateSelectedServiceCategory, ResetAll, ResetCableAddons, AddConfirmationData, UpdateCreateYourOwnFlag, UpdateVisitedPages, UpdateValidIDs, UpdateSecondaryIDs, UpdateSelectedCableTvPlan, UpdateSelectedCableTvDevice, AddOrdinalPostion, AddMonthlyFee, AddInstallFee, UpdateGoogleAddress, UpdateGoogleLATLONG, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdateNumberOfBoxes, UpdateSelectedCableDevice, UpdateSelectedCableDeviceId, UpdateCableLite, UpdateDeviceCollection, UpdateIndustries, UpdateApplicationType, RemoveSelectedBundlePromo, AddSelectedBundlePromo, UpdateAvailableBundles, UpdateSelectedBundlePromos } from './../actions/gelato.actions';
import { AvailableLockinPeriod } from './../models/available-lockin-period.model'
import { Province } from 'src/app/shared/models/province.model';
import { HeaderText } from '../models/header-text.model';
import { UpdateHeaderText,UpdateProvinces } from '../actions/gelato.actions';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { City } from '../models/city.model';
import { Genre } from '../models/genre.model';
import { patch, append, removeItem, insertItem, updateItem } from '@ngxs/store/operators'
import { Pack } from '../models/pack.model';
import { Device } from '../models/device.model';
import { CableBoxDevice } from '../models/cable-box-device.model';
import { ApplicationDetails } from '../models/application-details.model';
import { Industry } from '../models/industry.model';
import { BundleOptions, PromoList } from '../models/promo-list.model';


export class GelatoStateModel {
    http_request_flag:boolean
    header_text?:HeaderText
    provinces?:[Province]
    cities?:[City]
    buildings?:[Building]
    barangays?:[Barangay]
    streets?:[Street]
    address?:Address
    services?:ResponseObject<[Service]>
    service_plan?:ResponseObject<[ServicePlan]>
    plan_detail?:ResponseObject<PlanDetail>
    compare_plans?:ResponseObject<[PlanDetail]>
    compare_channels?:ResponseObject<ChannelComparison>
    genres?:ResponseObject<[Genre]>
    genre_detail?:ResponseObject<Genre>
    channel_packs?:ResponseObject<ChannelPack>
    pack_detail?:ResponseObject<PackDetail>
    channels?:ResponseObject<ChannelObject>
    channel_detail?:ResponseObject<Channel>
    devices?:ResponseObject<Devices>
    device_detail?:ResponseObject<DeviceDetail>
    device_collection?:ResponseObject<DeviceCollection>
    compare_devices?:ResponseObject<DeviceComparison>
    available_locking_period?:ResponseObject<AvailableLockinPeriod>
    help_me_choose?:[any]
    selected_service?:number
    selected_service_category?:string
    selected_plans?:[Plan]
    selected_packs?:[Pack]
    selected_channels?:[Channel]
    voucher_code?:ResponseObject<VoucherCode>
    edit_mode:boolean
    edit_origin_link?: String
    apply_button_flag: boolean
    selected_devices?:[Device]
    selected_lockin?:AvailableLockinPeriod
    selected_mesh?:[Device]
    selected_mesh_lockin?:[AvailableLockinPeriod]
    selected_cable?:[Device]
    selected_cable_lockin?:[AvailableLockinPeriod]
    selected_cable_id?:[number]
    selected_cable_index?:[any]
    applicationDetails?:[ApplicationDetails]
    confirmation_details?:[any]
    create_your_own_plan_flag?:boolean
    visited_pages?:[any]
    valid_ids?:[any]
    secondary_ids?:[any]
    ordinal_position?:number
    monthly_fee?:[number]
    install_fee?:[number]
    google_coordinates?:number[]
    google_address?:string
    billing_summary?:[BillingSummary]
    billing_summary_payload?:any
    number_of_boxes?:number
    is_cable_lite?:boolean
    industries?:[Industry]
    application_type?:string
    selected_bundles?:[BundleOptions]
    available_bundles?:PromoList[]
}


@State<GelatoStateModel>({
    name: 'gelato',
    defaults: {
        http_request_flag:false,
        edit_mode: false,
        header_text:{
            "check_availability_screen": {
                "ca_heading_text_1": "Check the products available in your location!",
                "ca_address_copy": "Type in your address",
                "ca_button_label": "Set Location",
                "ca_heading_text_2": "These services are available in your area! which one do you need?"
            },
            "internet_plan_screen": {
                "internet_plan_heading_text_1": "Internet Plan",
                "internet_plan_heading_subtext_1": "Choose your preferred Internet Speed",
                "internet_plan_heading_subtext_2": "Help me Choose >>",
                "internet_plan_heading_subtext_3": "You may change your modem upon payment",
                "internet_help_me_choose_heading_text": "Help me Choose",
                "internet_help_me_choose_heading_subtext": "Compare two to three plans.",
            	"cable_first_heading_text":"Do you need a SKY Fiber plan?"
            },
            "cabletv_plan_screen": {
                "cable_tv_plan_heading_text_1": "Cable TV Plan",
                "cable_tv_plan_heading_subtext_1": "You can choose from pre-selected plans, or customize your own!",
                "cable_tv_plan_heading_text_2": "Choose a Cable TV Plan",
                "cable_tv_plan_heading_subtext_2": "Select your preferred cable TV plan.",
                "cable_tv_plan_heading_subtext_3": "Help me choose >>",
                "cable_tv_help_me_choose_heading_text": "Help Me Choose",
                "cable_tv_help_me_choose_heading_subtext": "Compare two to three plans.",
                "cable_tv_create_your_own_tv_plan_heading_text": "Cable TV Plan",
                "cable_tv_create_your_own_tv_plan_heading_subtext": "Choose the packs you want to include in your plan!",
                "internet_first_heading_text": "Do you need a SKYcable TV plan?",
                "plan_selections": {
                    "cable_lite": {
                        "icon": "http://18.141.127.146:8080/assets/images/cabletv-selection/get-cable-lite-1606299801.png",
                        "description": "Avail free SKYcable Lite Channels",
                        "sub_text": "(comes free with any SKY Fiber plan)"
                    },
                    "standard": {
                        "icon": "http://18.141.127.146:8080/assets/images/cabletv-selection/get-standard.png",
                        "description": "See all SKYcable Postpaid Plans",
                        "sub_text": "(Best value plans designed for your family)"
                    },
                    "custom": {
                        "icon": "http://18.141.127.146:8080/assets/images/cabletv-selection/create-your-own.png",
                        "description": "Create your own SKYcable Plan",
                        "sub_text": ""
                    }
                }
            },
            "create_your_own_plan_screen":{
                "free_channels_text":"Comes with free channels: Cinemo, One Sports, MJCI, Teleradyo, ANC, ANC HD, Jeepney, ETC, RJTV, Beam TV, Rock TV Manila, INC, Light Network, EWTN, TV Maria, UNTV, SMNI, 3 ABN 45, Oras ng Himala, Knowledge Channel, MYX, Aquarium Channel HD, CNN Phils., GMA, GMA News TV, IBC 13, TV 5, NET 25, A2Z",
                "msf_text":"50% of monthly fee of chosen channels"
            },
            "bundle_plan_screen": {
                "bundle_heading_text_1": "Internet + Cable TV Plan",
                "bundle_heading_subtext_1": "Choose your preferred internet speed.",
                "bundle_heading_subtext_2": "Help me choose >>",
                "bundle_help_me_choose_heading_text": "Help Me Choose",
                "bundle_help_me_choose_heading_subtext_1": "Compare two to three plans.",
                "bundle_help_me_choose_heading_subtext_2": "*Deco M5 GE has 1000Mbps max bandwidth while Deco has FE port is 100Mbps max bandwidth. *Deco M5 contained twice the antenna, flash and DDR than Deco E4"
            },
            "internet_application_screen": {
                "internet_application_heading_text": "Thank you for submitting your application!",
                "internet_application_subtext": "Please wait for our confirmation via email or SMS within the day."
            },
            "dth_ftth_application_screen": {
                "application_heading_text": "Thank you for submitting your application!",
                "application_subtext": "Please wait for our customer support agent to contact you regarding your request."
            }
        },
        apply_button_flag:false,
        create_your_own_plan_flag:false,
        billing_summary_payload:{
            "selected_plans":[],
            "voucher_code":"",
            "addons":{
                "devices":[],
                "channel_pack_id":[],
                "channel_id":[]
            }
        },
        application_type: ""
    }
})

export class GelatoState {
    

    @Selector()
    static getHTTPRequestFlag(state: GelatoStateModel) {
        return state.http_request_flag
    }
    @Selector()
    static getHeaderText(state: GelatoStateModel) {
        return state.header_text
    }

    @Selector()
    static getProvinces(state: GelatoStateModel) {
        return state.provinces
    }
    @Selector()
    static getCities(state: GelatoStateModel) {
        return state.cities
    }
    @Selector()
    static getBuildings(state: GelatoStateModel) {
        return state.buildings
    }
    @Selector()
    static getBarangays(state: GelatoStateModel) {
        return state.barangays
    }
    @Selector()
    static getStreets(state: GelatoStateModel) {
        return state.streets
    }

    @Selector()
    static getAddress(state: GelatoStateModel) {
        return state.address
    }

    @Selector()
    static getServices(state: GelatoStateModel) {
        return state.services
    }
    
    @Selector()
    static getServicePlan(state: GelatoStateModel) {
        return state.service_plan
    }

    @Selector()
    static getPlanDetail(state: GelatoStateModel) {
        return state.plan_detail
    }
    @Selector()
    static getComparePlans(state: GelatoStateModel) {
        return state.compare_plans
    }
    @Selector()
    static getCompareChannels(state: GelatoStateModel) {
        return state.compare_channels
    }
    @Selector()
    static getGenres(state: GelatoStateModel) {
        return state.genres
    }

    @Selector()
    static getGenreDetail(state: GelatoStateModel) {
        return state.genre_detail
    }

    @Selector()
    static getChannelPacks(state: GelatoStateModel) {
        return state.channel_packs
    }

    @Selector()
    static getPackDetail(state: GelatoStateModel) {
        return state.pack_detail
    }

    @Selector()
    static getChannels(state: GelatoStateModel) {
        return state.channels
    }

    @Selector()
    static getChannelDetail(state: GelatoStateModel) {
        return state.channel_detail
    }

    @Selector()
    static getDevices(state: GelatoStateModel) {
        return state.devices
    }

    @Selector()
    static getDeviceDetail(state: GelatoStateModel) {
        return state.device_detail
    }

    @Selector()
    static getDeviceCollection(state: GelatoStateModel) {
        return state.device_collection
    }


    @Selector()
    static getCompareDevices(state: GelatoStateModel) {
        return state.compare_devices
    }

    @Selector()
    static getHelpMeChoose(state: GelatoStateModel) {
        return state.help_me_choose
    }

    @Selector()
    static getSelectedService(state: GelatoStateModel) {
        return state.selected_service
    }

    @Selector()
    static getSelectedServiceCategory(state: GelatoStateModel) {
        return state.selected_service_category
    }

    @Selector()
    static getSelectedPlans(state: GelatoStateModel) {
        return state.selected_plans
    }

    @Selector()
    static getSelectedPacks(state: GelatoStateModel) {
        return state.selected_packs
    }

    @Selector()
    static getSelectedChannels(state: GelatoStateModel) {
        return state.selected_channels
    }

    @Selector()
    static getVoucherCode(state: GelatoStateModel) {
        return state.voucher_code
    }

    @Selector()
    static getEditMode(state: GelatoStateModel) {
        return state.edit_mode
    }

    @Selector()
    static getEditOriginLink(state: GelatoStateModel) {
        return state.edit_origin_link
    }

    @Selector()
    static getApplyButtonFlag(state: GelatoStateModel) {
        return state.apply_button_flag
    }

    @Selector()
    static getSelectedDevices(state: GelatoStateModel) {
        return state.selected_devices
    }

    @Selector()
    static getSelectedLockin(state: GelatoStateModel) {
        return state.selected_lockin
    }

    @Selector()
    static getSelectedMesh(state: GelatoStateModel) {
        return state.selected_mesh
    }

    @Selector()
    static getSelectedMeshLockin(state: GelatoStateModel) {
        return state.selected_mesh_lockin
    }

    @Selector()
    static getSelectedCable(state: GelatoStateModel) {
        return state.selected_cable
    }

    @Selector()
    static getSelectedCableLockin(state: GelatoStateModel) {
        return state.selected_cable_lockin
    }

    @Selector()
    static getApplicationDetails(state: GelatoStateModel) {
        return state.applicationDetails
    }

    @Selector()
    static getSelectedCableDeviceId(state: GelatoStateModel) {
        return state.selected_cable_id
    }

    @Selector()
    static getSelectedDeviceIndex(state: GelatoStateModel) {
        return state.selected_cable_index
    }
    @Selector()
    static getConfirmationDetails(state: GelatoStateModel) {
        return state.confirmation_details
    }

    @Selector()
    static updateCreateYourOwnFlag(state: GelatoStateModel) {
        return state.create_your_own_plan_flag
    }

    @Selector()
    static getVisitedPages(state: GelatoStateModel) {
        return state.visited_pages
    }

    @Selector()
    static getValidIDs(state: GelatoStateModel) {
        return state.valid_ids
    }

    @Selector()
    static getSecondaryIDs(state: GelatoStateModel) {
        return state.secondary_ids
    }
    @Selector()
    static getOrdinalPosition(state: GelatoStateModel) {
        return state.ordinal_position
    }
    @Selector()
    static getMonthlyPrice(state: GelatoStateModel) {
        return state.monthly_fee
    }
    @Selector()
    static getInstallFee(state: GelatoStateModel) {
        return state.install_fee
    }
    
    @Selector()
    static getGoogleCoordinates(state: GelatoStateModel) {
        return state.google_coordinates
    }

    @Selector()
    static getGoogleAddress(state: GelatoStateModel) {
        return state.google_address
    }

    @Selector()
    static getBillingSummary(state: GelatoStateModel) {
        return state.billing_summary
    }

    @Selector()
    static getBillingSummaryPayload(state: GelatoStateModel) {
        return state.billing_summary_payload
    }

    @Selector()
    static getNumberOfBoxes(state: GelatoStateModel) {
        return state.number_of_boxes
    }

    @Selector()
    static getCableLite(state: GelatoStateModel) {
        return state.is_cable_lite
    }

    @Selector()
    static getIndustries(state: GelatoStateModel) {
        return state.industries
    }

    @Selector()
    static getApplicationType(state: GelatoStateModel) {
        return state.application_type
    }

    @Selector()
    static getSelectedBundlePromos(state: GelatoStateModel) {
        return state.selected_bundles
    }
    
    @Action(UpdateHTTPRequestFlag)
    updateHTTPRequestFlag({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateHTTPRequestFlag) {
        const state = getState();
        patchState({
            http_request_flag: payload
        })
    }

    @Action(UpdateHeaderText)
    updateHeaderText({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateHeaderText) {
        const state = getState();
        patchState({
            header_text: payload
        })
    }
    @Action(UpdateProvinces)
    updateProvinces({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateProvinces) {
        const state = getState();
        patchState({
            provinces: payload
        })
    }

    @Action(UpdateCities)
    updateCities({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateCities) {
        const state = getState();
        patchState({
            cities: payload
        })
    }

    @Action(UpdateBuildings)
    UpdateBuildings({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateBuildings) {
        const state = getState();
        patchState({
            buildings: payload
        })
    }

    @Action(UpdateBarangays)
    updateBarangays({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateBarangays) {
        const state = getState();
        patchState({
            barangays: payload
        })
    }

    @Action(UpdateStreets)
    updateStreets({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateStreets) {
        const state = getState();
        patchState({
            streets: payload
        })
    }

    @Action(UpdateFormAddress)
    updateAddress({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateFormAddress) {
        const state = getState();
        patchState({
            address: payload
        })
    }
    @Action(UpdateServices)
    updateServices({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateServices) {
        const state = getState();
        patchState({
            services: payload
        })
    }

    @Action(UpdateServicePlan)
    updateServicePlan({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateServicePlan) {
        const state = getState();
        patchState({
            service_plan: payload
        })
    }
    @Action(UpdatePlanDetails)
    updatePlanDetails({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdatePlanDetails) {
        const state = getState();
        patchState({
            plan_detail: payload
        })
    }
    @Action(UpdateComparePlans)
    updateComparePlans({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateComparePlans) {
        const state = getState();
        patchState({
            compare_plans: payload
        })
    }
    @Action(UpdateCompareChannels)
    updateCompareChannels({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateCompareChannels) {
        const state = getState();
        patchState({
            compare_channels: payload
        })
    }
  
    @Action(UpdateGenres)
    updateGenres({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateGenres) {
        const state = getState();
        patchState({
            genres: payload
        })
    }

    @Action(UpdateGenreDetail)
    updateGenreDetail({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateGenreDetail) {
        const state = getState();
        patchState({
            genre_detail: payload
        })
    }

    @Action(UpdateChannelPacks)
    updateChannelPacks({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateChannelPacks) {
        const state = getState();
        patchState({
            channel_packs: payload
        })
    }

    @Action(UpdatePackDetail)
    updatePackDetail({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdatePackDetail) {
        const state = getState();
        patchState({
            pack_detail: payload
        })
    }

    @Action(UpdateChannels)
    updateChannels({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateChannels) {
        const state = getState();
        patchState({
            channels: payload
        })
    }

    @Action(UpdateChannelDetail)
    updateChannelDetail({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateChannelDetail) {
        const state = getState();
        patchState({
            channel_detail: payload
        })
    }

    @Action(UpdateDevices)
    updateDevices({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateDevices) {
        const state = getState();
        patchState({
            devices: payload
        })
    }

   

    @Action(UpdateDeviceDetail)
    updateDeviceDetail({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateDeviceDetail) {
        const state = getState();
        patchState({
            device_detail: payload
        })
    }
    
    @Action(UpdateDeviceCollection)
    updateDeviceCollection({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateDeviceCollection) {
        const state = getState();
        patchState({
            device_collection: payload
        })
    }

    @Action(UpdateCompareDevices)
    updateComparisonDevice({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateCompareDevices) {
        const state = getState();
        patchState({
            compare_devices: payload
        })
    }

    @Action(UpdateHelpMeChoose)
    updateHelpMeChoose({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateHelpMeChoose) {
        const state = getState();
        setState(
            patch({
                help_me_choose:append([payload])
            })
        )
    }

    @Action(ResetHelpMeChoose)
    resetHelpMeChoose({getState, patchState }: StateContext<GelatoStateModel>) {
        const state = getState();
        patchState({
            help_me_choose: null
        })
    }
    @Action(ResetCableIndexId)
    resetCableIndexId({getState, patchState }: StateContext<GelatoStateModel>) {
        const state = getState();
        patchState({
            selected_cable_index: null
        })
    }
    @Action(UpdateSelectedService)
    updateSelectedService({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateSelectedService) {
        const state = getState();
        patchState({
            selected_service: payload
        })
    }
    @Action(UpdateSelectedServiceCategory)
    updateSelectedServiceCategory({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateSelectedServiceCategory) {
        const state = getState();
        patchState({
            selected_service_category: payload
        })
    }

    @Action(AddSelectedPlan)
    addSelectedPlan({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedPlan) {
        const state = getState();
        setState(
            patch({
                selected_plans:append([payload])
            })
        )
    }
    @Action(UpdateSelectedCableTvPlan)
    updateSelectedCableTvPlan({getState,setState, patchState}: StateContext<GelatoStateModel>, { payload }:UpdateSelectedCableTvPlan) {
        const stat = getState();
        setState(
            patch(
            
            {
                selected_plans:updateItem<Plan>(x=>x.service_id==payload.service_id,patch(payload))
            }
        )
        )
    }

    @Action(RemoveSelectedPlan)
    removeSelectedPlan({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:RemoveSelectedPlan) {
        const state = getState();
        setState(
            patch({
                selected_plans:removeItem<Plan>(x => x.id === payload)
            })
        )
    }

    @Action(AddSelectedPack)
    addSelectedPack({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedPack) {
        const state = getState();
        setState(
            patch({
                selected_packs:append([payload])
            })
        )
    }

    @Action(RemoveSelectedPack)
    removeSelectedPack({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:RemoveSelectedPack) {
        const state = getState();
        setState(
            patch({
                selected_packs:removeItem<Pack>(x => x.id === payload)
            })
        )
    }

    @Action(AddSelectedChannel)
    addSelectedChannel({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedChannel) {
        const state = getState();
        setState(
            patch({
                selected_channels:append([payload])
            })
        )
    }

    @Action(RemoveSelectedChannel)
    removeSelectedChannel({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:RemoveSelectedChannel) {
        const state = getState();
        setState(
            patch({
                selected_channels:removeItem<Channel>(x => x.id === payload)
            })
        )
    }

    @Action(UpdateVoucherCode)
    updateVoucherCode({getState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateVoucherCode) {
        const state = getState();
        patchState({
            voucher_code: payload
        })
    }

    @Action(UpdateEditMode)
    updateEditMode({getState, patchState}: StateContext<GelatoStateModel>, { payload}:UpdateEditMode) {
        const stat = getState();
        patchState({
            edit_mode: payload
        })
    }

    @Action(UpdateEditOriginLink)
    updateEditOriginLink({getState, patchState}: StateContext<GelatoStateModel>, { payload }:UpdateEditOriginLink) {
        const stat = getState();
        patchState({
            edit_origin_link: payload
        })
    }

    @Action(UpdateApplyButtonFlag)
    updateApplyButtonFlag({getState, patchState}: StateContext<GelatoStateModel>, { payload }:UpdateApplyButtonFlag) {
        patchState({
            apply_button_flag: payload
        })
    }

    @Action(AddSelectedDevice)
    addSelectedDevice({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedDevice) {
        const state = getState();
        setState(
            patch({
                selected_devices:append([payload])
            })
        )
    }
    @Action(UpdateSelectedCableTvDevice)
    UpdateSelectedCableTvDevice({getState, setState, patchState}: StateContext<GelatoStateModel>, { payload }:UpdateSelectedCableTvDevice) {
        const stat = getState();
        setState(
            patch(
            
            {
                selected_devices:updateItem<Device>(x=>x.device_type_id===payload.device_type_id,patch(payload))
            }
        )
        )
    }
   

    @Action(RemoveSelectedDevice)
    removeSelectedDevice({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:RemoveSelectedDevice) {
        const state = getState();
        setState(
            patch({
                selected_devices:removeItem<Device>(x => x.id === payload)
            })
        )
    }

    @Action(UpdateSelectedAvailableLockinPeriod)
    updateAvailableLockinPeriod({getState, patchState}: StateContext<GelatoStateModel>, { payload }:UpdateSelectedAvailableLockinPeriod) {
        const stat = getState();
        patchState({
            selected_lockin: payload
        })
    }

    @Action(AddSelectedMeshDevice)
    addSelectedMeshDevice({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedMeshDevice) {
        const state = getState();
        setState(
            patch({
                selected_mesh:append([payload])
            })
        )
    }

    @Action(RemoveSelectedMeshDevice)
    removeSelectedMeshDevice({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:RemoveSelectedMeshDevice) {
        const state = getState();
        setState(
            patch({
                selected_mesh:removeItem<MeshDevice>(x => x.id === payload)
            })
        )
    }

    @Action(AddSelectedMeshLockin)
    addSelectedMeshLockin({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedMeshLockin) {
        const state = getState();
        setState(
            patch({
                selected_mesh_lockin:append([payload])
            })
        )
    }

    @Action(RemoveSelectedMeshLockin)
    removeSelectedMeshLockin({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:RemoveSelectedMeshLockin) {
        const state = getState();
        setState(
            patch({
                selected_mesh_lockin:removeItem<AvailableLockinPeriod>(x => x.name === payload)
            })
        )
    }

    @Action(AddSelectedCableDevice)
    addSelectedCableDevice({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedCableDevice) {
        const state = getState();
        setState(
            patch({
                selected_cable:append([payload])
            })
        )
    }

    @Action(AddOrdinalPostion)
    addOrdinalPostion({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddOrdinalPostion) {
        const state = getState();
        setState(
            patch({
                ordinal_position:payload
            })
        )
    }

    @Action(AddSelectedCableDeviceCountId)
    addSelectedCableDeviceCountId({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedCableDeviceCountId) {
        const state = getState();
        setState(
            patch({
                selected_cable_id:append([payload])
            })
        )
    }

    @Action(AddSelectedCableDeviceIndex)
    addSelectedCableDeviceIndex({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedCableDeviceIndex) {
        const state = getState();
        setState(
            patch({
                selected_cable_index:append([payload])
            })
        )
    }

    @Action(RemoveSelectedCableDeviceId)
    removeSelectedCableDeviceId({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:RemoveSelectedCableDeviceId) {
        const state = getState();
        setState(
            patch({
                selected_cable_id:removeItem(x => x === payload)
            })
        )
    }

    @Action(RemoveSelectedCableDevice)
    removeSelectedCableDevice({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:RemoveSelectedCableDevice) {
        const state = getState();
        setState(
            patch({
                selected_cable:removeItem<CableBoxDevice>(x => x.id === payload)
            })
        )
    }

    @Action(UpdateSelectedCableDevice)
    updateSelectedCableDevice({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateSelectedCableDevice) {
        const state = getState();
        setState(
            patch({
                selected_cable:payload
            })
        )
    }
    @Action(UpdateSelectedCableDeviceId)
    updateSelectedCableDeviceId({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateSelectedCableDeviceId) {
        const state = getState();
        setState(
            patch({
                selected_cable_id:payload
            })
        )
    }
    @Action(AddSelectedCableLockin)
    addSelectedCableLockin({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedCableLockin) {
        const state = getState();
        setState(
            patch({
                selected_cable_lockin:append([payload])
            })
        )
    }

    @Action(RemoveSelectedCableLockin)
    removeSelectedCableLockin({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:RemoveSelectedCableLockin) {
        const state = getState();
        setState(
            patch({
                selected_cable_lockin:removeItem<AvailableLockinPeriod>(x => x.name === payload)
            })
        )
    }

    @Action(RemoveCableIndexId)
    removeCableIndexId({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:RemoveCableIndexId) {
        const state = getState();
        setState(
            patch({
                selected_cable_id:removeItem(payload)
                
            })
        )
    }

    @Action(ResetAll)
    resetAll({getState,setState, patchState }: StateContext<GelatoStateModel>, {  }:ResetAll) {
        const state = getState();
        setState(
            patch({
                selected_plans:null,
                selected_cable_id:null,
                selected_packs:null,
                selected_mesh:null,
                selected_mesh_lockin:null,
                selected_cable:null,
                selected_cable_index:null,
                selected_cable_lockin:null,
                selected_channels:null,
                selected_devices:null,
                selected_lockin:null,
                create_your_own_plan_flag:null,
                number_of_boxes:null,
                selected_bundles:null
            })
        )
    }
    @Action(ResetCableAddons)
    resetCableAddons({getState,setState, patchState }: StateContext<GelatoStateModel>, {  }:ResetCableAddons) {
        const state = getState();
        setState(
            patch({
                selected_cable_id:null,
                selected_packs:null,
                selected_channels:null,
                selected_cable:null,
                selected_cable_index:null,
                selected_cable_lockin:null,
                create_your_own_plan_flag:null,
                number_of_boxes:null
            })
        )
    }
    @Action(AddConfirmationData)
    addConfirmationData({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddConfirmationData) {
        const state = getState();
        setState(
            patch({
                confirmation_details:append([payload])
            })
        )
    }

    @Action(UpdateCreateYourOwnFlag)
    updateCreateYourOwnFlag({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateCreateYourOwnFlag) {
        const state = getState();
        setState(
            patch({
                create_your_own_plan_flag:payload
            })
        )
    }

    @Action(UpdateVisitedPages)
    updateVisitedPages({getState,setState,patchState} : StateContext<GelatoStateModel>, { payload }: UpdateVisitedPages) {
        const state = getState();
        setState(
            patch({
                visited_pages:payload
            })
        )
    }

    @Action(UpdateValidIDs)
    updateValidIDs({getState,setState,patchState} : StateContext<GelatoStateModel>, { payload }: UpdateValidIDs) {
        const state = getState();
        setState(
            patch({
                valid_ids:payload
            })
        )
    }

    @Action(UpdateSecondaryIDs)
    updateSecondaryIDs({getState,setState,patchState} : StateContext<GelatoStateModel>, { payload }: UpdateSecondaryIDs) {
        const state = getState();
        setState(
            patch({
                secondary_ids:payload
            })
        )
    }

    @Action(AddMonthlyFee)
    addMonthlyFee({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddMonthlyFee) {
        const state = getState();
        setState(
            patch({
                monthly_fee:append([payload])
            })
        )
    }
    @Action(AddInstallFee)
    addInstallFee({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddInstallFee) {
        const state = getState();
        setState(
            patch({
                install_fee:append([payload])
            })
        )
    }

    @Action(UpdateGoogleLATLONG)
    updateGoogleLATLONG({getState,setState,patchState} : StateContext<GelatoStateModel>, { glat,glong }: UpdateGoogleLATLONG) {
        const state = getState();
        setState(
            patch({
                google_coordinates:[glat,glong]
            })
        )
    }
    @Action(UpdateGoogleAddress)
    updateGoogleAddress({getState,setState,patchState} : StateContext<GelatoStateModel>, { payload }: UpdateGoogleAddress) {
        const state = getState();
        setState(
            patch({
                google_address:payload
            })
        )
    }
    @Action(UpdateBillingSummary)
    updateBillingSummary({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateBillingSummary) {
        const state = getState();
        patchState({
            billing_summary:([payload])
       })
    }

    @Action(UpdateBillingSummaryPayload)
    updateBillingSummaryPayload({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateBillingSummaryPayload) {
        const state = getState();
        patchState({
            billing_summary_payload:(payload)
       })
    }

    @Action(UpdateNumberOfBoxes)
    updateNumberOfBoxes({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateNumberOfBoxes) {
        const state = getState();
        patchState({
            number_of_boxes:payload
       })
    }

    @Action(UpdateCableLite)
    updateCableLite({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateCableLite) {
        const state = getState();
        patchState({
            is_cable_lite:payload
       })
    }

    @Action(UpdateIndustries)
    updateIndustries({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateIndustries) {
        const state = getState();
        patchState({
            industries:payload
       })
    }

    @Action(UpdateApplicationType)
    updateApplicationType({ patchState }: StateContext<GelatoStateModel>, { payload }:UpdateApplicationType) {
        patchState({
            application_type:payload
       })
    }

    @Action(AddSelectedBundlePromo)
    addSelectedBundlePromo({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:AddSelectedBundlePromo) {
        const state = getState();
        setState(
            patch({
                selected_bundles:append([payload])
            })
        )
    }

    @Action(RemoveSelectedBundlePromo)
    removeSelectedBundlePromo({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload, payload2 }:RemoveSelectedBundlePromo) {
        const state = getState();
        const index = getState().selected_bundles.findIndex(x => x.promoIndex == payload2)
        setState(
            patch({
                selected_bundles:removeItem<BundleOptions>(x => x.plan_id == payload && x.promoIndex == payload2)
            })
        )
    }

    @Selector()
    static getAvailableBundlePromos(state: GelatoStateModel) {
        return state.available_bundles
    }

    @Action(UpdateAvailableBundles)
    updateAvailableBundles({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateAvailableBundles) {
        patchState({
            available_bundles:payload
       })
    }

    @Action(UpdateSelectedBundlePromos)
    updateSelectedBundlePromos({getState,setState, patchState }: StateContext<GelatoStateModel>, { payload }:UpdateSelectedBundlePromos) {
        patchState({
            selected_bundles:payload
       })
    }

}

