import { SharedModule } from './shared/shared.module';
import { BundlePlanModule } from './modules/bundle-plan/bundle-plan.module';
import { InternetPlanModule } from './modules/internet-plan/internet-plan.module';
import { TvPlanModule } from './modules/tv-plan/tv-plan.module';

import { OnboardingModule } from './modules/onboarding/onboarding.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxsModule } from '@ngxs/store';
import { GelatoState } from './shared/state/gelato.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxsModule.forRoot([
      GelatoState
    ],{ developmentMode: false }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    OnboardingModule,
    TvPlanModule,
    InternetPlanModule,
    BundlePlanModule,
    CoreModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
