import { Address } from 'src/app/shared/models/address.model';
import { channel_lineup } from './../../models/channel_lineup.model';
import { Component, OnInit } from '@angular/core';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
import { UpdateCompareChannels, ResetHelpMeChoose, UpdateComparePlans, AddSelectedPlan, UpdateHTTPRequestFlag, AddSelectedDevice, UpdateHelpMeChoose, AddSelectedCableDevice } from '../../../shared/actions/gelato.actions';
import { Plan } from 'src/app/shared/models/plan.model';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Router } from '@angular/router';
import { ChannelComparison } from 'src/app/shared/models/channel-comparison.model';
import { Service } from '../../models/service.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bundle-lineup',
  templateUrl: './bundle-lineup.component.html',
  styleUrls: ['./bundle-lineup.component.css']
})
export class BundleLineupComponent implements OnInit {
  @Select(GelatoState.getPlanDetail) planDetails$: Observable<ResponseObject<PlanDetail>>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getHelpMeChoose) selected$: Observable<any>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  selectedPlans:[Plan]
  planDetail:PlanDetail
  showCompare: boolean = false;
  plansFlag:boolean = false
  compareFlag:boolean = false
  servicePlan:ServicePlan[]
  selected:PlanDetail[]
  blanckBox:[]
  planId: number[];
  count: number;
  selectedPlan:[]
  channelLineup:[]
  address:Address
  province_code:string = ""
  constructor(private srv: GelatoState, private store: Store,private gelatoService:GelatoService,private router: Router) { }

  ngOnInit() {
    this.services$.subscribe(x => {
      if(x) this.province_code = x.data["province"]["code"]
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.planDetails$.subscribe(x=> {
      this.planDetail = x.data["plan_details"]
      
    })
    this.address$.subscribe(x => {
      if(x) this.address = x
    })
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.selectedCategory$.subscribe(x => {
      if(x){
        this.gelatoService.getServicesByPlanID(environment.BIZ_BROADBAND.toString(),this.province_code,x).subscribe(x => {
          this.servicePlan = x.data["service_plans"]
        })
      }
    })

    this.store.select(state => state.gelato.help_me_choose).subscribe(x => {
      this.selected = x
      this.planId = x.map(x => x.id)
      
    });

    this.gelatoService.compareChannelIDs(this.planId,this.province_code).subscribe(x => {
      this.selectedPlan = x.data["selected_plans"]
      this.channelLineup = x.data["channel_lineup"]
      
    this.store.dispatch(new UpdateHTTPRequestFlag(false))

    })

    

    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    
  }

  getThisFlag(plan:PlanDetail){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.service_id === plan.service_id)
    }else{
      return false
    }
  }

  getThis(plandetail:Plan) {
    plandetail.comes_with.map(x => {
      this.store.dispatch(new AddSelectedCableDevice(x))
    })
    this.store.dispatch(new AddSelectedPlan(plandetail))
    
  }

  goBack(){
    window.history.back();
    this.compareFlag =  false
    this.plansFlag = false
    this.store.dispatch(new ResetHelpMeChoose())
  }

}
