import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bundle-mesh-device-details',
  templateUrl: './bundle-mesh-device-details.component.html',
  styleUrls: ['./bundle-mesh-device-details.component.css']
})
export class BundleMeshDeviceDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
