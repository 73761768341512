import { environment } from 'src/environments/environment';
import { UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { UpdateDevices } from './../../../../shared/actions/gelato.actions';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs/internal/Observable';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Devices } from 'src/app/shared/models/devices.model';
import { Plan } from 'src/app/shared/models/plan.model';
import { Device } from 'src/app/shared/models/device.model';

@Component({
  selector: 'app-internet-select-device',
  templateUrl: './internet-select-device.component.html',
  styleUrls: ['./internet-select-device.component.css']
})
export class InternetSelectDeviceComponent implements OnInit {
  @Select(GelatoState.getDevices) devices$: Observable<ResponseObject<Devices>>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  meshFlag:boolean = false
  hasTVPlanSelectedFlag:boolean = false
  requestFlag:boolean = true
  selectedService:number
  selectedDevices:Device[] = []
  selectedPlans:Plan[] = []
  constructor(private router: Router,private store: Store,private gelatoService:GelatoService) {
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
   }

  ngOnInit() {
    this.selectedService$.subscribe(x => {
      if(x )this.selectedService = x
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    })
    this.selectedPlans$.subscribe(x => {
      if(x){
        this.selectedPlans = x
        if(x.length > 0) this.hasTVPlanSelectedFlag = x.some(x => x.service_id === environment.BIZ_BROADBAND)
      }
      
    })
    this.gelatoService.getDevices().subscribe(x => {
      this.store.dispatch(new UpdateDevices(x))
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.requestFlag = false
    })
    
  }

  toggleMesh(){
    this.meshFlag = !this.meshFlag
  }

  nextClick(){
    let meshDeviceFlag = this.selectedDevices.some(x => x.device_type_id === environment.DEVICE_MESH)
    //IF PLAN COMES WITH A MESH DEVICE
    if(meshDeviceFlag){
      this.router.navigate(['/internet-plan/plan-summary'])
    }else{
      this.router.navigate(['/internet-plan/select-addon'])
    }
  }
  nextFlag(){
    if(this.selectedDevices){
      return this.selectedDevices.some(x => x.device_type_id === environment.DEVICE_MODEM) && this.selectedPlans.some(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND_CABLE)
    }
    else{
      return false
    }
  }
  
  back(){
    let planLength = this.selectedPlans.length
    // if bundle select packs
    if(planLength == 1 && this.selectedPlans.some(x => x.service_id == environment.BIZ_BROADBAND_CABLE)){
      return '/tv-plan/create-plan/tv-packs'
    }
    // if internet only tv select plan
    else if(planLength == 1 && this.selectedPlans.some(x => x.service_id == environment.HOMEBIZ)){
      return '/tv-plan/choose-plan'
    }
    // if internet + cable select packs
    else if(planLength == 2 && this.selectedService == environment.HOMEBIZ){
      return '/tv-plan/create-plan/tv-packs'
    }
    // if cable + internet tv select addon
    else if(planLength == 2 && this.selectedService == environment.BIZ_BROADBAND){
      return '/tv-plan/select-addon'
    }
  }
}
