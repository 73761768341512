import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GelatoState } from '../../state/gelato.state';

@Component({
  selector: 'app-id-modal',
  templateUrl: './id-modal.component.html',
  styleUrls: ['./id-modal.component.css']
})

export class IdModalComponent implements OnInit {
  @Select(GelatoState.getValidIDs) validIDs$: Observable<any>;
  @Select(GelatoState.getSecondaryIDs) secondaryIDs$: Observable<any>;
  more: number = 1;
  more2: number = 1;
  isButtonVisible = true;
  isButtonVisible2 = true;
  buttonhide: boolean = true
  buttonName = "See more";

  guidelines = ["Please upload a copy of your valid identification in PNG, JPEG or PDF format, no larger than 3mb in size.",
    "The image must be high quality, unobstructed and uncropped.",
    "The image must show a full document page or in case of national ID photocards, both sides of the card."
  ]

  constructor(public dialogRef: MatDialogRef<IdModalComponent>) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  SeeMore() {
    this.more = this.more + 20;

    if (this.more > 4) {
      this.isButtonVisible = false;
      this.buttonName = "See Less";
    }
  }
  SeeLess() {
    this.more = 0;
    this.isButtonVisible = true;
  }

  SeeMore2() {
    this.more2 = this.more + 20;

    if (this.more2 > 4) {
      this.isButtonVisible2 = false;
    }
  }
  SeeLess2() {
    this.more2 = 0;
    this.isButtonVisible2 = true;
  }

}
