import { UpdateApplicationType, UpdateHeaderText } from './../../../../shared/actions/gelato.actions';
import { ResponseObject } from './../../../../shared/models/response-object.model';
import { Address } from 'src/app/shared/models/address.model';
import { Component, OnInit, Input } from '@angular/core';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Service } from 'src/app/shared/models/service.model';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateServices, UpdateHTTPRequestFlag, UpdateVisitedPages } from 'src/app/shared/actions/gelato.actions';
import { HeaderText } from './../../../../shared/models/header-text.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-available-location',
  templateUrl: './available-location.component.html',
  styleUrls: ['./available-location.component.css']
})
export class AvailableLocationComponent implements OnInit {

  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  @Select(GelatoState.getVisitedPages) visitedPages$: Observable<[any]>;
  @Select(GelatoState.getGoogleCoordinates) googleCoordinates$: Observable<number[]>;
  address: string
  message: String = ""
  noService: boolean = false
  services: Service[]
  googleAddress: string
  constructor(private store: Store, private gelatoService: GelatoService, private router: Router) {
    const applicationType = localStorage.getItem("application-type");
    if (applicationType) {
      this.gelatoService.applicationType = applicationType;
      this.store.dispatch(new UpdateApplicationType(applicationType))
    }
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.gelatoService.getHeaderText().subscribe(x => {
      if (x) this.store.dispatch(new UpdateHeaderText(x["data"]))
    })
    this.visitedPages$.subscribe(x => {
      if (x == undefined) {
        this.store.dispatch(new UpdateVisitedPages(['available']))
      }
      else if (!x.includes('available')) {
        this.store.dispatch(new UpdateVisitedPages(['available']))
      }
    })
    const addressObj = localStorage.getItem("address")
    if (addressObj) {
      const x = JSON.parse(addressObj) as Address
      this.address = `${x.unit_no} ${x.building.building_name ? x.building.building_name : ''} ${x.subd} ${x.street.name}, ${x.barangay.name}, ${x.city.name}, ${x.province.name} ${x.zip_code}`
      this.gelatoService.getServices(x.province.province_code, x.city.city_code, x.barangay.brgy_code, x.street.street_code, x.building.id).subscribe(x => {
        this.store.dispatch(new UpdateServices(x))
        if (!x.data) {
          this.message = x.message
          this.noService = true
          this.router.navigate(['/onboarding/validate-location'])
        } else if (x.data['services'].length == 0) {
          this.message = x.message
          this.noService = true
          this.router.navigate(['/onboarding/validate-location'])
        } else {
          this.headerText$.subscribe(x => {
            this.message = x.check_availability_screen.ca_heading_text_2
          })
          this.services = x.data["services"]
          this.message = x.message
        }
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })
    }

    if (localStorage.getItem("google_address")) {
      this.googleAddress = localStorage.getItem("google_address")
    }

    if (localStorage.getItem("google_coordinates")) {
      const googleCoordinates = JSON.parse(localStorage.getItem("google_coordinates")) as number[]
      this.gelatoService.getServicesByLATLONG(googleCoordinates[0], googleCoordinates[1]).subscribe(x => {
        this.store.dispatch(new UpdateServices(x))
        if (!x.data) {
          this.message = x.message
          this.noService = true
          this.router.navigate(['/onboarding/validate-location'])
        } else if (x.data['services'].length == 0) {
          this.message = x.message
          this.noService = true
          this.router.navigate(['/onboarding/validate-location'])
        } else {
          this.headerText$.subscribe(x => {
            this.message = x.check_availability_screen.ca_heading_text_2
          })
          this.services = x.data["services"]
          this.message = x.message
        }
        this.store.dispatch(new UpdateHTTPRequestFlag(false))
      })
    }
  }

  ngOnInit() {
  }

}
