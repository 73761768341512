import { Component, Input, OnInit } from '@angular/core';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';

@Component({
  selector: 'app-lite-plan-box-container',
  templateUrl: './lite-plan-box-container.component.html',
  styleUrls: ['./lite-plan-box-container.component.css']
})
export class LitePlanBoxContainerComponent implements OnInit {
  @Input() servicePlan: ServicePlan;
  constructor() { }

  ngOnInit() {
  }

}
