import { Address } from 'src/app/shared/models/address.model';
import { Component, OnInit, Input } from '@angular/core';
import { Plan } from 'src/app/shared/models/plan.model';
import { UpdateCompareChannels, ResetHelpMeChoose, UpdateComparePlans, AddSelectedPlan, UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Router } from '@angular/router';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { PlanDetail } from 'src/app/shared/models/planDetail.model'
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { MatDialogRef } from '@angular/material/dialog';import { MatDialog } from '@angular/material/dialog';
import { UpdatePlanDetails } from 'src/app/shared/actions/gelato.actions';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';
import { Service } from 'src/app/shared/models/service.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-bundle-viewchannel-lineup',
  templateUrl: './bundle-viewchannel-lineup.component.html',
  styleUrls: ['./bundle-viewchannel-lineup.component.css']
})
export class BundleViewchannelLineupComponent implements OnInit {

  @Select(GelatoState.getHelpMeChoose) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;

  @Select(GelatoState.getPlanDetail) planDetails$: Observable<ResponseObject<PlanDetail>>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getHelpMeChoose) selected$: Observable<any>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  showCompare: boolean = false;
  plansFlag:boolean = false
  compareFlag:boolean = false

  selectedPlans:[]

  panelOpen: boolean = false;
  twoWordPlan: boolean = false;
  firstWord: String = "";


  servicePlan:ServicePlan[]
  selected:Plan[]
  planDetail:[]

  blanckBox:[]
  compareError : boolean = false
  isDisabled: any;
  address:Address
  province_code:string = ""
  constructor(private srv: GelatoState,private store: Store,private gelatoService:GelatoService,private router: Router) { 

    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.planDetails$.subscribe(x=> {
      this.planDetail = x.data["plan_details"]
    })
  }

  ngOnInit() {
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.gelatoService.getServicesByPlanID(environment.BIZ_BROADBAND_CABLE.toString(),this.province_code).subscribe(x => {
      this.servicePlan = x.data["service_plans"]
    })
    this.store.select(state => state.gelato.help_me_choose).subscribe(x => {
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
      this.selectedPlans = x
     
    });

  }

  getThis(){
  
    this.router.navigate(['/tv-plan/create-plan/tv-packs'])
  }

  goBack(){
    window.history.back();
    this.compareFlag =  false
    this.plansFlag = false
  }
 
}
