import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mesh-device-details',
  templateUrl: './mesh-device-details.component.html',
  styleUrls: ['./mesh-device-details.component.css']
})
export class MeshDeviceDetailsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goBack(){
    this.router.navigate(['internet-plan/select-device'])
  }

}
