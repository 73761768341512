import { Component, OnInit } from '@angular/core';
import { ResetHelpMeChoose } from '../../actions/gelato.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-header-channel-lineup',
  templateUrl: './header-channel-lineup.component.html',
  styleUrls: ['./header-channel-lineup.component.css']
})
export class HeaderChannelLineupComponent implements OnInit {

  constructor(private store: Store) { }

  ngOnInit() {
  }

  goBack(){
    window.history.back();
    this.store.dispatch(new ResetHelpMeChoose())
  }

}
