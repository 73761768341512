import { environment } from 'src/environments/environment';
import { AddSelectedChannel, RemoveSelectedChannel, UpdateDevices, UpdateHTTPRequestFlag } from './../../../../shared/actions/gelato.actions';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { Router } from '@angular/router';
import { Plan } from 'src/app/shared/models/plan.model';
import { Device } from 'src/app/shared/models/device.model';
import { GelatoService } from 'src/app/core/services/gelato.service';

@Component({
  selector: 'app-channel-info',
  templateUrl: './channel-info.component.html',
  styleUrls: ['./channel-info.component.css']
})
export class ChannelInfoComponent implements OnInit {
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  selectedService: number
  @Select(GelatoState.getChannelDetail) channelDetails$: Observable<ResponseObject<Channel>>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getSelectedDevices) selectedDevice$: Observable<[Device]>;
  device: Device[] = []
  selectedChannels:[Channel]
  channelDetails:Channel
  flag:boolean
  selectedPlans:[Plan]
  constructor(private store: Store,private router: Router,private gelatoService: GelatoService) { }

  ngOnInit() {
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    this.channelDetails$.subscribe(x => {
      this.channelDetails = x.data["channel_details"]
    })
    this.selectedDevice$.subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.device = x
        }
      }
    })
    this.selectedChannels$.subscribe(x => {
      this.selectedChannels = x
    })

  }

  getFlag(x:Channel){
    
    if(this.selectedChannels){
      return this.selectedChannels.some(y => y.id === x.id)
    }else{
      return false
    }
    
  }

  getThis(channelDetails:Channel){
    this.flag = !this.flag
    this.store.dispatch(new AddSelectedChannel(channelDetails))
  }
  removeThis(channel:Channel){
    this.flag = !this.flag
    this.store.dispatch(new RemoveSelectedChannel(channel.id))
  }

  next(){
    
    if(this.selectedService && this.selectedPlans){
      
      if(this.selectedService === environment.BIZ_BROADBAND){
        this.router.navigate(['/internet-plan/select-plan'])
      }else if(this.selectedService == environment.BIZ_BROADBAND_CABLE){
        this.selectedCategory$.subscribe(x => {
          if(x){
            if(x === "FTTH" || x === "FTTO"){
              this.router.navigate(['/bundle-plan/plan-summary'])
            }else{
              //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
              if(this.selectedPlans.filter(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND_CABLE).some(x => x.is_upgradeable === 1)){
                this.router.navigate(['/internet-plan/select-device'])
              }else{
                let meshDeviceFlag = this.device.some(x => x.device_type_id === environment.DEVICE_MESH)
                //IF PLAN COMES WITH A MESH DEVICE
                if(meshDeviceFlag){
                  this.router.navigate(['/internet-plan/plan-summary'])
                }else{
                  this.store.dispatch(new UpdateHTTPRequestFlag(true))
                    this.gelatoService.getDevices().subscribe(x => {
                      this.store.dispatch(new UpdateDevices(x))
                      this.store.dispatch(new UpdateHTTPRequestFlag(false))
                    })
                  this.router.navigate(['/internet-plan/select-addon'])
                }
              } 
            }
          }
        })  
      }else{
        this.selectedCategory$.subscribe(x => {
          if(x){
            if(x === "FTTH" || x === "FTTO"){
              this.router.navigate(['/internet-plan/plan-summary'])
            }else{
              //CHECK IF INTERNET PLAN DEVICE CAN BE UPGRADED
              if(this.selectedPlans.filter(x => x.service_id === environment.HOMEBIZ || x.service_id === environment.BIZ_BROADBAND_CABLE).some(x => x.is_upgradeable === 1)){
                this.router.navigate(['/internet-plan/select-device'])
              }else{
                let meshDeviceFlag = this.device.some(x => x.device_type_id === environment.DEVICE_MESH)
                //IF PLAN COMES WITH A MESH DEVICE
                if(meshDeviceFlag){
                  this.router.navigate(['/internet-plan/plan-summary'])
                }else{
                  this.store.dispatch(new UpdateHTTPRequestFlag(true))
                    this.gelatoService.getDevices().subscribe(x => {
                      this.store.dispatch(new UpdateDevices(x))
                      this.store.dispatch(new UpdateHTTPRequestFlag(false))
                    })
                  this.router.navigate(['/internet-plan/select-addon'])
            }
              }
            }
          }
        })  
      }
    }
  }

}
