import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AddSelectedBundlePromo, RemoveSelectedBundlePromo } from 'src/app/shared/actions/gelato.actions';
import { BundleOptions } from 'src/app/shared/models/promo-list.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';

@Component({
  selector: 'app-promo-box',
  templateUrl: './promo-box.component.html',
  styleUrls: ['./promo-box.component.css']
})
export class PromoBoxComponent implements OnInit {

  @Select(GelatoState.getSelectedBundlePromos) selectedBundlePromos$: Observable<[BundleOptions]>;
  
  @Input() bundle:BundleOptions;
  @Input() plan_id:number;
  @Input() index:number;
  @Input() promoIndex:number;

  selecteBundlePromos?: BundleOptions[]
  panelOpen: boolean = false;


  constructor(private store: Store) { 
    this.selectedBundlePromos$.subscribe(x => {
      this.selecteBundlePromos = x
    })
  }

  ngOnInit() {
  }

  getFlag(bundle: BundleOptions){
    // Check if selected bundle promo
    // Same bundle id, plan id and index
    return this.selecteBundlePromos?.some((x,i) => x.bundle_id == bundle.bundle_id && x.plan_id == this.plan_id && x.promoIndex == this.promoIndex)
  }

  getBundle(bundle: BundleOptions,get: boolean){
    if(!get){
      const selected = {...bundle,"plan_id":this.plan_id,"index":this.index,"promoIndex":this.promoIndex}
      // Remove previously selected bundle promo
      if(this.selecteBundlePromos && this.selecteBundlePromos?.length >= 1){
        this.store.dispatch(new RemoveSelectedBundlePromo(this.plan_id,this.promoIndex))
      }
      this.store.dispatch(new AddSelectedBundlePromo(selected))
      this.panelOpen = true
    }
  }

}
