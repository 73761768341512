import { Devices } from './../../models/devices.model';
import { DeviceComparison } from './../../models/device-comparison.model';
import { Component, OnInit, Input } from '@angular/core';
import { ResponseObject } from '../../models/response-object.model';
import { UpdateCompareDevices, UpdateDevices, UpdateHelpMeChoose, UpdateDeviceDetail, AddSelectedCableDeviceIndex, RemoveCableIndexId, ResetCableIndexId } from '../../actions/gelato.actions';
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Observable } from 'rxjs';
import { GelatoState } from '../../state/gelato.state';
import { ModemViewDetailsModalComponent } from '../modem-view-details-modal/modem-view-details-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Plan } from '../../models/plan.model';
import { Device } from '../../models/device.model';
import { Channel } from '../../models/channel.model';
import { Pack } from '../../models/pack.model';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-choose-cable-box',
  templateUrl: './choose-cable-box.component.html',
  styleUrls: ['./choose-cable-box.component.css']
})
export class ChooseCableBoxComponent implements OnInit {
  @Select(GelatoState.getSelectedDeviceIndex) cableIndex$: Observable<any>
  @Select(GelatoState.getSelectedCableDeviceId) cableCountId$: Observable<any>
  @Select(GelatoState.getDevices) selectedService$: Observable<ResponseObject<Devices>>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$:Observable<[Plan]>
  @Select(GelatoState.getSelectedCable) selectedCable$:Observable<[Device]>
  @Select(GelatoState.getSelectedDevices) selectedDevice$: Observable<[Device]>;
  
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  
  
  meshFlag:boolean = false
  selectedPlans:[Plan]
  @Input() devices: ResponseObject<Devices>;
  title:string = "Choose your Cable Box"
  count:any
  cableIndex:number[];
  disabledFlag:string
  length:number
  cableCountId:number[];
  selectValue:string = ""
  box:number = 0
  selectedPacks:Pack[] = []
  selectedChannels:Channel[] = []
  selectedDevices:Device[] = [];
  header:String = ''
  constructor(public dialog: MatDialog,private store: Store,private router: Router,private gelatoService:GelatoService) { }

  ngOnInit() {

    this.selectedDevice$.subscribe(x => {
      
      if(x){
        this.selectedDevices = x
        if(x.some(x => x.device_type_id == environment.DEVICE_CABLE)){
          this.header = "Select your Cable TV Box for Main and Additional Outlet/s"
        }
        else{
          this.header = "Select your Cable TV Box for Main and Additional Outlet/s"
        }
      }
      else{
        this.header = "Select your Cable TV Box for Main and Additional Outlet/s"
      }
    })

    this.cableIndex$.subscribe(x => {
      this.cableIndex = x
     this.count = x
    
      
    })
    this.cableCountId$.subscribe(x => {
      this.cableCountId = x
      if(x){
        if(x.length > 0) this.meshFlag = true
      }
    
    })
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
       if(x){
         if(x.length > 0) this.title = "Upgrade your Cable Box"
       }
     })

     this.selectedChannels$.subscribe(x => {
      if(x) this.selectedChannels = x
     })
    this.selectedPacks$.subscribe(x => {
      if(x) this.selectedPacks = x
     })
    
  }

  toggleMesh(){
    this.meshFlag = !this.meshFlag
  }

  

  selectPlan(x){
    this.router.navigate([x])
  }

  
goTo(x){
  this.router.navigate([x])
}

displayFlag(device:Device) {
  return this.selectedDevices.some(x => x.id === device.id)
}

}
