import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bundle-compare-mesh-devices',
  templateUrl: './bundle-compare-mesh-devices.component.html',
  styleUrls: ['./bundle-compare-mesh-devices.component.css']
})
export class BundleCompareMeshDevicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
