import { Address } from 'src/app/shared/models/address.model';
import { Component, OnInit, Input } from '@angular/core';
import { Plan } from 'src/app/shared/models/plan.model';
import { MatDialog } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { AddSelectedDevice, AddSelectedPlan, RemoveSelectedChannel, RemoveSelectedDevice, RemoveSelectedPack, RemoveSelectedPlan, UpdateBillingSummary, UpdateBillingSummaryPayload, UpdatePlanDetails } from 'src/app/shared/actions/gelato.actions';
import { TvModalPlanDetailComponent  } from 'src/app/shared/components/tv-modal-plan-detail/tv-modal-plan-detail.component';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable, Subscription } from 'rxjs';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Service } from 'src/app/shared/models/service.model';
import { environment } from 'src/environments/environment';
import { Device } from 'src/app/shared/models/device.model';
import { Pack } from 'src/app/shared/models/pack.model';
import { Channel } from 'src/app/shared/models/channel.model';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-tv-plan-box',
  templateUrl: './tv-plan-box.component.html',
  styleUrls: ['./tv-plan-box.component.css']
})
export class TvPlanBoxComponent implements OnInit {
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  selectedPlans:[Plan]
  @Input() plan: Plan;
  panelOpen: boolean = false;
  twoWordPlan: boolean = false;
  firstWord: String = "";
  subscription: Subscription;
  address:Address
  selectedDevices:Device[] =  []
  province_code:string = ""
  errorMessage = ""
  billingSummaryPayload:any
  selectedPacks:Pack[] = []
  selectedChannels:Channel[] = []
  removedPlan: number
  constructor(public dialog: MatDialog,private store: Store,private gelatoService:GelatoService,private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })

    this.selectedPacks$.subscribe(x => {
      this.selectedPacks = x
    })

    this.selectedChannels$.subscribe(x => {
      this.selectedChannels = x
    })

    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
  }
  openPlanDetail(id) {
    this.subscription = this.selectedCategory$.subscribe(x => {
      if(x){ 
        //UPDATE PLAN_DETAIL
        this.gelatoService.getPlanDetailsByID(id,this.province_code,x).subscribe(x => {
          this.store.dispatch(new UpdatePlanDetails(x))
          const dialogRef = this.dialog.open(TvModalPlanDetailComponent,{
            height: '800px',
            width: '660px',
          });
          dialogRef.afterClosed().subscribe(result => {
            this.subscription.unsubscribe()
          });
         })
      }
    })
    
    
  }

  editPlanName(planName){
    var split = planName.split(" ")
    var word = split.slice(1).join().replace(",", " ")
    if(split.length > 1){
      return word
    }
    else{
      return planName
    }
  }

  getFlag(plan:Plan){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === plan.id)
    }else{
      return false
    }
    
  }
  
  getThis(plan:Plan) {
    if(this.selectedPlans == undefined || this.selectedPlans.filter(x => x.service_id == environment.SKYBIZ_HD_PLANS).length < 5){
      this.panelOpen = true
   
      if(plan.service_id === environment.SKYBIZ_HD_PLANS){
        this.store.dispatch(new AddSelectedPlan(plan))
      }
      else{
        this.store.dispatch(new AddSelectedPlan(plan))
        plan.comes_with.map(x => {
          this.store.dispatch(new AddSelectedDevice(x))
        })
      }
      this.billingSummary()
    }
    else{
      this.openSnackBar( "You've reached the maximum allowed number of selections.","Close")
    }
    
    
  }

  billingSummary(){
      //CONSTRUCT SELECTED PLANS
      const selected = this.selectedPlans.map(x => {
         return {
          "plan_id":x.id,
          // device_id: x.service_id === environment.HOMEBIZ ? this.selectedDevices.filter(y=> (y.device_type_id === environment.DEVICE_MODEM || y.device_type_id === environment.DEVICE_MESH) && x.service_id === environment.HOMEBIZ).map(x=>x.id) : this.selectedDevices.filter(y=>  y.device_type_id === environment.DEVICE_CABLE).map(x=>x.id) 
          device_id: x.comes_with.map(device => device.id) 

        }
      })
      const defaultAddon = {
          "devices":[],
          "channel_pack_id":[],
          "channel_id":[]
      }

      if(this.removedPlan == 56){
        if(this.selectedPacks){
          this.selectedPacks.forEach(pack => {
            this.store.dispatch(new RemoveSelectedPack(pack.id))
          })
        }
        if(this.selectedChannels){
          this.selectedChannels.forEach(channel => {
            this.store.dispatch(new RemoveSelectedChannel(channel.id))
          })
        }
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected, "addons":defaultAddon }
      }
      else{
        this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected }
      }
      
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
        
        if(x.status_code === 200){
          this.errorMessage = ""
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }else{
          this.errorMessage = x.message
        }
      })    
  
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

}
