import {DeviceDetail  } from './../../models/device-detail.model';
import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs/internal/Observable';
import { ResponseObject } from '../../models/response-object.model';
import { Plan } from '../../models/plan.model';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { AddSelectedPlan, UpdateEditMode } from '../../../shared/actions/gelato.actions';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-tv-select-device-modal',
  templateUrl: './tv-select-device-modal.component.html',
  styleUrls: ['./tv-select-device-modal.component.css']
})
export class TvSelectDeviceModalComponent implements OnInit {
  @Select(GelatoState.getDeviceDetail) deviceDetails$: Observable<ResponseObject<DeviceDetail>>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  selectedPlans:[Plan]
  deviceDetail:DeviceDetail
  device:any
  isButtonVisible = true;
  buttonName = "See more";
  more: number=1;
  selectedService: number
  constructor(private store: Store,private router: Router,private gelatoService:GelatoService,
    public dialogRef: MatDialogRef<TvSelectDeviceModalComponent>) 
    {
      
    this.deviceDetails$.subscribe(x=> {
      this.device = x.data["device"]
      this.deviceDetail = x.data["device"]
    
    })
    
    window.scrollTo({top: 0})
    
  }

  SeeMore(){
    this.more = this.more + 11;
  
    if(this.more >= 7){
        this.isButtonVisible = false;
        this.buttonName = "See Less";        
    }
  }
  

    SeeLess(){
        this.more = 0;
        this.isButtonVisible = true;
    }

  ngOnInit() {
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
  }
 
  editPlanName(planName){
    var split = planName.split(" ")
    if(split.length > 1){
      return split[1]
    }
    else{
      return planName
    }
  }
  
  closeDialog(){
    this.dialogRef.close();
  }

}



