import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UpdateCompareChannels, ResetHelpMeChoose, UpdateComparePlans, AddSelectedPlan, UpdateEditMode } from '../../../shared/actions/gelato.actions';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { GelatoService } from 'src/app/core/services/gelato.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Select(GelatoState.getEditMode) editMode$:Observable<boolean>;
  @Select(GelatoState.getEditOriginLink) editOriginLink$: Observable<String>;
  @Input() nextButton: boolean
  @Input() helpChoose: boolean = true
  @Input() editPlan: boolean = false
  @Input() backClick?: string
  @Output() nextClick: any = new EventEmitter()
  
  plansFlag:boolean = false
  compareFlag:boolean = false
  editMode:boolean = false
  constructor(private srv: GelatoState, private store: Store,private gelatoService:GelatoService, private router: Router) { }

  ngOnInit() {
    this.editMode$.subscribe(x =>{
      this.editMode = x
    })
  }

  goBack(){
    if(this.backClick){
      this.router.navigate([this.backClick])
    }else{
      window.history.back();
    }

  }

  goNext(){
    this.nextClick.emit("click")
    this.store.dispatch(new UpdateEditMode(false))
  }

  saveChanges(){
    this.store.dispatch(new UpdateEditMode(false))
    this.editOriginLink$.subscribe(x => {
      this.router.navigate([x])
    })
  }

}
