import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';
import { Plan } from '../../models/plan.model';
import { PlanDetail } from '../../models/planDetail.model';
import { Router } from '@angular/router';
import { AddSelectedPlan, UpdateHelpMeChoose, AddSelectedDevice, UpdateBillingSummaryPayload, UpdateBillingSummary } from '../../actions/gelato.actions';
import { ResponseObject } from '../../models/response-object.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import * as _ from 'lodash'

@Component({
  selector: 'app-channel-lineup',
  templateUrl: './channel-lineup.component.html',
  styleUrls: ['./channel-lineup.component.css']
})

export class ChannelLineupComponent implements OnInit {
  @Select(GelatoState.getHelpMeChoose) helpMeChoose$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getPlanDetail) planDetails$: Observable<ResponseObject<PlanDetail>>;
  @Select(GelatoState.getHelpMeChoose) selected$: Observable<any>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Input() plan: Plan;
  @Input() selectedPlan:[]
  @Input() Detail:[]
  @Output() getThisEvent = new EventEmitter();
  selectedPlans:[Plan]
  planDetail:PlanDetail
  billingSummaryPayload:any
  constructor(private srv: GelatoState,private store: Store,private gelatoService:GelatoService,private router: Router) 
  { 
    this.planDetails$.subscribe(x=> {
      this.planDetail = x.data["plan_details"]
    })
  }

  ngOnInit() {
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
  }

  getThisFlag(planDetail:PlanDetail){
    // Check if plan selected
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === planDetail.id)
    }else{
      return false
    }
  }
 

  getThis(plandetail:Plan) {
    plandetail.comes_with.map(x => {
      this.store.dispatch(new AddSelectedDevice(x))
    })
    this.store.dispatch(new AddSelectedPlan(plandetail))
    
    //CONSTRUCT SELECTED PLANS
    const selected = this.selectedPlans.map(x => {
      return {
        "plan_id":x.id,
        device_id:x.comes_with.map(x=>x.id)
      }
    })
    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)

    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(payload).subscribe(x => {
      if(x.status_code === 200){
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{
      }
    })    


    this.router.navigate(['/tv-plan/create-plan/tv-packs'])
    
  }

}

