import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs';
import { Plan } from '../../models/plan.model';
import { PlanDetail } from '../../models/planDetail.model';
import { Router } from '@angular/router';
import { AddSelectedPlan, AddSelectedDevice, RemoveSelectedPlan, RemoveSelectedDevice, UpdateBillingSummaryPayload, UpdateBillingSummary, RemoveSelectedPack, RemoveSelectedChannel } from '../../actions/gelato.actions';
import { GelatoService } from 'src/app/core/services/gelato.service';
import * as _ from 'lodash'
import { Channel } from '../../models/channel.model';
import { Device } from '../../models/device.model';
import { Pack } from '../../models/pack.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-compare-channel-lineup',
  templateUrl: './compare-channel-lineup.component.html',
  styleUrls: ['./compare-channel-lineup.component.css']
})
export class CompareChannelLineupComponent implements OnInit {
  @Select(GelatoState.getHelpMeChoose) helpMeChoose$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Input() selectedPlansToCompare:[]
  @Input() channelLineup:[]
  @Output() getThisEvent = new EventEmitter();
  selectedPlans:[Plan]
  billingSummaryPayload:any
  errorMessage = ""
  removedPlan: number
  selectedService: number
  selectedPacks:Pack[] = []
  selectedChannels:Channel[] = []
  selectedDevices:Device[] =  []
  constructor(private _snackBar: MatSnackBar,private store: Store,private gelatoService:GelatoService,private router: Router) { }

  ngOnInit() {
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
     this.selectedPacks$.subscribe(x => {
      this.selectedPacks = x
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })

    this.selectedChannels$.subscribe(x => {
      this.selectedChannels = x
    })
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    
    this.selectedService$.subscribe(x => {
      this.selectedService = x
    })
  }

  getThisFlag(id){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === id)
    }else{
      return false
    }
  }

  
  getThis(plan:Plan) {
    let plansByService = 0;
    if(this.selectedPlans){
      if(this.selectedService === environment.BIZ_BROADBAND_CABLE) {
        plansByService = this.selectedPlans.filter(x => x.service_id == environment.BIZ_BROADBAND_CABLE).length
      } else if(this.selectedService === environment.FIBER_CONNECT_HDTV_PLANS) {
        plansByService = this.selectedPlans.filter(x => x.service_id == environment.FIBER_CONNECT_HDTV_PLANS).length
      } else {
        plansByService =this.selectedPlans.filter(x => x.service_id == environment.SKYBIZ_HD_PLANS).length
      }
    }
    if(this.selectedPlans == undefined || plansByService < 5){
      
      this.store.dispatch(new AddSelectedPlan(plan))
      plan.comes_with.map(x => {
        this.store.dispatch(new AddSelectedDevice(x))
      })

      //CONSTRUCT SELECTED PLANS
      const selected = this.selectedPlans.map(x => {
        return {
          "plan_id":x.id,
          device_id:x.comes_with.map(x=>x.id)
        }
      })
      let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)
      
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(payload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(payload).subscribe(x => {
        if(x.status_code === 200){
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }
      })    

       
      if(this.selectedService === environment.BIZ_BROADBAND_CABLE || this.selectedService === environment.FIBER_CONNECT_HDTV_PLANS) {
        this.router.navigate(['/bundle-plan/select-plan'])
      } else {
        this.router.navigate(['/tv-plan/standard-plan'])
      }
    }
    else{
      this.openSnackBar( "You've reached the maximum allowed number of selections.","Close")
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  billingSummary(){
    //CONSTRUCT SELECTED PLANS
    const selected = this.selectedPlans.map(x => {
       return {
        "plan_id":x.id,
        device_id: x.service_id === environment.HOMEBIZ ? this.selectedDevices.filter(y=> (y.device_type_id === environment.DEVICE_MODEM || y.device_type_id === environment.DEVICE_MESH) && x.service_id === environment.HOMEBIZ).map(x=>x.id) : this.selectedDevices.filter(y=>  y.device_type_id === environment.DEVICE_CABLE).map(x=>x.id) 

      }
    })
    const defaultAddon = {
        "devices":[],
        "channel_pack_id":[],
        "channel_id":[]
    }

    if(this.removedPlan == 56){
      if(this.selectedPacks){
        this.selectedPacks.forEach(pack => {
          this.store.dispatch(new RemoveSelectedPack(pack.id))
        })
      }
      if(this.selectedChannels){
        this.selectedChannels.forEach(channel => {
          this.store.dispatch(new RemoveSelectedChannel(channel.id))
        })
      }
      this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected, "addons":defaultAddon }
    }
    else{
      this.billingSummaryPayload = {...this.billingSummaryPayload, "selected_plans": selected }
    }
    
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(this.billingSummaryPayload))
    //UPDATE BILLING SUMMARY
    this.gelatoService.billingSummary(this.billingSummaryPayload).subscribe(x => {
      
      if(x.status_code === 200){
        this.errorMessage = ""
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        this.router.navigate(['/tv-plan/create-plan/tv-packs'])
      }else{
        this.errorMessage = x.message
      }
    })    

  }

}
