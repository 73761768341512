import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compare-mesh-devices',
  templateUrl: './compare-mesh-devices.component.html',
  styleUrls: ['./compare-mesh-devices.component.css']
})
export class CompareMeshDevicesComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goBack(){
    this.router.navigate(['internet-plan/select-device'])
  }

}
