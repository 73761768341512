import { AvailableLockinPeriod } from './../../models/available-lockin-period.model';
import { AddSelectedDevice, RemoveSelectedDevice, AddSelectedCableDevice, AddSelectedCableLockin, AddSelectedCableDeviceCountId, AddSelectedCableDeviceIndex, AddMonthlyFee, AddInstallFee, UpdateBillingSummary, UpdateBillingSummaryPayload } from './../../actions/gelato.actions';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';
import { ResponseObject } from '../../models/response-object.model';
import { DeviceDetail } from '../../models/device-detail.model';
import { MatDialogRef } from '@angular/material/dialog';
import { Devices } from '../../models/devices.model';
import { Device } from '../../models/device.model';
import { Plan } from '../../models/plan.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Pack } from '../../models/pack.model';
import { Channel } from '../../models/channel.model';
import { VoucherCode } from '../../models/voucher-code';
import * as _ from 'lodash';
import { HeaderText } from '../../models/header-text.model';

@Component({
  selector: 'app-cable-view-modal-details',
  templateUrl: './cable-view-modal-details.component.html',
  styleUrls: ['./cable-view-modal-details.component.css']
})
export class CableViewModalDetailsComponent implements OnInit {
  @Select(GelatoState.getSelectedDevices) selectedDevices$: Observable<[Device]>
  @Select(GelatoState.getSelectedLockin) selectedLockin$: Observable<AvailableLockinPeriod>
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>
  @Select(GelatoState.getOrdinalPosition) ordinalPostiion$: Observable<number>
  @Select(GelatoState.getSelectedDeviceIndex) Index$: Observable<[any]>
  @Select(GelatoState.getDeviceDetail) deviceDetails$: Observable<ResponseObject<DeviceDetail>>
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPlans) selectedPlan$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedPacks) selectedPacks$: Observable<[Pack]>;
  @Select(GelatoState.getSelectedChannels) selectedChannels$: Observable<[Channel]>;
  @Select(GelatoState.getVoucherCode) discountCode$: Observable<ResponseObject<VoucherCode>>;
  @Select(GelatoState.getEditMode) edit$: Observable<boolean>;
  @Select(GelatoState.getApplyButtonFlag) applyButtonFlag$: Observable<boolean>;
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedCableDeviceId) CableId$: Observable<[any]>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.updateCreateYourOwnFlag) createYourOwnFlag$: Observable<boolean>
  discountCode:string =""
  selectedPlan:Plan[] = []
  selectedPacks:Pack[] = []
  selectedDevices:Device[] =  []
  selectedChannels:Channel[] = []
  selectedCableLockin:[AvailableLockinPeriod]
  selectedMeshLockin:[AvailableLockinPeriod]
  selectedCableDevice:any[] = []
  selectedMeshDevice:any[] = []
  createYourOwnFlag:boolean
  errorMessage = ""

  selectedDevice:[Device]
  selectedLockin:AvailableLockinPeriod
  countt: [number]
  position:number

  
  billingSummaryPayload:any
  headerText:HeaderText
  constructor(private store: Store,public dialogRef: MatDialogRef<CableViewModalDetailsComponent>,private gelatoService:GelatoService) {
    this.createYourOwnFlag$.subscribe(x =>{
      if(x){
        this.createYourOwnFlag = x
      }
    })
   }

  ngOnInit() {
    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
      else this.selectedCableDevice = []
    })
    this.selectedDevices$.subscribe(x => {
      this.selectedDevice = x
    })
    this.selectedLockin$.subscribe(x => {
      this.selectedLockin = x
    })
    this.Index$.subscribe(x => {
      this.countt = x
      
    })
    this.ordinalPostiion$.subscribe(x=>{
      this.position = x
      
    })
    this.selectedPlan$.subscribe(x => {
      if(x) this.selectedPlan = x
     
    })
    this.selectedDevices$.subscribe(x => {
      if(x) this.selectedDevices = x
    
    })
    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
      else this.selectedCableDevice = []
    })
  
    this.selectedMesh$.subscribe(x => {
      if(x) this.selectedMeshDevice = x
      else this.selectedMeshDevice = []
    })
   
    this.selectedPacks$.subscribe(x => {
      if(x) this.selectedPacks = x
      else this.selectedPacks = []
    })
    this.selectedChannels$.subscribe(x => {
      if(x) this.selectedChannels = x
     else this.selectedChannels = []
    })
    this.discountCode$.subscribe(x => {
      if(x) this.discountCode = x.data['voucher_code'].code
      else this.discountCode = ""
    })

    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    this.headerText$.subscribe(x => {
      if(x) this.headerText = x
    })
  }

  getThis(x){
    
    const device_id = x.id
    this.store.dispatch(new AddSelectedCableDevice(x))  
      this.store.dispatch(new AddSelectedCableLockin(this.selectedLockin)) 
    this.store.dispatch(new AddSelectedCableDeviceCountId(x.id))  
   this.billingSummary()


    this.dialogRef.close()
    
  }

  getThisFlag(device:Device){

    if(this.selectedDevice){
      if(this.selectedDevice.some(y => y.id === device.id)){
     
        return true
      }else{
     
        return false
      }
    }else{
     
      return false
      
    }
    
}
  
closeDialog(){
  this.dialogRef.close();
}


billingSummary(){
  const selected = []
  const selectedMesh = this.selectedMeshDevice.map(x => {
    selected.push({
      "device_id":x.id,
      "lockin_period_id":x.lockin_period_id 
    })
   return{
    device_id:x.id,
    "lockin_period_id":x.lockin_period_id 
   }
    
  })
  const selectedCable = this.selectedCableDevice.map(x => {
    selected.push({
      "device_id":x.id,
      "lockin_period_id":x.lockin_period_id 
    })
    return {
      
        device_id:x.id,
        "lockin_period_id":x.lockin_period_id 
    }
  })


   let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.devices",selected)
 //UPDATE BILLING SUMMARY PAYLOAD
 this.store.dispatch(new UpdateBillingSummaryPayload(payload))
 //UPDATE BILLING SUMMARY
 this.gelatoService.billingSummary(payload).subscribe(x => {
   if(x.status_code === 200){
     this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
   }else{ 
   }
   })  



}



}

