import { Address } from 'src/app/shared/models/address.model';
import { PlanDetail } from '../../../../shared/models/planDetail.model';
import { Component, OnInit,Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { GelatoState } from '../../../../shared/state/gelato.state';
import { Observable } from 'rxjs/internal/Observable';
import { ResponseObject } from '../../../../shared/models/response-object.model';
import { Plan } from '../../../../shared/models/plan.model';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { UpdateHelpMeChoose, ResetHelpMeChoose, UpdateComparePlans, AddSelectedPlan, AddSelectedDevice } from '../../../../shared/actions/gelato.actions';
import { MatDialog } from '@angular/material/dialog';
import { UpdatePlanDetails } from 'src/app/shared/actions/gelato.actions';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';
import { Service } from 'src/app/shared/models/service.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bundle-view-details-modal',
  templateUrl: './bundle-view-details-modal.component.html',
  styleUrls: ['./bundle-view-details-modal.component.css']
})
export class BundleViewDetailsModalComponent implements OnInit {

  @Select(GelatoState.getPlanDetail) planDetails$: Observable<ResponseObject<PlanDetail>>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getHelpMeChoose) selected$: Observable<any>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Input() plan: Plan;
  selectedPlans:[Plan]
  panelOpen: boolean = false;
  twoWordPlan: boolean = false;
  firstWord: String = "";
  showCompare: boolean = false;
  plansFlag:boolean = false
  compareFlag:boolean = false
  servicePlan:ServicePlan[]
  selected:Plan[]
  blanckBox:[]
  compareError : boolean = false
  isDisabled: any;
  planDetail:PlanDetail
  isButtonVisible = true;
  buttonName = "See more";
  more: number=1;
  address:Address
  province_code:string = ""
  constructor(private srv: GelatoState,private store: Store,private router: Router,private gelatoService:GelatoService,
    public dialogRef: MatDialogRef<BundleViewDetailsModalComponent>)
     { 

      this.planDetails$.subscribe(x=> {
        this.planDetail = x.data["plan_details"]
      })
      
      window.scrollTo({top: 0})

     }


     SeeMore(){
      this.more = this.more + 11; 
  
      if(this.more >= 7){
          this.isButtonVisible = false;
          this.buttonName = "See Less";        
      }
    }
    
  
      SeeLess(){
          this.more = 0;
          this.isButtonVisible = true;
      }
  

  ngOnInit() {
    this.services$.subscribe(x => {
      if(x) this.province_code = x.data["province"]["code"]
    })
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    this.address$.subscribe(x => {
      if(x) this.address = x
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.gelatoService.getServicesByPlanID(environment.BIZ_BROADBAND_CABLE.toString(),this.province_code).subscribe(x => {
      this.servicePlan = x.data["service_plans"]
    })
    this.store.select(state => state.gelato.help_me_choose).subscribe(x => {
      this.selectedPlans = x
    });
  }
  selectPlan(plan:Plan){
    this.store.dispatch(new UpdateHelpMeChoose(this.planDetail))
    
    this.selectedService$.subscribe(x => {
      this.router.navigate(['../../../../shared/components/compare-channel-lineup/compare-channel-lineup.component.ts'])
    
    })
  }
  
  getThisFlag(planDetail:PlanDetail){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.service_id=== planDetail.service_id)
    }else{
      return false
    }
  }
  
  getThis(plan:Plan) {
    
    this.store.dispatch(new AddSelectedPlan(plan))
    plan.comes_with.map(x => {
      this.store.dispatch(new AddSelectedDevice(x))
    })
    this.dialogRef.close()
    this.router.navigate(['/bundle-plan/select-plan'])
  }

  editPlanName(planName){
    var split = planName.split(" ")
    if(split.length > 1){
      return split[1]
    }
    else{
      return planName
    }
  }
  

}

