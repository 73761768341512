import { PlanDetail } from './../../models/planDetail.model';
import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { GelatoState } from '../../state/gelato.state';
import { Observable } from 'rxjs/internal/Observable';
import { ResponseObject } from '../../models/response-object.model';

@Component({
  selector: 'app-modal-plan-detail',
  templateUrl: './modal-plan-detail.component.html',
  styleUrls: ['./modal-plan-detail.component.css']
})
export class ModalPlanDetailComponent implements OnInit {
  @Select(GelatoState.getPlanDetail) planDetails$: Observable<ResponseObject<PlanDetail>>;
  planDetail:PlanDetail
  constructor(private store: Store,private gelatoService:GelatoService) {
    this.planDetails$.subscribe(x=> {
      this.planDetail = x.data["plan_details"]
    })
    
    window.scrollTo({top: 0})
   }

  ngOnInit() {
  }

}
