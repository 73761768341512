import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { browserRefresh } from 'src/app/app.component';

@Component({
  selector: 'app-bundle-plan-container',
  templateUrl: './bundle-plan-container.component.html',
  styleUrls: ['./bundle-plan-container.component.css']
})
export class BundlePlanContainerComponent implements OnInit {
  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  flag: boolean

  constructor(private router: Router) { 
    this.router.url === '/bundle-plan' ? this.router.navigate(['/bundle-plan/select-plan']) : null
  }

  ngOnInit() {
    if(browserRefresh){
      this.router.navigate(['/onboarding/available-location'])
    }
    this.httpReqeuestFlag$.subscribe(x =>{
      this.flag = x
    })
  }

}
