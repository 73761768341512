import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import {browserRefresh} from 'src/app/app.component'
@Component({
  selector: 'app-internet-plan-container',
  templateUrl: './internet-plan-container.component.html',
  styleUrls: ['./internet-plan-container.component.css']
})
export class InternetPlanContainerComponent implements OnInit {

  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  flag: boolean = true
  
  constructor(private router: Router) { 
 
    this.router.url === '/internet-plan' ? this.router.navigate(['/internet-plan/select-plan']) : null
  }

  ngOnInit() {
    if(browserRefresh){
      this.router.navigate(['/onboarding/available-location'])
    }
    this.httpReqeuestFlag$.subscribe(x =>{
      this.flag = x
    })
  }

}
