import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { TvPlanRoutingModule } from './tv-plan-routing.module';
import { TvPlanContainerComponent } from './pages/tv-plan-container/tv-plan-container.component';
import { ChoosePlanComponent } from './pages/choose-plan/choose-plan.component';
import { StandardTvPlanComponent } from './pages/standard-tv-plan/standard-tv-plan.component';
import { CreateTvPlanComponent } from './pages/create-tv-plan/create-tv-plan.component';
import { TvPacksComponent } from './pages/tv-packs/tv-packs.component';
import { ChannelInfoComponent } from './pages/channel-info/channel-info.component';
import { PackInfoComponent } from './pages/pack-info/pack-info.component';
import { FinalStepsComponent } from './pages/final-steps/final-steps.component';
import { TvHelpMeChooseComponent } from './pages/tv-help-me-choose/tv-help-me-choose.component';
import { TvPlanBoxComponent } from './component/tv-plan-box/tv-plan-box.component';
import { ChannelInclusionsComponent } from './components/channel-inclusions/channel-inclusions.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TvPlanBoxContainerComponent } from './component/tv-plan-box-container/tv-plan-box-container.component';
import { TvPlanChannelLineupComponent } from './pages/tv-plan-channel-lineup/tv-plan-channel-lineup.component';
import { TvSelectDeviceComponent } from './pages/tv-select-device/tv-select-device.component';
import { TvCompareDeviceComponent } from './pages/tv-compare-device/tv-compare-device.component';
import { ChannelDetailsComponent } from './pages/channel-details/channel-details.component';
import { AddonComponent } from './pages/addon/addon.component';
import { CableLiteComponent } from './pages/cable-lite/cable-lite.component';
import { LitePlanBoxComponent } from './component/lite-plan-box/lite-plan-box.component';
import { LitePlanBoxContainerComponent } from './component/lite-plan-box-container/lite-plan-box-container.component';
import { LiteSelectDeviceComponent } from './pages/lite-select-device/lite-select-device.component';





@NgModule({
  declarations: [TvPlanBoxComponent,TvPlanBoxContainerComponent,TvPlanContainerComponent, ChoosePlanComponent, StandardTvPlanComponent, CreateTvPlanComponent, TvPacksComponent, ChannelInfoComponent, PackInfoComponent, FinalStepsComponent, TvHelpMeChooseComponent, ChannelInclusionsComponent, TvPlanChannelLineupComponent,TvSelectDeviceComponent, TvCompareDeviceComponent, ChannelDetailsComponent, AddonComponent, CableLiteComponent, LitePlanBoxComponent, LitePlanBoxContainerComponent, LiteSelectDeviceComponent],
  imports: [
    TvPlanRoutingModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class TvPlanModule { }
