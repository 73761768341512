// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  GELATO_API_BASE_URL: "https://subscribeapi.mysky.com.ph/api/v1",
  // GELATO_API_BASE_URL: "http://122.248.248.45/backend/api/v1",
  INTERFACE_ID: "PROJECT SKYBIZ GELATO",
  INTERFACE_KEY: "153b029837d2b29cd2c8a6128273d67c7fe12388b5bcdd21a37321f877e2f4b2",
  GOOGLE_CLOUD_KEY: "AIzaSyCDqdXiq6nokJdRdwnzNkfRED9NqHK9P8Y",
  GOOGLE_RECAPTCHA_KEY: "6Lc4sM0ZAAAAAMCrYCfLzDuMGMVndxXiyJfJt9f4",
  PLAN_LINK: {
    "7": "../../internet-plan/select-plan",
    "5": "../../tv-plan/choose-plan",
    "6": "../../bundle-plan/select-plan"
  },
  BIZ_BROADBAND: 5,
  BIZ_BROADBAND_CABLE: 6,
  HOMEBIZ: 7,
  FIBER_CONNECT_PLANS: 8,
  SKYBIZ_HD_PLANS: 9,
  FIBER_CONNECT_HDTV_PLANS: 10,
  BIZ_BROADBAND_CONNECT_BUNDLE_PROMO: 11,
  BIZ_FIBER_CONNECT_BUNDLE_PROMO: 12,
  // SERVICE_INTERNET:7, //3
  // SERVICE_TV:5, // 1
  // SERVICE_BUNDLE:6, // 2
  DEVICE_MODEM: 1,
  DEVICE_CABLE: 2,
  DEVICE_MESH: 3
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
