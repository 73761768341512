import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bundle-compare-device',
  templateUrl: './bundle-compare-device.component.html',
  styleUrls: ['./bundle-compare-device.component.css']
})
export class BundleCompareDeviceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
