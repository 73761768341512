import { environment } from 'src/environments/environment';
import { Address } from 'src/app/shared/models/address.model';
import { PlanDetail } from 'src/app/shared/models/planDetail.model';
import { UpdateHelpMeChoose, ResetHelpMeChoose, UpdateComparePlans, AddSelectedPlan, UpdateHTTPRequestFlag, AddSelectedDevice, UpdateBillingSummary, UpdateBillingSummaryPayload, RemoveSelectedDevice, RemoveSelectedPlan } from './../../../../shared/actions/gelato.actions';
import { Plan } from 'src/app/shared/models/plan.model';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { Observable } from 'rxjs';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { ServicePlan } from 'src/app/shared/models/servicePlan.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { Router } from '@angular/router';
import * as _ from 'lodash'
import { Service } from 'src/app/shared/models/service.model';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-bundle-help-me-choose',
  templateUrl: './bundle-help-me-choose.component.html',
  styleUrls: ['./bundle-help-me-choose.component.css']
})
export class BundleHelpMeChooseComponent implements OnInit {

  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getHelpMeChoose) selected$: Observable<any>;
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  selectedPlans:[Plan]
  showCompare: boolean = false;
  plansFlag:boolean = false
  compareFlag:boolean = false
  servicePlan:ServicePlan[]
  selected:Plan[]
  blanckBox:[]
  compareError : boolean = false
  isDisabled: any;
  twoWordPlan: boolean = false;
  firstWord: String = "";
  selectedCategory: string;
  address:Address
  billingSummaryPayload:any
  province_code:string = ""
  googleAddress: string = localStorage.getItem("google_address")
  selectedService: number;
  constructor(private srv: GelatoState, private store: Store,private gelatoService:GelatoService,private router: Router,private _snackBar: MatSnackBar) { }

  header: any;
   message: any;
   
  ngOnInit() {
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google || this.googleAddress) {
          this.province_code = x.data["province"]["code"]
        }
      })
    })
    this.selectedService$.subscribe(x => {
      x ? this.selectedService = x : null
    })
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    this.selectedCategory$.subscribe(x => {
      this.selectedCategory = x
    })
  this.resetComparison()
    this.headerText$.subscribe(x => {
      this.header = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_text
      this.message = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_subtext
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.gelatoService.getServicesByPlanID(this.selectedService.toString(),this.province_code, this.selectedCategory).subscribe(x => {
      this.servicePlan = x.data["service_plans"]
    })
    this.store.select(state => state.gelato.help_me_choose).subscribe(x => {
      this.selected = x
    });
        
    this.headerText$.subscribe(x => {
      this.header = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_text
      this.message = x.cabletv_plan_screen.cable_tv_help_me_choose_heading_subtext
    })
    
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    
  }

  displayPlans(){
    this.togglePlan()
   
  }
  togglePlan(){
    this.plansFlag = !this.plansFlag
    this.compareError = false
    this.toggleError()
  }
  toggleCompare(){
    this.compareError = false
    if(this.selected != undefined){
      if(this.selected.length > 1){
        this.compareFlag = !this.compareFlag
      }
      else{
        this.compareError = true
        this.toggleError()
      }
    }
    else {
      this.compareError = true
      this.toggleError()
    }

   
  }
  selectPlan(plan:Plan){
    this.store.dispatch(new UpdateHelpMeChoose(plan))
    this.togglePlan()
    if(this.selected.length == 3){
      this.isDisabled = "none";
    }
  }
  resetComparison(){
    this.compareFlag =  false
    this.plansFlag = false
    this.store.dispatch(new UpdateHTTPRequestFlag(false))
    this.store.dispatch(new ResetHelpMeChoose())
    this.isDisabled = "any";
  }

  getThisFlag(planDetail:PlanDetail){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.id === planDetail.id)
    }
    else{
      return false
    }
  }

  getThis(plan:Plan) {
    
    if(this.selectedPlans == undefined || this.selectedPlans.filter(x => x.service_id == this.selectedService).length < 5){
      this.store.dispatch(new AddSelectedPlan(plan))
      plan.comes_with.map(x => {
        this.store.dispatch(new AddSelectedDevice(x))
      })

      //CONSTRUCT SELECTED PLANS
      const selected = this.selectedPlans.map(x => {
        return {
          "plan_id":x.id,
          device_id:x.comes_with.map(x=>x.id)
        }
      })
      let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"selected_plans",selected)
      
      //UPDATE BILLING SUMMARY PAYLOAD
      this.store.dispatch(new UpdateBillingSummaryPayload(payload))
      //UPDATE BILLING SUMMARY
      this.gelatoService.billingSummary(payload).subscribe(x => {
        if(x.status_code === 200){
          this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
        }
      })    

      this.router.navigate(['/bundle-plan/select-plan'])
    }
    else{
      this.openSnackBar( "You've reached the maximum allowed number of selections.","Close")
    }
  }

  compare(){
   this.showCompare = true
  }

  toggleError(){
    if(this.compareError){
      document.getElementsByClassName('add-choice')[0].classList.add('red-border')
      document.getElementsByClassName('add-choice')[1].classList.add('red-border')
    }
    else{
      document.getElementsByClassName('add-choice')[0].classList.remove('red-border')
      document.getElementsByClassName('add-choice')[1].classList.remove('red-border')

    }
  }
  
  editPlanName(planName){
    var split = planName.split(" ")
    if(split.length > 1){
      return split[1]
    }
    else{
      return planName
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

}
