import { Address } from 'src/app/shared/models/address.model';
import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { HeaderText } from './../../../../shared/models/header-text.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';
import { ServicePlan } from './../../../../shared/models/servicePlan.model';
import { UpdateServicePlan, UpdateDevices, UpdateVisitedPages, UpdateServices } from './../../../../shared/actions/gelato.actions';
import { ProgressComponent } from './../../../../shared/components/progress/progress.component';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { BundlePlanContainerComponent } from '../bundle-plan-container/bundle-plan-container.component';
import { Plan } from 'src/app/shared/models/plan.model';
import { ResponseObject } from 'src/app/shared/models/response-object.model';
import { Service } from 'src/app/shared/models/service.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-bundle',
  templateUrl: './select-bundle.component.html',
  styleUrls: ['./select-bundle.component.css'],
})
export class SelectBundleComponent implements OnInit {
  @Select(GelatoState.getSelectedService) selectedService$: Observable<number>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  @Select(GelatoState.getEditMode) editMode$: Observable<boolean>;
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>
  @Select(GelatoState.getSelectedServiceCategory) selectedCategory$: Observable<string>;
  @Select(GelatoState.getVisitedPages) visitedPages$: Observable<[any]>;
  @Select(GelatoState.getAddress) address$: Observable<Address>;
  @Select(GelatoState.getServices) services$: Observable<ResponseObject<[Service]>>;
  @Select(GelatoState.getGoogleAddress) googleAddress$: Observable<string>;
  @Select(GelatoState.getGoogleCoordinates) googleCoordinates$: Observable<number[]>
  selectedPlans: [Plan]
  flag: boolean
  editMode: boolean
  servicePlan:ServicePlan[]
  selectedCategory: string;
  address:Address
  province_code:string = ""
  hasMoreThanOnePlan:boolean=false
  googleCoordinates: any
  requestGoogleServices: boolean = false
  googleAddress: string = localStorage.getItem("google_address")
  selectedService: number
  env = environment
  constructor(private router: Router, private srv: GelatoState,private store: Store,private gelatoService:GelatoService) { 
    this.visitedPages$.subscribe(x => {
      if(x){
        if(!x.includes('select-plan')){
          this.store.dispatch(new UpdateVisitedPages(['available','select-plan']))
        }
      }
    })
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
    this.selectedPlans$.subscribe(x => {
      this.selectedPlans = x
    })
    this.googleCoordinates$.subscribe(x => {
      if(x){
        this.googleCoordinates = x
      }      else{
        this.googleCoordinates = parseInt(localStorage.getItem("google_coordinates"))
      }

    })
  }
  
  ngOnInit() {
    this.selectedCategory$.subscribe(x => {
      this.selectedCategory = x
    })
    this.googleAddress$.subscribe(google => {
      this.services$.subscribe(x => {
        if(google || this.googleAddress) {
          if(x){
            x.data['province'] ? this.province_code = x.data["province"]["code"] : null
          }
          else{
            this.requestGoogleServices = true
            this.gelatoService.getServicesByLATLONG(this.googleCoordinates[0], this.googleCoordinates[1]).subscribe(x => {
              if(x.data){
                this.store.dispatch(new UpdateServices(x))
                this.province_code = x.data['province']['code']
                this.selectedCategory$.subscribe(x => {
                  if (x) {
                    this.gelatoService.getServicesByPlanID(environment.BIZ_BROADBAND_CABLE.toString(), this.province_code, x).subscribe(x => {
                      this.store.dispatch(new UpdateHTTPRequestFlag(true))
                      this.servicePlan = x.data["service_plans"]
                      //IF PLANS IS MORE THAN ONE, SHOW HELP ME CHOOSE
                      if (this.servicePlan.reduce((x, y) => x + y.plans.length, 0) > 1) this.hasMoreThanOnePlan = true
                      this.store.dispatch(new UpdateServicePlan(x.data["service_plans"]))
                      this.store.dispatch(new UpdateHTTPRequestFlag(false))
                      this.requestGoogleServices = false
                    })
                  }
                })
              }
              else{
                this.store.dispatch(new UpdateHTTPRequestFlag(false))
                console.warn(x.message)
                this.requestGoogleServices = false
              }
            })
          }
        }
      })
    })
    this.editMode$.subscribe(x => {
      this.editMode = x
    })
    const addressObj = localStorage.getItem("address")
    if(addressObj){
      this.address = JSON.parse(addressObj) as Address
      this.province_code = this.address.province.province_code
    }
    this.httpReqeuestFlag$.subscribe(x =>{
      this.flag = x
    })
    this.selectedService$.subscribe(x => {
      if (x && this.requestGoogleServices == false) {
        this.selectedService = x
        this.gelatoService.getServicesByPlanID(x.toString(), this.province_code, this.selectedCategory).subscribe(x => {
          this.store.dispatch(new UpdateHTTPRequestFlag(true))
          this.servicePlan = x.data["service_plans"]
          //IF PLANS IS MORE THAN ONE, SHOW HELP ME CHOOSE
          if(this.servicePlan.reduce((x,y) => x+y.plans.length,0) > 1) this.hasMoreThanOnePlan = true
          this.store.dispatch(new UpdateServicePlan(x.data["service_plans"]))
          this.store.dispatch(new UpdateHTTPRequestFlag(false))
        })  
      }
    })
    this.gelatoService.getDevices().subscribe(x => {
      this.store.dispatch(new UpdateDevices(x))
    })

    
  }

  nextFlag(){
    if(this.selectedPlans){
      return this.selectedPlans.some(x => x.service_id == this.selectedService)
    }
    else{
      return false
    }
  }
  
  nextClick(){
    this.router.navigate(['/tv-plan/create-plan/tv-packs'])
  }
}
