import { MeshDevice } from './../../models/mesh-device.model';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GelatoState } from '../../state/gelato.state';
import { Observable, Subscription } from 'rxjs';
import { ResponseObject } from '../../models/response-object.model';
import { Devices } from '../../models/devices.model';
import { DeviceComparison } from '../../models/device-comparison.model';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { AddSelectedDevice, UpdateHTTPRequestFlag, AddSelectedMeshDevice, AddSelectedMeshLockin, UpdateBillingSummaryPayload, UpdateBillingSummary, RemoveSelectedMeshDevice, RemoveSelectedMeshLockin } from '../../actions/gelato.actions';
import { Device } from '../../models/device.model';
import { SelectedDevice } from '../../models/selected-device.model';
import * as _ from 'lodash';
import { AvailableLockinPeriod } from '../../models/available-lockin-period.model';

@Component({
  selector: 'app-compare-mesh-table',
  templateUrl: './compare-mesh-table.component.html',
  styleUrls: ['./compare-mesh-table.component.css']
})
export class CompareMeshTableComponent implements OnInit {
  @Select(GelatoState.getSelectedMesh) selectedMesh$: Observable<[Device]>;
  @Select(GelatoState.getDevices) devices$: Observable<ResponseObject<Devices>>
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  @Select(GelatoState.getSelectedCable) selectedCable$: Observable<[Device]>;
  @Select(GelatoState.getSelectedMeshLockin) selectedMeshLockin$: Observable<[AvailableLockinPeriod]>;
  selectedMesh:[Device]
  deviceFeature:DeviceComparison 
  requestFlag: boolean = true
  selectedCableDevice:any[] = []
  selectedMeshDevice:any[] = []
  selectedMeshLockin:AvailableLockinPeriod[]=[]
  billingSummaryPayload:any
  subscription: Subscription
  constructor(private store: Store,private gelatoService:GelatoService) { 
    this.store.dispatch(new UpdateHTTPRequestFlag(true))
  }

  ngOnInit() {
    this.billingSummaryPayload$.subscribe(x => {
      if(x) this.billingSummaryPayload = x
    })
    this.gelatoService.getDevices().subscribe(x => {
      const modems = x.data["mesh_devices"] as [MeshDevice]
      if(modems){
        this.gelatoService.compareDevicesByID("Mesh",modems.map(x => x.id)).subscribe(x => {
          this.deviceFeature = x.data
          this.store.dispatch(new UpdateHTTPRequestFlag(false)) 
          this.requestFlag = false
        })
      }
    })

    this.selectedMesh$.subscribe(x => {
      if (x) this.selectedMesh = x
      this.selectedMeshDevice = x
    })
    this.selectedMeshLockin$.subscribe(x => {
      if (x) this.selectedMeshLockin = x
    })
    this.selectedCable$.subscribe(x => {
      if(x) this.selectedCableDevice = x
      else this.selectedCableDevice = []
    })
  }
  getThis(x:SelectedDevice){
    if(this.selectedMeshDevice){
      this.selectedMeshDevice.map(x => {
        this.store.dispatch(new RemoveSelectedMeshDevice(x.id))
        this.store.dispatch(new RemoveSelectedMeshLockin(x.lockin_period_id))
      })
    }
    this.store.dispatch(new AddSelectedMeshDevice(x))  
    this.store.dispatch(new AddSelectedMeshLockin(x.lockin_period))  
    const selected = []
    const selectedMesh = this.selectedMeshDevice.map(x => {
      selected.push({
        "device_id":x.id,
        "lockin_period_id":x.lockin_period['id'] || x.lockin_period_id
      })
     return{
      device_id:x.id,
      "lockin_period_id":x.lockin_period['id'] || x.lockin_period_id
     }
      
    })
    const selectedCable = this.selectedCableDevice.map(x => {
      selected.push({
        "device_id":x.id,
        "lockin_period_id":x.lockin_period_id 
      })
      return {
        
          device_id:x.id,
          "lockin_period_id":x.lockin_period_id 
      }
    })
    let payload = _.set(JSON.parse(JSON.stringify(this.billingSummaryPayload)),"addons.devices",selected)
    //UPDATE BILLING SUMMARY PAYLOAD
    this.store.dispatch(new UpdateBillingSummaryPayload(payload))
    //UPDATE BILLING SUMMARY
    this.subscription = this.gelatoService.billingSummary(payload).subscribe(x => {
      if(x.status_code === 200){
        this.store.dispatch(new UpdateBillingSummary(x.data['billing_summary']))
      }else{ 
      }
      this.subscription.unsubscribe() 
    }) 
    //END UPDATE MESH BILLING SUMMARY PAYLOAD
    window.history.back()
  }

  getThisFlag(device:Device){

    if(this.selectedMesh){
      if(this.selectedMesh.some(y => y.id === device.id)){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
}
}
