import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-compare-device-header',
  templateUrl: './compare-device-header.component.html',
  styleUrls: ['./compare-device-header.component.css']
})
export class CompareDeviceHeaderComponent implements OnInit {
  @Input() title: String
  constructor() { }

  ngOnInit() {
  }

  goBack(){
    window.history.back();
  }

}
