import { ApplicationFormComponent } from './shared/components/application-form/application-form.component';
import { BundlePlanContainerComponent } from './modules/bundle-plan/pages/bundle-plan-container/bundle-plan-container.component';
import { InternetPlanContainerComponent } from './modules/internet-plan/pages/internet-plan-container/internet-plan-container.component';
import { OnboardingContainerComponent } from './modules/onboarding/pages/onboarding-container/onboarding-container.component';
import { TvPlanContainerComponent } from './modules/tv-plan/pages/tv-plan-container/tv-plan-container.component';
import { ConfirmationComponent } from './shared/components/confirmation/confirmation.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



const routes: Routes = [
  { path: '', redirectTo: '/onboarding/set-location', pathMatch: 'full' },
  {
    path:'',
    component:OnboardingContainerComponent
  },
  {
    path:'onboarding',
    component:OnboardingContainerComponent
  },
  {
    path:'tv-plan',
    component:TvPlanContainerComponent
  },
  {
    path:'internet-plan',
    component:InternetPlanContainerComponent
  },
  {
    path:'bundle-plan',
    component:BundlePlanContainerComponent
  },
  {
    path: 'application-form',
    component:ApplicationFormComponent
  },
  {
    path: 'confirmation',
    component:ConfirmationComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
