import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GelatoService } from 'src/app/core/services/gelato.service';
import { UpdateAvailableBundles, UpdateHTTPRequestFlag } from 'src/app/shared/actions/gelato.actions';
import { HeaderText } from 'src/app/shared/models/header-text.model';
import { Plan } from 'src/app/shared/models/plan.model';
import { BundleOptions, PromoList } from 'src/app/shared/models/promo-list.model';
import { GelatoState } from 'src/app/shared/state/gelato.state';

@Component({
  selector: 'app-bundle-promo',
  templateUrl: './bundle-promo.component.html',
  styleUrls: ['./bundle-promo.component.css']
})
export class BundlePromoComponent implements OnInit {
  
  @Select(GelatoState.getSelectedPlans) selectedPlans$: Observable<[Plan]>;
  @Select(GelatoState.getSelectedBundlePromos) selectedBundlePromos$: Observable<[BundleOptions]>;
  @Select(GelatoState.getHTTPRequestFlag) httpReqeuestFlag$: Observable<boolean>;
  @Select(GelatoState.getHeaderText) headerText$: Observable<HeaderText>;
  @Select(GelatoState.getAvailableBundlePromos) availableBundlePromos$: Observable<PromoList[]>;
  @Select(GelatoState.getBillingSummaryPayload) billingSummaryPayload$: Observable<any>;
  selectedPlans: Plan[] = []
  selectedPlanIds: number[]
  availablePromos: PromoList[]
  selectedBundlePromos: [BundleOptions];
  flag:boolean;

  constructor(private gelatoService: GelatoService, private router: Router, private store: Store) { 
    this.httpReqeuestFlag$.subscribe(x => {
      this.flag = x
    })
    this.selectedPlans$.subscribe(x => {
      if (x) {
        this.selectedPlans = x
        this.selectedPlanIds = x.map(x => x.id)
      }
    })

    this.gelatoService.getBundlePromos(this.selectedPlanIds).subscribe(x => {
      this.store.dispatch(new UpdateHTTPRequestFlag(true))
      this.store.dispatch(new UpdateAvailableBundles(x.data));
      this.store.dispatch(new UpdateHTTPRequestFlag(false))
    })

    this.availableBundlePromos$.subscribe(x => {
      this.availablePromos = x;
    })

    this.selectedBundlePromos$.subscribe(x => {
      this.selectedBundlePromos = x
    })
  }

  ngOnInit() {
  }

  nextFlag(){
    return this.selectedBundlePromos?.length == this.availablePromos?.length
  }

  nextClick(){
    this.router.navigate(['internet-plan/plan-summary'])
  }

}
